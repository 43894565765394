"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var lists_1 = require("constants/lists");
var invoice_1 = require("api/invoice");
var stripe_1 = require("api/stripe");
var errors_herlper_actions_1 = require("./errors_herlper_actions");
var errors_1 = require("api/helpers/errors");
var invoice_2 = require("constants/invoice");
function getInvoicesList(query, entity) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var newQuery, result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch({
                        type: lists_1.LIST_ACTIONS.GET_LIST,
                        payload: { entity: entity }
                    });
                    newQuery = Object.assign({}, query);
                    return [4 /*yield*/, invoice_1.InvoiceApi.getList(query)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: lists_1.LIST_ACTIONS.GET_LIST_SUCCESS,
                                payload: {
                                    result: resultData,
                                    query: newQuery,
                                    entity: entity
                                }
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                payload: errors_1.prepareErrors(resultData),
                                type: lists_1.LIST_ACTIONS.GET_LIST_FAIL,
                                entity: entity
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.getInvoicesList = getInvoicesList;
function getInvoiceStatuses() {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, invoice_1.InvoiceApi.getPreinvoiceStatuses()];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.INVOICE_STATUS_GET_SUCCESS,
                                payload: resultData.statuses
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                payload: errors_1.prepareErrors(resultData),
                                type: invoice_2.INVOICE_CONST.INVOICE_STATUS_GET_FAIL
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.getInvoiceStatuses = getInvoiceStatuses;
function getInvoice(id, params) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, invoice_1.InvoiceApi.getInvoice(id, params)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.INVOICE_GET_SUCCESS,
                                payload: resultData
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.INVOICE_GET_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.getInvoice = getInvoice;
function getInvoiceByUuid(uuid) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, invoice_1.InvoiceApi.getInvoiceByUuid(uuid)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.GET_INVOICE_BY_UUID_SUCCESS,
                                payload: {
                                    invoice: __assign({}, resultData.preinvoice, { provider_name: resultData.provider_name, client_email: resultData.client_email, card_fee_item: resultData.card_fee_item, international_card_fee_item: resultData.international_card_fee_item, reject_reason: resultData.reject_reason }),
                                    sources: resultData.sources
                                }
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.GET_INVOICE_BY_UUID_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.getInvoiceByUuid = getInvoiceByUuid;
function updateInvoice(id, data) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, invoice_1.InvoiceApi.updateInvoice(id, data)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                payload: resultData.preinvoice,
                                type: invoice_2.INVOICE_CONST.INVOICE_UPDATE_SUCCESS
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                payload: errors_1.prepareErrors(resultData),
                                type: invoice_2.INVOICE_CONST.INVOICE_UPDATE_FAIL
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.updateInvoice = updateInvoice;
function chargeInvoice(data) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData, status_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, stripe_1.StripeApi.charge(data)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                payload: {
                                    invoice: resultData.invoice
                                },
                                type: invoice_2.INVOICE_CONST.CHARGE_INVOICE_SUCCESS
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        status_1 = result.status === 409 ? result.status : undefined;
                        return [2 /*return*/, dispatch({
                                payload: errors_1.prepareErrors(resultData, status_1),
                                type: invoice_2.INVOICE_CONST.CHARGE_INVOICE_FAIL
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.chargeInvoice = chargeInvoice;
function verifyAchBankStart(data) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData, status_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, stripe_1.StripeApi.verifyAchBankStart(data)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                payload: {
                                    invoice: resultData.invoice,
                                    source: resultData.source
                                },
                                type: invoice_2.INVOICE_CONST.VERIFY_ACH_START_SUCCESS
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        status_2 = result.status === 409 ? result.status : undefined;
                        return [2 /*return*/, dispatch({
                                payload: errors_1.prepareErrors(resultData, status_2),
                                type: invoice_2.INVOICE_CONST.VERIFY_ACH_START_FAIL
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.verifyAchBankStart = verifyAchBankStart;
function verifyAchBankEnd(data) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, stripe_1.StripeApi.verifyAchBankEnd(data)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                payload: {
                                    invoice: resultData.invoice,
                                    source: resultData.source
                                },
                                type: invoice_2.INVOICE_CONST.VERIFY_ACH_END_SUCCESS
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                payload: errors_1.prepareErrors(resultData),
                                type: invoice_2.INVOICE_CONST.VERIFY_ACH_END_FAIL
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.verifyAchBankEnd = verifyAchBankEnd;
function chargeAchBank(data) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData, status_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, stripe_1.StripeApi.chargeAchBank(data)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                payload: {
                                    invoice: resultData.invoice,
                                    source: resultData.source
                                },
                                type: invoice_2.INVOICE_CONST.VERIFY_ACH_END_SUCCESS
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        status_3 = result.status === 409 ? result.status : undefined;
                        return [2 /*return*/, dispatch({
                                payload: errors_1.prepareErrors(resultData, status_3),
                                type: invoice_2.INVOICE_CONST.VERIFY_ACH_END_FAIL
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.chargeAchBank = chargeAchBank;
function chargeCard(data) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData, status_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, stripe_1.StripeApi.chargeСard(data)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                payload: {
                                    invoice: resultData.invoice
                                },
                                type: invoice_2.INVOICE_CONST.CHARGE_CARD_SUCCESS
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        status_4 = result.status === 409 ? result.status : undefined;
                        return [2 /*return*/, dispatch({
                                payload: errors_1.prepareErrors(resultData, status_4),
                                type: invoice_2.INVOICE_CONST.CHARGE_CARD_FAIL
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.chargeCard = chargeCard;
function invoiceMarkAsPaid(id) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, invoice_1.InvoiceApi.invoiceMarkAsPaid(id)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.INVOICE_MARK_AS_PAID_SUCCESS,
                                payload: resultData.preinvoice
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.INVOICE_MARK_AS_PAID_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.invoiceMarkAsPaid = invoiceMarkAsPaid;
function invoiceCancel(id) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, invoice_1.InvoiceApi.invoiceCancel(id)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.INVOICE_CANCEL_SUCCESS,
                                payload: resultData.preinvoice
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.INVOICE_CANCEL_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.invoiceCancel = invoiceCancel;
function invoiceSend(id, client_email) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, invoice_1.InvoiceApi.invoiceSend(id, client_email)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.INVOICE_SEND_SUCCESS,
                                payload: resultData.preinvoice
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: invoice_2.INVOICE_CONST.INVOICE_SEND_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.invoiceSend = invoiceSend;
