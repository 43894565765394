"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_form_1 = require("redux-form");
var react_i18next_1 = require("react-i18next");
var react_router_1 = require("react-router");
var react_toastify_1 = require("react-toastify");
var lodash_1 = require("lodash");
require("./style.less");
var action_panel_1 = require("../provider_add/components/action_panel");
var fields_1 = require("components/form/fields");
var validators_1 = require("components/form/wrapper/validators");
var connect_button_1 = require("components/stripe/connect_button");
var login_link_1 = require("components/stripe/login_link");
var stripe_login_error_1 = require("components/stripe/stripe_login_error");
var UserDetails = /** @class */ (function (_super) {
    __extends(UserDetails, _super);
    function UserDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.prepareValues = function (sourceValues) {
            var values = lodash_1.cloneDeep(sourceValues);
            var user = _this.props.user;
            values.kind = user.kind;
            return values;
        };
        _this.handleSubmit = function (sourceValues) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onUpdate, t, values, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onUpdate = _a.onUpdate, t = _a.t;
                        values = this.prepareValues(sourceValues);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, onUpdate(values)];
                    case 2:
                        _b.sent();
                        react_toastify_1.toast.success(t("user_details_form.update_success"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        react_toastify_1.toast.error(t("user_details_form.update_error"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        /**
                         * Backend send wrong empty array on email if password is invalid
                         */
                        if (error_1.email &&
                            Array.isArray(error_1.email) &&
                            error_1.email.length === 0) {
                            delete error_1.email;
                        }
                        throw new redux_form_1.SubmissionError(error_1);
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.redirect = function () {
            var history = _this.props.history;
            if (history) {
                history.push("/");
            }
        };
        return _this;
    }
    UserDetails.prototype.render = function () {
        var _a = this.props, valid = _a.valid, t = _a.t, validators = _a.validators, user = _a.user, handleSubmit = _a.handleSubmit, error = _a.error;
        return ((t && validators && user && handleSubmit && (React.createElement(redux_form_1.Form, { className: "add-edit-form user-details-form", onSubmit: handleSubmit(this.handleSubmit) },
            React.createElement(action_panel_1.default, { title: user.first_name + " " + user.last_name, onSubmit: handleSubmit(this.handleSubmit), onCancel: this.redirect, submitButtonText: t("user_details_form.titles.submit_button"), isSubmit: valid }),
            React.createElement("div", { className: "details-center-wrapper" },
                React.createElement("div", { className: "details-center" },
                    React.createElement("div", { className: "details-column" },
                        React.createElement("h2", { className: "add-edit-form__title" }, t("user_details_form.titles.user_details")),
                        React.createElement(redux_form_1.Field, { label: t("user_details_form.fields.first_name.label"), component: fields_1.default, require: "true", validate: validators.required, name: "first_name", type: "text", placeholder: t("user_details_form.fields.first_name.placeholder") }),
                        React.createElement(redux_form_1.Field, { label: t("user_details_form.fields.last_name.label"), component: fields_1.default, require: "true", validate: validators.required, name: "last_name", type: "text", placeholder: t("user_details_form.fields.last_name.placeholder") }),
                        React.createElement(redux_form_1.Field, { label: t("user_details_form.fields.email.label"), component: fields_1.default, require: "true", validate: [validators.required, validators.email], name: "email", type: "text", placeholder: t("user_details_form.fields.email.placeholder") })),
                    React.createElement("div", { className: "details-column" },
                        React.createElement("h2", { className: "add-edit-form__title" }, t("user_details_form.titles.change_password")),
                        React.createElement(redux_form_1.Field, { label: t("user_details_form.fields.current_password.label"), component: fields_1.default, require: "true", validate: [validators.required, validators.passwordMaxMin], name: "current_password", type: "password", placeholder: t("user_details_form.fields.current_password.placeholder") }),
                        React.createElement(redux_form_1.Field, { label: t("user_details_form.fields.password.label"), component: fields_1.default, name: "password", validate: validators.passwordMaxMin, type: "password", placeholder: t("user_details_form.fields.password.placeholder") }),
                        React.createElement(redux_form_1.Field, { label: t("user_details_form.fields.confirm_password.label"), component: fields_1.default, validate: [validators.password, validators.passwordMaxMin], name: "password_confirmation", type: "password", placeholder: t("user_details_form.fields.confirm_password.placeholder") })),
                    error ? React.createElement("span", { className: "error" }, error) : null),
                React.createElement("div", { className: "billing-details" },
                    React.createElement("h2", { className: "add-edit-form__title user-detail-billing-title" }, t("user_details_form.titles.billing")),
                    React.createElement(connect_button_1.default, null),
                    React.createElement(login_link_1.default, null),
                    React.createElement(stripe_login_error_1.default, null))),
            React.createElement(action_panel_1.default, { onSubmit: handleSubmit(this.handleSubmit), onCancel: this.redirect, submitButtonText: t("user_details_form.titles.submit_button"), isSubmit: valid })))) ||
            null);
    };
    UserDetails = __decorate([
        validators_1.default,
        redux_form_1.reduxForm({
            form: "user_details",
            enableReinitialize: true,
            keepDirtyOnReinitialize: true
        }),
        react_i18next_1.translate(),
        react_router_1.withRouter
    ], UserDetails);
    return UserDetails;
}(React.Component));
exports.default = UserDetails;
