"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BILLING_CONST;
(function (BILLING_CONST) {
    BILLING_CONST["STRIPE_CONNECT_SUCCESS"] = "STRIPE_CONNECT_SUCCESS";
    BILLING_CONST["STRIPE_CONNECT_FAIL"] = "STRIPE_CONNECT_FAIL";
    BILLING_CONST["IS_CONNECTED_SUCCESS"] = "IS_CONNECTED_SUCCESS";
    BILLING_CONST["IS_CONNECTED_FAIL"] = "IS_CONNECTED_FAIL";
    BILLING_CONST["STRIPE_DISCONNECT_SUCCESS"] = "STRIPE_DISCONNECT_SUCCESS";
    BILLING_CONST["STRIPE_DISCONNECT_FAIL"] = "STRIPE_DISCONNECT_FAIL";
    BILLING_CONST["STRIPE_GET_LOGIN_LINK_SUCCESS"] = "STRIPE_GET_LOGIN_LINK_SUCCESS";
    BILLING_CONST["STRIPE_GET_LOGIN_LINK_FAIL"] = "STRIPE_GET_LOGIN_LINK_FAIL";
    BILLING_CONST["STRIPE_GET_LOGIN_LINK_START"] = "STRIPE_GET_LOGIN_LINK_START";
})(BILLING_CONST = exports.BILLING_CONST || (exports.BILLING_CONST = {}));
var BILLING_STATUSES;
(function (BILLING_STATUSES) {
    BILLING_STATUSES["BILLING_DEFAULT_OFF"] = "default_off";
    BILLING_STATUSES["BILLING_OFF"] = "off";
    BILLING_STATUSES["BILLING_ON"] = "on";
    BILLING_STATUSES["BILLING_FIRST_TIME_ON"] = "first_time_on";
})(BILLING_STATUSES = exports.BILLING_STATUSES || (exports.BILLING_STATUSES = {}));
