"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _1 = require(".");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var lists_1 = require("constants/lists");
var invoice_1 = require("actions/invoice");
var clients_1 = require("actions/clients");
var service_provider_1 = require("actions/service_provider");
var is_admin_or_manager_1 = require("../../../components/helpers/user/is_admin_or_manager");
var invoiceEntity = lists_1.LIST_ENTITIES.ALL_INVOICES_LIST;
var clientEntity = lists_1.LIST_ENTITIES.ALL_INVOICES_CLIENTS_PAGINATED_LIST;
var providerEntity = lists_1.LIST_ENTITIES.ALL_INVOICES_PROVIDERS_PAGINATED_LIST;
var InvoicesFilterContainer = /** @class */ (function (_super) {
    __extends(InvoicesFilterContainer, _super);
    function InvoicesFilterContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.updateTable = function (params) { return __awaiter(_this, void 0, void 0, function () {
            var getInvoicesListAction;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        getInvoicesListAction = this.props.getInvoicesListAction;
                        return [4 /*yield*/, getInvoicesListAction(params, invoiceEntity)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    InvoicesFilterContainer.prototype.render = function () {
        var _a = this.props, invoiceStatus = _a.invoiceStatus, clientsList = _a.clientsList, providersList = _a.providersList, user = _a.user;
        var isAdminOrManager = is_admin_or_manager_1.adminOrManager(user.role);
        return (React.createElement(_1.default, { fetchInvoices: this.updateTable, invoiceStatus: invoiceStatus, clientsList: clientsList, providersList: providersList, isAdminOrManager: isAdminOrManager }));
    };
    InvoicesFilterContainer.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, getInvoiceStatusesAction, getClientListAction, getServiceProvidersListAction, user, queryParam, clientParams, tutorParams;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = this.props, getInvoiceStatusesAction = _b.getInvoiceStatusesAction, getClientListAction = _b.getClientListAction, getServiceProvidersListAction = _b.getServiceProvidersListAction, user = _b.user;
                        queryParam = is_admin_or_manager_1.adminOrManager(user.role)
                            ? "manager_by_invoices"
                            : "service_provider_by_invoices";
                        getInvoiceStatusesAction();
                        clientParams = (_a = {
                                per_page: "all"
                            },
                            _a[queryParam] = user.kind_id,
                            _a);
                        return [4 /*yield*/, getClientListAction(clientParams, clientEntity)];
                    case 1:
                        _c.sent();
                        tutorParams = {
                            per_page: "all",
                            manager_by_invoices: user.kind_id
                        };
                        return [4 /*yield*/, getServiceProvidersListAction(tutorParams, providerEntity)];
                    case 2:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return InvoicesFilterContainer;
}(React.Component));
exports.default = react_redux_1.connect(function (state) { return ({
    user: state.user.my,
    invoiceStatus: state.invoiceStatus,
    clientsList: state.lists[clientEntity].items,
    providersList: state.lists[providerEntity].items
}); }, function (dispatch) { return ({
    getInvoicesListAction: redux_1.bindActionCreators(invoice_1.getInvoicesList, dispatch),
    getInvoiceStatusesAction: redux_1.bindActionCreators(invoice_1.getInvoiceStatuses, dispatch),
    getClientListAction: redux_1.bindActionCreators(clients_1.getClientList, dispatch),
    getServiceProvidersListAction: redux_1.bindActionCreators(service_provider_1.getServiceProvidersList, dispatch)
}); })(InvoicesFilterContainer);
