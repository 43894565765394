"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClientsHelper = /** @class */ (function () {
    function ClientsHelper() {
    }
    ClientsHelper.getOptions = function (items) {
        return (items || []).map(function (item) {
            return Object.assign(item, {
                label: item.name,
                value: item.id
            });
        });
    };
    ClientsHelper.getRates = function (feeData) {
        return [{
                label: "$" + feeData.offlineRate + "/" + feeData.sessionDuration + " min",
                value: 'offline',
            },
            {
                label: "$" + feeData.onlineRate + "/" + feeData.sessionDuration + " min",
                value: 'online',
            }
        ];
    };
    return ClientsHelper;
}());
exports.default = ClientsHelper;
