"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./style.less");
var private_1 = require("components/helpers/user/private");
var react_router_1 = require("react-router");
var auth_1 = require("components/helpers/auth");
var user_1 = require("constants/user");
var MainPage = /** @class */ (function (_super) {
    __extends(MainPage, _super);
    function MainPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MainPage.prototype.render = function () {
        if (!auth_1.default.isAuthorized()) {
            return React.createElement(react_router_1.Redirect, { to: "/login" });
        }
        return auth_1.default.getRole() === user_1.Role.serviceProvider ? (React.createElement(react_router_1.Redirect, { to: "/invoices" })) : (React.createElement(react_router_1.Redirect, { to: "/providers" }));
    };
    MainPage = __decorate([
        private_1.default
    ], MainPage);
    return MainPage;
}(React.Component));
exports.default = MainPage;
