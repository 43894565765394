"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var component_1 = require("./component");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var react_i18next_1 = require("react-i18next");
var sessions_1 = require("actions/sessions");
var lists_1 = require("actions/lists");
var lists_2 = require("constants/lists");
var mapStateToProps = function (state) {
    return {
        sessionsArray: state.lists[lists_2.LIST_ENTITIES.MOUNTH_SESSIONS].items,
        selectedDate: state.sessions.selectedDate
    };
};
var mapDispatchToProps = function (dispatch) { return ({
    getSessionsPerDateAction: redux_1.bindActionCreators(lists_1.getList, dispatch),
    updateSelectedDateAction: redux_1.bindActionCreators(sessions_1.updateSelectedDate, dispatch)
}); };
exports.default = react_i18next_1.translate()(react_redux_1.connect(mapStateToProps, mapDispatchToProps)(component_1.default));
