"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var fields_1 = require("components/form/fields");
require("./style.less");
var redux_form_1 = require("redux-form");
var textarea_1 = require("components/form/fields/textarea");
var eductation_1 = require("./eductation");
var tags_1 = require("components/form/fields/tags");
var phone_1 = require("./phone");
var email_1 = require("./email");
var select_1 = require("components/form/fields/select");
var gender_1 = require("helpers/gender");
var fee_range_1 = require("helpers/fee_range");
var address_1 = require("components/form/parts/address");
var component_1 = require("components/schedule_preferences/component");
var input_1 = require("config/input");
exports.EditProviderFormFields = function (_a) {
    var validators = _a.validators, t = _a.t, workspace = _a.workspace, sp_fee_setting = _a.sp_fee_setting, initialManagerFeeSettings = _a.initialManagerFeeSettings, manager_fee_setting = _a.manager_fee_setting, initialSpFeeSettings = _a.initialSpFeeSettings, getSide = _a.getSide, getGender = _a.getGender, getManagers = _a.getManagers, error = _a.error, daysSelected = _a.daysSelected, changeDayValue = _a.changeDayValue, dayPrefix = _a.dayPrefix, otherProps = __rest(_a, ["validators", "t", "workspace", "sp_fee_setting", "initialManagerFeeSettings", "manager_fee_setting", "initialSpFeeSettings", "getSide", "getGender", "getManagers", "error", "daysSelected", "changeDayValue", "dayPrefix"]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "static-block" },
            React.createElement("h2", { className: "add-edit-form__title" }, t("provider_add_form.titles.personal_info")),
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.first_name.label"), component: fields_1.default, require: "true", validate: validators.required, name: "first_name", type: "text", maxLength: input_1.default.first_name.maxLength, autoFocus: "true", placeholder: t("provider_add_form.fields.first_name.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.last_name.label"), component: fields_1.default, require: "true", validate: validators.required, name: "last_name", maxLength: input_1.default.last_name.maxLength, type: "text", placeholder: t("provider_add_form.fields.last_name.placeholder") })),
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.experience.label"), component: fields_1.default, name: "work_experience", validate: [validators.maxMin_4_4, validators.from1930ToNow], type: "number", placeholder: t("provider_add_form.fields.experience.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.gender.label"), component: select_1.default, clearable: true, options: gender_1.default.getGendersWithNoPreference(workspace.genders), name: "gender", type: "text", placeholder: t("provider_add_form.fields.gender.placeholder") })),
            React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.about.label"), component: textarea_1.default, name: "about", maxLength: input_1.default.about.maxLength, placeholder: t("provider_add_form.fields.about.placeholder") }),
            React.createElement("h2", { className: "add-edit-form__title" }, t("provider_add_form.titles.education")),
            React.createElement(redux_form_1.FieldArray, { name: "educations_attributes", component: eductation_1.default, degrees: workspace.degrees, validators: validators }),
            React.createElement("h2", { className: "add-edit-form__title" }, t("provider_add_form.titles.professional_characteristic")),
            React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.expertise.label"), component: tags_1.default, suggestions: workspace.expertises, name: "expertises", type: "text", placeholder: t("provider_add_form.fields.expertise.placeholder") }),
            React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.techniques.label"), component: tags_1.default, suggestions: workspace.techniques, name: "techniques", type: "text", placeholder: t("provider_add_form.fields.techniques.placeholder") }),
            React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.personality.label"), component: tags_1.default, name: "personalities", suggestions: workspace.personalities, type: "text", placeholder: t("provider_add_form.fields.personality.placeholder") }),
            React.createElement("h2", { className: "add-edit-form__title" }, t("provider_add_form.titles.rate_work_preferences")),
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.preferred_fee.label"), component: fields_1.default, validate: [validators.integer], name: "preference_attributes.preferred_fee", type: "text", maxLength: input_1.default.preferred_fee.maxLength, placeholder: t("provider_add_form.fields.preferred_fee.placeholder"), withSign: "$" }),
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.fee_per_hour.label"), component: select_1.default, options: fee_range_1.default.getOptions(workspace.fee_ranges), name: "preference_attributes.fee_range", type: "text", placeholder: t("provider_add_form.fields.fee_per_hour.placeholder") }))),
        React.createElement("div", { className: "static-block" },
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.prefer_city.label"), component: fields_1.default, name: "preference_attributes.city", type: "text", maxLength: input_1.default.city.maxLength, placeholder: t("provider_add_form.fields.prefer_city.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.prefered_location.label"), component: fields_1.default, name: "preference_attributes.preferred_location", type: "text", maxLength: input_1.default.prefered_location.maxLength, placeholder: t("provider_add_form.fields.prefered_location.placeholder") })),
            React.createElement("h6", { className: "form-title" }, t("provider_add_form.fields.shedule_preference.label")),
            React.createElement(component_1.default, { daysSelected: daysSelected, changeDayValue: changeDayValue, dayPrefix: dayPrefix }),
            React.createElement(redux_form_1.Field, { component: textarea_1.default, extendedClass: "textarea_small", name: "preference_attributes.schedule_preference", type: "text", maxLength: input_1.default.schedule_preference.maxLength, placeholder: t("provider_add_form.fields.shedule_preference.placeholder") }),
            React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.client_age_range.label"), component: tags_1.default, name: "preference_attributes.age_ranges", type: "text", suggestions: workspace.age_ranges, placeholder: t("provider_add_form.fields.client_age_range.placeholder") }),
            React.createElement("h2", { className: "add-edit-form__title" }, t("provider_add_form.titles.contacts")),
            React.createElement("div", { className: "email-phone-container two-line margin-bottom vertical-top" },
                React.createElement("div", { className: "item" },
                    React.createElement(redux_form_1.FieldArray, { name: "contact.phones", component: phone_1.default, validators: validators })),
                React.createElement("div", { className: "item" },
                    React.createElement(redux_form_1.FieldArray, { name: "contact.emails", component: email_1.default, validators: validators, customError: error }))),
            React.createElement(address_1.default, __assign({}, otherProps, { namePrefix: "contact", validators: validators, t: t })))));
};
