"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reducers_1 = require("reducers");
var redux_1 = require("redux");
var redux_thunk_1 = require("redux-thunk");
var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux_1.compose;
function configureStore() {
    var store = redux_1.createStore(reducers_1.default, composeEnhancers(redux_1.applyMiddleware(redux_thunk_1.default)));
    return store;
}
exports.default = configureStore;
