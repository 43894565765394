"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("config/api");
var params_1 = require("./helpers/params");
var errors_herlper_actions_1 = require("actions/errors_herlper_actions");
var queryString = require("query-string");
// TODO refactoring methods, a lot of copypast here
var StripeApi = /** @class */ (function () {
    function StripeApi() {
    }
    StripeApi.create = function () {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "stripe_accounts/create", Object.assign({}, params_1.getDefaultParams(), {
            method: "POST"
        }));
    };
    StripeApi.isStripeConnected = function () {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "stripe_accounts/is_connected", Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    StripeApi.getAcceptionStripeOauth = function (query) {
        query =
            "?" + queryString.stringify(query, {
                arrayFormat: "bracket"
            }) || "";
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "stripe_accounts/accept" + query, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    StripeApi.disconnect = function () {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "stripe_accounts/disconnect", Object.assign({}, params_1.getDefaultParams(), {
            method: "POST"
        }));
    };
    StripeApi.charge = function (data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "public_preinvoices/charge_with_card", Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify(data),
            method: "POST"
        }));
    };
    StripeApi.verifyAchBankStart = function (data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "public_preinvoices/verify_ach_start", Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify(data),
            method: "POST"
        }));
    };
    StripeApi.verifyAchBankEnd = function (data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "public_preinvoices/verify_ach_end", Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify(data),
            method: "POST"
        }));
    };
    StripeApi.chargeAchBank = function (data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "public_preinvoices/charge_ach", Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify(data),
            method: "POST"
        }));
    };
    StripeApi.chargeСard = function (data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "public_preinvoices/charge_attached_card", Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify(data),
            method: "POST"
        }));
    };
    StripeApi.getLoginLink = function () {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "stripe_accounts/login_link", Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    StripeApi.toStripe = function (authorize_url) {
        window.location.href = authorize_url;
    };
    return StripeApi;
}());
exports.StripeApi = StripeApi;
