"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var header_1 = require("components/header");
var footer_1 = require("components/footer");
require("./style.less");
var SimpleLayout = /** @class */ (function (_super) {
    __extends(SimpleLayout, _super);
    function SimpleLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SimpleLayout.prototype.render = function () {
        return (React.createElement("div", { className: "main-layout simple" },
            React.createElement(header_1.default, { withNav: false, logoToLanding: this.props.logoToLanding }),
            this.props.children,
            React.createElement(footer_1.default, { withNav: false })));
    };
    return SimpleLayout;
}(React.Component));
exports.default = SimpleLayout;
