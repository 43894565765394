"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var moment = require("moment");
var redux_form_1 = require("redux-form");
var fields_1 = require("../form/fields");
var select_1 = require("../form/fields/select");
var masks_1 = require("../form/masks");
var datepicker_1 = require("../form/fields/datepicker");
var FeeSettings = /** @class */ (function (_super) {
    __extends(FeeSettings, _super);
    function FeeSettings() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isDependentChecked: null
        };
        return _this;
    }
    FeeSettings.prototype.render = function () {
        var _a = this.props, t = _a.t, validators = _a.validators, translatePath = _a.translatePath, disabled = _a.disabled, name = _a.name, children = _a.children, isSuperAdmin = _a.isSuperAdmin, ratesHelper = _a.ratesHelper, feeOptions = _a.feeOptions;
        var isChecked = this.defineChecked();
        return t ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "static-block" },
                React.createElement("div", { className: "two-line" },
                    children,
                    React.createElement(redux_form_1.Field, { name: name + "_checkbox", type: "checkbox", component: fields_1.default, extendedClass: "second-line-checkbox", id: name + "_checkbox", disabled: disabled, checked: isChecked, label: t("change_fee_block." + translatePath + ".checkbox_title"), onChange: this.onDependentChange.bind(this) }))),
            React.createElement("div", { className: "dynamic-block-container" }, isChecked && (React.createElement("div", { className: "dynamic-block" },
                React.createElement("div", { className: "two-line dynamic-fields-container" },
                    isSuperAdmin ? (React.createElement(redux_form_1.Field, __assign({ label: t("change_fee_block." + translatePath + ".fields.new_fee.label"), component: fields_1.default, validate: [
                            validators.required,
                            validators.integer,
                            validators.maxNumber99,
                            validators.minNumber1
                        ], require: "true", name: "preference_attributes." + name + "_attributes.next_planned_percent", type: "text", disabled: disabled, withSign: "%", placeholder: t("change_fee_block." + translatePath + ".fields.new_fee.placeholder") }, masks_1.percentMask))) : (React.createElement(redux_form_1.Field, { type: "text", component: select_1.default, require: "true", validate: validators.required, name: "preference_attributes." + name + "_attributes.next_planned_percent", label: t("change_fee_block." + translatePath + ".fields.new_fee.label"), placeholder: t("change_fee_block." + translatePath + ".fields.new_fee.placeholder"), options: ratesHelper.getOptions(feeOptions, function (value) { return t("change_fee_block.general.fields.new_fee.value", { value: value }); }) })),
                    React.createElement(redux_form_1.Field, { label: t("change_fee_block.general.fields.since_date.label"), component: datepicker_1.default, require: "true", minDate: moment().add(1, "day"), validate: [
                            validators.required,
                            validators.moment,
                            validators.afterNow
                        ], disabled: disabled, name: "preference_attributes." + name + "_attributes.date_change", type: "text", placeholder: t("change_fee_block.general.fields.since_date.placeholder") }))))))) : null;
    };
    FeeSettings.prototype.defineChecked = function () {
        var initialFeeSettings = this.props.initialFeeSettings;
        if (_.isNull(this.state.isDependentChecked)) {
            if (initialFeeSettings &&
                (initialFeeSettings.date_change ||
                    initialFeeSettings.next_planned_percent)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return this.state.isDependentChecked;
        }
    };
    FeeSettings.prototype.onDependentChange = function (value) {
        this.setState({ isDependentChecked: !this.defineChecked() });
    };
    return FeeSettings;
}(React.Component));
exports.default = FeeSettings;
