"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var invoice_1 = require("constants/invoice");
var lodash_1 = require("lodash");
var initialState = {
    invoice: null,
    sources: []
};
function clientInvoice(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case invoice_1.INVOICE_CONST.GET_INVOICE_BY_UUID_SUCCESS:
            return {
                invoice: action.payload.invoice,
                sources: action.payload.sources
            };
        case invoice_1.INVOICE_CONST.GET_INVOICE_BY_UUID_FAIL:
            return initialState;
        case invoice_1.INVOICE_CONST.VERIFY_ACH_START_SUCCESS:
            var newState = lodash_1.cloneDeep(state);
            return {
                invoice: Object.assign(newState.invoice, action.payload.invoice)
            };
        case invoice_1.INVOICE_CONST.VERIFY_ACH_END_SUCCESS:
            return {
                invoice: __assign({}, action.payload.invoice, { provider_name: state.invoice.provider_name })
            };
        case invoice_1.INVOICE_CONST.CHARGE_ACH_SUCCESS:
            return {
                invoice: __assign({}, action.payload.invoice, { provider_name: state.invoice.provider_name })
            };
        case invoice_1.INVOICE_CONST.CHARGE_CARD_SUCCESS:
            return {
                invoice: __assign({}, action.payload.invoice, { provider_name: state.invoice.provider_name })
            };
        case invoice_1.INVOICE_CONST.CHARGE_INVOICE_SUCCESS:
            return {
                invoice: __assign({}, action.payload.invoice, { provider_name: state.invoice.provider_name })
            };
        default:
            return state;
    }
}
exports.default = clientInvoice;
