"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var redux_form_1 = require("redux-form");
var fields_1 = require("components/form/fields");
var select_1 = require("components/form/fields/select");
var invoice_statuses_1 = require("helpers/invoice_statuses");
var clients_helper_1 = require("helpers/clients_helper");
var service_provider_helper_1 = require("helpers/service_provider_helper");
require("./style.less");
var updateFilterTimer = null;
var InvoicesFilter = /** @class */ (function (_super) {
    __extends(InvoicesFilter, _super);
    function InvoicesFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InvoicesFilter.prototype.render = function () {
        var _a = this.props, t = _a.t, invoiceStatus = _a.invoiceStatus, clientsList = _a.clientsList, providersList = _a.providersList, isAdminOrManager = _a.isAdminOrManager;
        return (React.createElement("div", { className: "main-page-filter invoices-filter" },
            React.createElement("form", null,
                React.createElement("div", { className: "search" },
                    React.createElement(redux_form_1.Field, { component: fields_1.default, name: "search", type: "text", id: "search", placeholder: t(isAdminOrManager
                            ? "invoices_filter.search.placeholder"
                            : "invoices_filter.search_tutor.placeholder") }),
                    React.createElement("label", { htmlFor: "search" },
                        React.createElement("i", { className: "icon search" }))),
                isAdminOrManager && (React.createElement(redux_form_1.Field, { clearable: true, backspaceRemoves: true, component: select_1.default, name: "service_provider", options: service_provider_helper_1.default.getOptions(providersList), placeholder: t("invoices_filter.tutor.placeholder") })),
                React.createElement(redux_form_1.Field, { clearable: true, backspaceRemoves: true, component: select_1.default, name: "client", options: clients_helper_1.default.getOptions(clientsList), placeholder: t("invoices_filter.client.placeholder") }),
                React.createElement(redux_form_1.Field, { clearable: true, backspaceRemoves: true, component: select_1.default, name: "status", options: invoice_statuses_1.default.getOptions(invoiceStatus, t), placeholder: t("invoices_filter.statuses.placeholder") }))));
    };
    return InvoicesFilter;
}(React.Component));
exports.default = react_i18next_1.translate()(redux_form_1.reduxForm({
    form: "invoicesFilter",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onChange: function (values, dispatch, props) {
        var fetchInvoices = props.fetchInvoices;
        clearTimeout(updateFilterTimer);
        updateFilterTimer = setTimeout(function () {
            var params = {
                q: values.search,
                page: 1
            };
            if (values.client) {
                params.client = values.client.id;
            }
            if (values.service_provider) {
                params.service_provider = values.service_provider.id;
            }
            if (values.status) {
                params.status = values.status.id;
            }
            fetchInvoices(params);
        }, 500);
    }
})(InvoicesFilter));
