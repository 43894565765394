"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_form_1 = require("redux-form");
require("./style.less");
var fields_1 = require("components/form/fields");
var textarea_1 = require("components/form/fields/textarea");
var tags_1 = require("components/form/fields/tags");
var select_1 = require("components/form/fields/select");
var gender_1 = require("helpers/gender");
var fee_range_1 = require("helpers/fee_range");
var session_duration_range_1 = require("helpers/session_duration_range");
var masks_1 = require("components/form/masks");
var fee_settings_1 = require("components/edit_manager_fee_plans/fee_settings");
var input_1 = require("config/input");
var auth_1 = require("components/helpers/auth");
var rate_ranges_1 = require("../../../../helpers/rate_ranges");
var container_1 = require("./contacts/container");
exports.EditClientFormFields = function (_a) {
    var validators = _a.validators, t = _a.t, workspace = _a.workspace, sp_fee_setting = _a.sp_fee_setting, initialManagerFeeSettings = _a.initialManagerFeeSettings, manager_fee_setting = _a.manager_fee_setting, initialSpFeeSettings = _a.initialSpFeeSettings, getSide = _a.getSide, getGender = _a.getGender, getManagers = _a.getManagers, error = _a.error, otherProps = __rest(_a, ["validators", "t", "workspace", "sp_fee_setting", "initialManagerFeeSettings", "manager_fee_setting", "initialSpFeeSettings", "getSide", "getGender", "getManagers", "error"]);
    var isManager = auth_1.default.isAuthorizedAsManager();
    var isServiceProvider = auth_1.default.isAuthorizedAsServiceProvider();
    var isSuperAdmin = auth_1.default.isSuperAdmin();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "static-block" },
            React.createElement("h2", { className: "add-edit-form__title" }, t("client_add_form.titles.personal_info")),
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.first_name.label"), component: fields_1.default, require: "true", validate: validators.required, name: "first_name", type: "text", autoFocus: "true", maxLength: input_1.default.first_name.maxLength, placeholder: t("client_add_form.fields.first_name.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.last_name.label"), component: fields_1.default, require: "true", validate: validators.required, name: "last_name", type: "text", maxLength: input_1.default.last_name.maxLength, placeholder: t("client_add_form.fields.last_name.placeholder") })),
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.dob.label"), component: fields_1.default, validate: [
                        validators.minNumberMore0,
                        validators.integer,
                        validators.maxMin_3_0,
                    ], name: "age", type: "number", placeholder: t("client_add_form.fields.dob.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.gender.label"), component: select_1.default, clearable: true, options: gender_1.default.getGendersWithNoPreference(workspace.genders), name: "gender", type: "text", placeholder: t("client_add_form.fields.gender.placeholder") })),
            React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.school.label"), component: fields_1.default, require: "true", name: "school", validate: validators.required, type: "text", maxLength: input_1.default.school.maxLength, placeholder: t("client_add_form.fields.school.placeholder") })),
        React.createElement("div", { className: "static-block" },
            React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.problem.label"), component: textarea_1.default, name: "problem", require: "true", validate: validators.required, maxLength: input_1.default.problem.maxLength, placeholder: t("client_add_form.fields.problem.placeholder") }),
            React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.expertises.label"), component: tags_1.default, name: "expertises", type: "text", suggestions: workspace.expertises, placeholder: t("client_add_form.fields.expertises.placeholder") }),
            React.createElement("h2", { className: "add-edit-form__title" }, t("client_add_form.titles.fee_plan")),
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.preferred_fee.label"), component: fields_1.default, validate: [validators.required, validators.integer], require: "true", name: "preference_attributes.preferred_fee", disabled: isServiceProvider, type: "text", maxLength: input_1.default.preferred_fee.maxLength, placeholder: t("client_add_form.fields.preferred_fee.placeholder"), withSign: "$" }),
                React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.fee_per_hour.label"), component: select_1.default, options: fee_range_1.default.getOptions(workspace.fee_ranges), name: "preference_attributes.fee_range", disabled: isServiceProvider, type: "text", placeholder: t("client_add_form.fields.fee_per_hour.placeholder") })),
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.preferred_online_fee.label"), component: fields_1.default, validate: [validators.integer], require: "false", name: "preference_attributes.preferred_online_fee", disabled: isServiceProvider, type: "text", maxLength: input_1.default.preferred_online_fee.maxLength, placeholder: t("client_add_form.fields.preferred_online_fee.placeholder"), withSign: "$" }),
                React.createElement(redux_form_1.Field, { type: "text", component: select_1.default, require: "true", validate: validators.required, name: "session_duration", disabled: isServiceProvider, label: t("client_add_form.fields.session_duration.label"), placeholder: t("client_add_form.fields.session_duration.placeholder"), options: session_duration_range_1.SessionDurationRangeHelper.getOptions(function (value) { return t("client_add_form.fields.session_duration.value", { value: value }); }) }))),
        !isServiceProvider && (React.createElement(fee_settings_1.default, __assign({}, otherProps, { validators: validators, t: t, name: manager_fee_setting, translatePath: "edit_managers_fee", initialFeeSettings: initialManagerFeeSettings, isSuperAdmin: isSuperAdmin, ratesHelper: rate_ranges_1.SupervisorRatesHelper, feeOptions: workspace.manager_network_fee_percentages }), isSuperAdmin ? (React.createElement(redux_form_1.Field, __assign({ label: t("client_add_form.fields.managers_fee.label"), component: fields_1.default, validate: [
                validators.required,
                validators.integer,
                validators.maxNumber99,
                validators.minNumber1
            ], require: "true", name: "preference_attributes.manager_fee_setting_attributes.mediator_percent", type: "text", withSign: "%", placeholder: t("client_add_form.fields.managers_fee.placeholder") }, masks_1.percentMask))) : (React.createElement(redux_form_1.Field, { type: "text", component: select_1.default, require: "true", validate: validators.required, name: "preference_attributes.manager_fee_setting_attributes.mediator_percent", label: t("client_add_form.fields.managers_fee.label"), placeholder: t("client_add_form.fields.managers_fee.placeholder"), options: rate_ranges_1.SupervisorRatesHelper.getOptions(workspace.manager_network_fee_percentages, function (value) { return t("client_add_form.fields.managers_fee.value", { value: value }); }) })))),
        React.createElement(fee_settings_1.default, __assign({}, otherProps, { validators: validators, t: t, disabled: isManager || isServiceProvider, name: sp_fee_setting, translatePath: "edit_sp_fee", initialFeeSettings: initialSpFeeSettings, isSuperAdmin: isSuperAdmin, ratesHelper: rate_ranges_1.TutorRatesHelper, feeOptions: workspace.service_provider_network_fee_percentages }), isSuperAdmin ? (React.createElement(redux_form_1.Field, __assign({ label: t("client_add_form.fields.providers_fee.label"), component: fields_1.default, validate: [
                validators.required,
                validators.integer,
                validators.maxNumber99,
                validators.minNumber1
            ], require: "true", disabled: isManager || isServiceProvider, name: "preference_attributes.sp_fee_setting_attributes.mediator_percent", type: "text", withSign: "%", placeholder: t("client_add_form.fields.providers_fee.placeholder") }, masks_1.percentMask))) : (React.createElement(redux_form_1.Field, { type: "text", component: select_1.default, require: "true", validate: validators.required, disabled: isManager || isServiceProvider, name: "preference_attributes.sp_fee_setting_attributes.mediator_percent", label: t("client_add_form.fields.providers_fee.label"), placeholder: t("client_add_form.fields.providers_fee.placeholder"), options: rate_ranges_1.TutorRatesHelper.getOptions(workspace.service_provider_network_fee_percentages, function (value) { return t("client_add_form.fields.providers_fee.value", { value: value }); }) }))),
        React.createElement("div", { className: "static-block" },
            React.createElement("h2", { className: "add-edit-form__title after-two-line" }, t("client_add_form.titles.client_preferences")),
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.prefer_city.label"), component: fields_1.default, name: "preference_attributes.city", type: "text", maxLength: input_1.default.city.maxLength, placeholder: t("provider_add_form.fields.prefer_city.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.prefered_location.label"), component: fields_1.default, name: "preference_attributes.preferred_location", type: "text", maxLength: input_1.default.prefered_location.maxLength, placeholder: t("provider_add_form.fields.prefered_location.placeholder") })),
            React.createElement("div", { className: "two-line" },
                React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.side.label"), component: select_1.default, options: getSide(), validate: validators.required, name: "preference_attributes.side", type: "text", placeholder: t("client_add_form.fields.side.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.preferred_gender.label"), component: select_1.default, options: getGender(), clearable: true, name: "preference_attributes.gender", type: "text", placeholder: t("client_add_form.fields.preferred_gender.placeholder") })),
            React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.shedule_preference.label"), component: textarea_1.default, name: "preference_attributes.schedule_preference", type: "text", maxLength: input_1.default.schedule_preference.maxLength, placeholder: t("client_add_form.fields.shedule_preference.placeholder") }),
            React.createElement("h2", { className: "add-edit-form__title" }, t("provider_add_form.titles.contacts")),
            React.createElement(container_1.default, { validators: validators, t: t, error: error }),
            React.createElement("h2", { className: "add-edit-form__title after-two-line" }, t("client_add_form.titles.other")),
            React.createElement(redux_form_1.Field, { label: t("client_add_form.fields.intake_manager.label"), component: select_1.default, options: getManagers(), disabled: isServiceProvider, validate: validators.required, require: "true", name: "intake_manager", type: "text", placeholder: t("client_add_form.fields.intake_manager.placeholder") }))));
};
