"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _a;
var lists_1 = require("./../../constants/lists");
var lodash_1 = require("lodash");
var items_converter_1 = require("./items_converter");
var entityInitData = {
    loading: true,
    query: {
        page: 1
    },
    items: [],
    filters: {
        usePreviousFilters: false,
        previous: {}
    }
};
var initData = {};
lodash_1.each(lists_1.LIST_ENTITIES, function (entityName) {
    initData[entityName] = lodash_1.cloneDeep(entityInitData);
});
var itemsConverterMap = (_a = {},
    _a[lists_1.LIST_ENTITIES.DAY_SESSIONS] = items_converter_1.daySessionsConverter,
    _a);
function list(state, action) {
    if (state === void 0) { state = initData; }
    switch (action.type) {
        case lists_1.LIST_ACTIONS.GET_LIST:
            var newState = lodash_1.cloneDeep(state);
            if (action.payload.entity) {
                newState[action.payload.entity].loading = true;
            }
            return newState;
        case lists_1.LIST_ACTIONS.GET_LIST_SUCCESS:
            newState = lodash_1.cloneDeep(state);
            var entity = action.payload.entity;
            if (entity) {
                newState[action.payload.entity] = {
                    items: itemsConverterMap[entity]
                        ? itemsConverterMap[entity](newState[action.payload.entity], action.payload.result.data, action.payload.query)
                        : action.payload.result.data,
                    meta: action.payload.result.meta,
                    filters: newState[action.payload.entity] ? newState[action.payload.entity].filters : null,
                    loading: false,
                    query: action.payload.query
                };
            }
            return newState;
        case lists_1.LIST_ACTIONS.GET_LIST_FAIL:
            newState = lodash_1.cloneDeep(state);
            if (action.payload.entity) {
                newState[action.payload.entity] = {
                    items: [],
                    meta: action.payload.result.meta,
                    query: action.payload.query,
                    filters: newState[action.payload.entity].filters,
                    loading: false
                };
            }
            return newState;
        // FILTERS
        case lists_1.LIST_ACTIONS.SAVE_FILTERS:
            newState = lodash_1.cloneDeep(state);
            if (action.payload.entity) {
                newState[action.payload.entity].filters.previous = action.payload.filters;
            }
            return newState;
        case lists_1.LIST_ACTIONS.ENABLE_PREVIOUS_FILTERS:
            newState = lodash_1.cloneDeep(state);
            if (action.payload.entity) {
                newState[action.payload.entity].filters.usePreviousFilters = true;
            }
            return newState;
        case lists_1.LIST_ACTIONS.DISABLE_PREVIOUS_FILTERS:
            newState = lodash_1.cloneDeep(state);
            if (action.payload.entity) {
                newState[action.payload.entity].filters.usePreviousFilters = false;
            }
            return newState;
        case lists_1.LIST_ACTIONS.CLEAN_FILTERS:
            newState = lodash_1.cloneDeep(state);
            if (action.payload.entity) {
                newState[action.payload.entity].query = entityInitData.query;
                newState[action.payload.entity].filters.previous = {};
                newState[action.payload.entity].loading = true;
            }
            return newState;
        default:
            return state;
    }
}
exports.default = list;
exports.entityDefaultParams = entityInitData;
