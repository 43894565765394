"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_form_1 = require("redux-form");
var Cost = /** @class */ (function (_super) {
    __extends(Cost, _super);
    function Cost() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Cost.prototype.render = function () {
        var _a = this.props, values = _a.values, rateType = _a.rateType, rates = _a.rates, sessionDuration = _a.sessionDuration, _b = _a.recurring, recurring = _b === void 0 ? false : _b;
        var cost = '-';
        if (values && rates && sessionDuration && rateType) {
            var beginAt = recurring ? values.recurring.begin_time : values.oneTime.begin_at;
            var endAt = recurring ? values.recurring.end_time : values.oneTime.end_at;
            var duration = moment(endAt).diff(moment(beginAt), 'minutes');
            // @ts-ignore
            var result = (rates[rateType] * duration / sessionDuration).toFixed(2);
            // @ts-ignore
            cost = result < 0 ? 0 : result;
        }
        return React.createElement("div", { className: "value-replacer" },
            "$",
            cost);
    };
    Cost = __decorate([
        react_redux_1.connect(function (state) {
            return {
                values: redux_form_1.getFormValues("add_new_session")(state)
            };
        })
    ], Cost);
    return Cost;
}(React.Component));
exports.default = Cost;
