"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var redux_form_1 = require("redux-form");
var lodash_1 = require("lodash");
var select_1 = require("../form/fields/select");
var selected_time_1 = require("./selected-time");
var prefered_time_1 = require("helpers/prefered_time");
var clickEvent = document.createEvent("MouseEvents");
clickEvent.initEvent("mousedown", true, true);
var triggerClick = function (node) {
    setTimeout(function () { return node.dispatchEvent(clickEvent); });
};
var ScheduleDay = /** @class */ (function (_super) {
    __extends(ScheduleDay, _super);
    function ScheduleDay() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.prevOptionId = null;
        _this.state = {
            selected: !lodash_1.isEmpty(_this.props.initialDayValues)
        };
        _this.addSelection = function () {
            var _a = _this.props, changeDayValue = _a.changeDayValue, day = _a.day;
            changeDayValue(day, prefered_time_1.defaultScheduleTime);
            _this.setState({ selected: true });
        };
        _this.removeSelection = function () {
            var _a = _this.props, changeDayValue = _a.changeDayValue, day = _a.day;
            changeDayValue(day, []);
            _this.setState({ selected: false });
        };
        _this.optionClick = function (e) {
            var _a = e.target.id.split("--option-"), prefix = _a[0], currentOptionId = _a[1];
            _this.handleClickWithShift(e, +currentOptionId, prefix);
            _this.savePrevOptionId(e, +currentOptionId);
        };
        _this.savePrevOptionId = function (e, currentOptionId) {
            if (!e.target.classList.contains("Select-option"))
                return;
            _this.prevOptionId = currentOptionId;
        };
        _this.resetPrevOptionId = function () {
            _this.prevOptionId = null;
        };
        _this.handleClickWithShift = function (e, current, prefix) {
            var buttonPressed = e.shiftKey || e.metaKey;
            if (!buttonPressed || _this.prevOptionId === null)
                return;
            var selectedClass = "is-selected";
            var shouldSelect = e.target.classList.contains(selectedClass);
            var prev = _this.prevOptionId;
            var ids = prev < current ? lodash_1.range(prev + 1, current) : lodash_1.range(current - 1, prev);
            ids.forEach(function (id) {
                var node = document.getElementById(prefix + "--option-" + id);
                var optionIsSelected = node.classList.contains(selectedClass);
                if (optionIsSelected && !shouldSelect) {
                    triggerClick(node);
                }
                if (!optionIsSelected && shouldSelect) {
                    triggerClick(node);
                }
            });
        };
        return _this;
    }
    ScheduleDay.prototype.render = function () {
        var _a = this.props, day = _a.day, dayPrefix = _a.dayPrefix, _b = _a.editable, editable = _b === void 0 ? true : _b, _c = _a.initialDayValues, initialDayValues = _c === void 0 ? [] : _c;
        var selected = this.state.selected;
        return (React.createElement("li", { onClick: selected ? this.optionClick : this.addSelection, className: classNames({
                schedule_preference__item: true,
                schedule_preference__item_selected: selected,
                schedule_preference__item_view: !editable
            }) },
            React.createElement("p", null, day),
            React.createElement("div", { className: "schedule_preference__time" }, editable ? (React.createElement(redux_form_1.Field, { component: select_1.default, valueComponent: selected_time_1.default, name: dayPrefix + "." + day, searchable: false, multi: true, options: prefered_time_1.hourOptions, removeSelected: false, closeOnSelect: false, onClose: this.resetPrevOptionId })) : (React.createElement(selected_time_1.default, { values: prefered_time_1.createHourOptions(initialDayValues) }))),
            selected && editable && (React.createElement("button", { type: "button", className: "icon close_grey", onClick: this.removeSelection }))));
    };
    ScheduleDay.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.initialDayValues !== nextProps.initialDayValues) {
            this.setState({ selected: !lodash_1.isEmpty(nextProps.initialDayValues) });
        }
    };
    return ScheduleDay;
}(React.Component));
exports.default = ScheduleDay;
