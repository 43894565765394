"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var stripe_1 = require("actions/stripe");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var spinner_1 = require("../spinner");
var StripeLoginLink = /** @class */ (function (_super) {
    __extends(StripeLoginLink, _super);
    function StripeLoginLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StripeLoginLink.prototype.render = function () {
        var _a = this.props, t = _a.t, stripe = _a.stripe;
        if (stripe.login_link_loading) {
            return (React.createElement("div", { className: "login-link-spinner" },
                React.createElement(spinner_1.default, { inline: true })));
        }
        return stripe.is_connected !== null ? (React.createElement(React.Fragment, null, stripe.is_connected && stripe.login_link && (React.createElement("a", { className: "go-to-stripe", href: "" + stripe.login_link, target: "_blank" }, t("stripe.login_link"))))) : null;
    };
    StripeLoginLink.prototype.componentWillMount = function () {
        if (this.props.stripe.is_connected &&
            !this.props.stripe.login_link &&
            !this.props.stripe.login_link_loading) {
            this.props.stripeGetLoginLinkAction();
        }
    };
    StripeLoginLink.prototype.componentWillReceiveProps = function (nextProps) {
        if (nextProps.stripe.is_connected &&
            !this.props.stripe.is_connected &&
            !this.props.stripe.login_link_loading) {
            this.props.stripeGetLoginLinkAction();
        }
    };
    StripeLoginLink = __decorate([
        react_redux_1.connect(function (state) {
            return {
                stripe: state.billing.stripe
            };
        }, function (dispatch) {
            return {
                stripeGetLoginLinkAction: redux_1.bindActionCreators(stripe_1.stripeGetLoginLink, dispatch)
            };
        }),
        react_i18next_1.translate()
    ], StripeLoginLink);
    return StripeLoginLink;
}(React.Component));
exports.default = StripeLoginLink;
