"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var moment = require("moment");
var classNames = require("classnames");
var lodash_1 = require("lodash");
var description_table_1 = require("../../invoice_edit/item/description_table");
var lists_1 = require("constants/lists");
require("./style.less");
var popup_1 = require("components/popup");
var invoice_calculation_1 = require("helpers/invoice_calculation");
var stateless_components_1 = require("./stateless-components");
var stateless_components_2 = require("./stateless-components");
var validators_1 = require("components/form/wrapper/validators");
var invoice_1 = require("../../../models/invoice");
var is_admin_or_manager_1 = require("../../../components/helpers/user/is_admin_or_manager");
var invoice_service_1 = require("../../../services/invoice.service");
var InvoiceView = /** @class */ (function (_super) {
    __extends(InvoiceView, _super);
    function InvoiceView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            openModal: false,
            paidConfirmation: false,
            emailConfirmation: false,
            cancelConfirmation: false,
            selectedEmail: null,
            sendEmailError: undefined,
            options: []
        };
        _this.openPaidConfirmation = function () {
            _this.setState({
                paidConfirmation: true,
                openModal: true
            });
        };
        _this.openEmailConfirmation = function () {
            _this.setState({
                emailConfirmation: true,
                openModal: true
            });
        };
        _this.openCancelConfirmation = function () {
            _this.setState({
                cancelConfirmation: true,
                openModal: true
            });
        };
        _this.closeModal = function () {
            _this.setState({
                openModal: false,
                paidConfirmation: false,
                emailConfirmation: false,
                cancelConfirmation: false
            });
        };
        _this.onChangeEmail = function (option) {
            _this.setState({ selectedEmail: option, sendEmailError: undefined });
        };
        _this.sendEmail = function (e) {
            e.preventDefault();
            var _a = _this.props, sendInvoice = _a.sendInvoice, t = _a.t;
            var selectedEmail = _this.state.selectedEmail;
            if (!selectedEmail || !selectedEmail.value) {
                _this.setState({
                    sendEmailError: t("invoice_view.new_email_validation")
                });
            }
            else {
                _this.setState({ sendEmailError: undefined });
                sendInvoice(selectedEmail.value);
            }
        };
        return _this;
    }
    InvoiceView.prototype.render = function () {
        var _a = this.props, t = _a.t, invoice = _a.invoice, onBackToListHandler = _a.onBackToListHandler, markAsPaid = _a.markAsPaid, cancelInvoice = _a.cancelInvoice, isConnected = _a.isConnected, currentUserRole = _a.currentUserRole;
        var dueDate = moment(invoice.due_date).format("DD MMMM YYYY");
        var isAdminOrManager = is_admin_or_manager_1.adminOrManager(currentUserRole);
        var userIsTutor = currentUserRole === "service_provider";
        var isEditable = [
            invoice_1.INVOICE_STATUSES.DRAFT,
            invoice_1.INVOICE_STATUSES.SENT
        ].includes(invoice.status);
        var showPanel = [
            invoice_1.INVOICE_STATUSES.DRAFT,
            invoice_1.INVOICE_STATUSES.SENT,
            invoice_1.INVOICE_STATUSES.IN_PROGRESS,
            invoice_1.INVOICE_STATUSES.REJECTED,
            invoice_1.INVOICE_STATUSES.ACH_VERIFICATION,
        ].includes(invoice.status);
        var _b = this.state, openModal = _b.openModal, paidConfirmation = _b.paidConfirmation, emailConfirmation = _b.emailConfirmation, cancelConfirmation = _b.cancelConfirmation;
        return (invoice &&
            t && (React.createElement("div", { className: "view-container view-container_invoice" },
            React.createElement("div", { className: "view-container_back" },
                React.createElement(react_router_dom_1.Link, { to: "/invoices", onClick: function () {
                        return onBackToListHandler(lists_1.LIST_ENTITIES.ALL_INVOICES_LIST);
                    } },
                    React.createElement("i", { className: "fas fa-arrow-left margin-right-10" }),
                    t("client_view.back"))),
            React.createElement("div", { className: "view-container_edit view-container_edit__invoice" },
                React.createElement("p", { className: "title" }, t("invoice_view.details")),
                !isAdminOrManager && isEditable && (React.createElement("span", { className: "icon edit" },
                    React.createElement(react_router_dom_1.Link, { to: "/invoices/edit/" + invoice.id })))),
            React.createElement("div", { className: "view-container_title" },
                React.createElement("h2", null,
                    invoice.guid,
                    " ",
                    t("invoice_view.from"),
                    " ",
                    invoice.service_provider_name),
                React.createElement("p", null, invoice_service_1.isInvoicePaidManually(invoice)
                    ? t("invoice.statuses.manually_paid")
                    : lodash_1.startCase(invoice_1.INVOICE_STATUSES_NAMES[invoice.status]))),
            React.createElement("div", { className: "client-invoice-view" },
                React.createElement("div", { className: "invoice-wrapper" },
                    React.createElement("h1", null, invoice.guid),
                    React.createElement("div", { className: "invoice-title-separator" }),
                    React.createElement("div", { className: "white-table content-table" },
                        React.createElement("table", { className: "invoice-main-table" },
                            React.createElement("tbody", null,
                                React.createElement("tr", { className: "not-selectable" },
                                    React.createElement("td", { className: "main-table_title" }, t("invoice.table.bill_to")),
                                    React.createElement("td", { className: "not-selectable" }, invoice.client_name)),
                                React.createElement("tr", { className: "not-selectable" },
                                    React.createElement("td", { className: "main-table_title" }, t("invoice.table.number")),
                                    React.createElement("td", { className: "not-selectable" }, invoice.guid)),
                                React.createElement("tr", { className: "not-selectable" },
                                    React.createElement("td", { className: "main-table_title" }, t("invoice.table.amount")),
                                    React.createElement("td", { className: "not-selectable" },
                                        "$",
                                        invoice_calculation_1.default.amountToFixed(invoice.amount))),
                                React.createElement("tr", { className: "not-selectable" },
                                    React.createElement("td", { className: "main-table_title" }, t("invoice.table.due_by")),
                                    React.createElement("td", { className: "not-selectable" }, dueDate))))),
                    invoice.note && (React.createElement("div", { className: "invoice-view_description" },
                        React.createElement("p", null, invoice.note)))),
                React.createElement("div", { className: "invoice-table-wrapper" },
                    React.createElement(description_table_1.default, { isEditable: false, userIsTutor: userIsTutor, itemsCurrentValues: invoice.items, isAdminOrManager: isAdminOrManager, summ: parseFloat(String(invoice.amount)) }))),
            showPanel && !isAdminOrManager && (React.createElement("div", { className: "view-container_back admin-panel" },
                invoice.status === invoice_1.INVOICE_STATUSES.DRAFT && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "admin-panel__inner" },
                        React.createElement("button", { className: "button button__transparent", onClick: this.openPaidConfirmation },
                            React.createElement("i", { className: "far fa-check-circle margin-right-10" }),
                            t("invoice_view.actions.mark_as_paid"))),
                    React.createElement("div", { className: "admin-panel__inner" },
                        React.createElement("button", { onClick: this.openEmailConfirmation, className: "button button__red" }, t("invoice_view.actions.send"))))),
                invoice.status === invoice_1.INVOICE_STATUSES.SENT && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "admin-panel__inner" },
                        React.createElement("button", { className: "button button__transparent", onClick: this.openCancelConfirmation },
                            React.createElement("i", { className: "far fa-times-circle margin-right-10" }),
                            t("invoice_view.actions.cancel")),
                        React.createElement("button", { className: "button button__transparent", onClick: this.openPaidConfirmation },
                            React.createElement("i", { className: "far fa-check-circle margin-right-10" }),
                            t("invoice_view.actions.mark_as_paid"))),
                    React.createElement("div", { className: "admin-panel__inner" },
                        React.createElement("button", { onClick: this.openEmailConfirmation, className: "button button__red" }, t("invoice_view.actions.send_again"))))),
                invoice.status === invoice_1.INVOICE_STATUSES.ACH_VERIFICATION && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "admin-panel__inner" },
                        React.createElement("button", { className: "button button__transparent", onClick: this.openCancelConfirmation },
                            React.createElement("i", { className: "far fa-times-circle margin-right-10" }),
                            t("invoice_view.actions.cancel"))),
                    React.createElement("div", { className: "admin-panel__inner" },
                        React.createElement("button", { onClick: this.openEmailConfirmation, className: "button button__red" }, t("invoice_view.actions.send_again"))))),
                invoice.status === invoice_1.INVOICE_STATUSES.IN_PROGRESS && (React.createElement("p", null, t("invoice_view.in_progress_message"))),
                invoice.status === invoice_1.INVOICE_STATUSES.REJECTED && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "admin-panel__inner" },
                        React.createElement("p", null, t("invoice_view.rejected_message"))),
                    React.createElement("button", { className: "button button__transparent", onClick: this.openCancelConfirmation },
                        React.createElement("i", { className: "far fa-times-circle margin-right-10" }),
                        t("invoice_view.actions.cancel")))))),
            React.createElement(popup_1.default, { openned: openModal, extendedClass: classNames({
                    "edit-confirmation": true,
                    "email-confirmation": emailConfirmation && isConnected
                }), onClose: this.closeModal },
                paidConfirmation && (React.createElement(stateless_components_1.PaidConfirmationPopupContent, { t: t, markAsPaid: markAsPaid, closeModal: this.closeModal })),
                emailConfirmation && isConnected && (React.createElement(stateless_components_2.EmailConfirmationPopupContent, { t: t, invoice: invoice, sendEmail: this.sendEmail, validators: this.props.validators, options: this.state.options, onChangeEmail: this.onChangeEmail, selectedEmail: this.state.selectedEmail, sendEmailError: this.state.sendEmailError })),
                emailConfirmation && !isConnected && (React.createElement(stateless_components_2.ConnectStripeWarningPopupContent, { t: t, closeModal: this.closeModal })),
                cancelConfirmation && (React.createElement(stateless_components_2.CancelConfirmationPopupContent, { t: t, cancelInvoice: cancelInvoice, closeModal: this.closeModal }))))));
    };
    InvoiceView.prototype.componentDidMount = function () {
        var options = this.props.clientEmails.map(function (email) { return ({
            label: email,
            value: email
        }); });
        var default_client_email = this.props.invoice.client_email;
        var defaultOption = default_client_email
            ? options.find(function (option) { return option.value === default_client_email; })
            : null;
        this.setState({
            options: options,
            selectedEmail: defaultOption
        });
    };
    InvoiceView = __decorate([
        react_i18next_1.translate(),
        validators_1.default
    ], InvoiceView);
    return InvoiceView;
}(React.Component));
exports.default = react_i18next_1.translate()(InvoiceView);
