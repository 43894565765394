"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefered_time_1 = require("helpers/prefered_time");
var React = require("react");
var SelectedTime = function (_a) {
    var values = _a.values, value = _a.value;
    if (value && values[0] !== value)
        return null;
    var durations = prefered_time_1.hoursToDurations(values);
    var label = durations
        .map(function (duration) { return duration.start_at + " - " + duration.end_at; })
        .join("/");
    return React.createElement("div", { className: "schedule_preference__selected-time" }, label);
};
exports.default = SelectedTime;
