"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var popup_1 = require("../popup");
var ModalWindow = /** @class */ (function (_super) {
    __extends(ModalWindow, _super);
    function ModalWindow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isModalOpened: false
        };
        _this.handleSubmitModal = function () {
            _this.props.onSubmit();
        };
        _this.handleCancelModal = function () {
            _this.props.onCancel();
        };
        return _this;
    }
    ModalWindow.prototype.render = function () {
        var _a = this.props, _b = _a.cancelTextPath, cancelTextPath = _b === void 0 ? "modal.cancel" : _b, _c = _a.submitTextPath, submitTextPath = _c === void 0 ? "modal.submit" : _c, _d = _a.titlePath, titlePath = _d === void 0 ? "modal.title" : _d, textPath = _a.textPath, isModalOpened = _a.isModalOpened, t = _a.t;
        return t ? (React.createElement(popup_1.default, { openned: isModalOpened, onClose: this.handleCancelModal },
            React.createElement("div", null,
                React.createElement("h2", { className: "modal-title" }, t(titlePath)),
                textPath && React.createElement("p", { className: "modal-text" }, t(textPath)),
                React.createElement("div", { className: "modal-actions" },
                    React.createElement("button", { onClick: this.handleCancelModal, className: "button button__cancel half-width" }, t(cancelTextPath)),
                    React.createElement("button", { onClick: this.handleSubmitModal, className: "button button__red" }, t(submitTextPath)))))) : null;
    };
    ModalWindow = __decorate([
        react_i18next_1.translate()
    ], ModalWindow);
    return ModalWindow;
}(React.Component));
exports.default = ModalWindow;
