"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_form_1 = require("redux-form");
var react_i18next_1 = require("react-i18next");
var fields_1 = require("components/form/fields");
var phone_1 = require("../../../../provider_add/components/edit_form/phone");
var email_1 = require("../../../../provider_add/components/edit_form/email");
var address_1 = require("components/form/parts/address");
var input_1 = require("config/input");
var RenderParents = /** @class */ (function (_super) {
    __extends(RenderParents, _super);
    function RenderParents() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleAdd = function () {
            var fields = _this.props.fields;
            if (fields && fields.length < 20) {
                fields.push("");
            }
        };
        _this.handleRemove = function (index) {
            var fields = _this.props.fields;
            if (fields) {
                fields.remove(index);
            }
        };
        return _this;
    }
    RenderParents.prototype.render = function () {
        var _this = this;
        var _a = this.props, fields = _a.fields, t = _a.t, validators = _a.validators;
        return t ? (React.createElement("div", { className: "dynamic-block-container" },
            fields.map(function (parent, index) { return (React.createElement("div", { key: index, className: "dynamic-block" },
                React.createElement("span", { className: "dynamic-block-header" }, t("client_add_form.parents_title", { index: index + 1 })),
                React.createElement("div", { className: "dynamic-fields-container" },
                    React.createElement("div", { className: "two-line" },
                        React.createElement(redux_form_1.Field, { className: "part-input", label: t("client_add_form.fields.parent_first_name.label"), component: fields_1.default, type: "text", require: "true", name: parent + ".first_name", validate: validators.required, maxLength: input_1.default.first_name.maxLength, placeholder: t("client_add_form.fields.parent_first_name.placeholder") }),
                        React.createElement(redux_form_1.Field, { className: "part-input", label: t("client_add_form.fields.parent_last_name.label"), component: fields_1.default, type: "text", require: "true", name: parent + ".last_name", validate: validators.required, maxLength: input_1.default.last_name.maxLength, placeholder: t("client_add_form.fields.parent_last_name.placeholder") })),
                    React.createElement("div", { className: "email-phone-container two-line margin-bottom vertical-top" },
                        React.createElement("div", { className: "item" },
                            React.createElement(redux_form_1.FieldArray, { name: parent + ".phones", component: phone_1.default, validators: validators })),
                        React.createElement("div", { className: "item" },
                            React.createElement(redux_form_1.FieldArray, { name: parent + ".emails", component: email_1.default, validators: validators }))),
                    React.createElement(address_1.default, __assign({}, _this.props, { namePrefix: parent }))),
                React.createElement("a", { className: "delete-dynamic-block " + (fields.length === 1 ? "hidden-visible" : "") + " ", onClick: _this.handleRemove.bind(_this, index) }, "Remove"))); }),
            React.createElement("div", { className: "general-actions" },
                React.createElement("button", { className: "button button__transparent", type: "button", onClick: this.handleAdd }, t("provider_add_form.add_more"))))) : null;
    };
    RenderParents.prototype.componentDidMount = function () {
        var fields = this.props.fields;
        if (fields && fields.length === 0) {
            fields.push("");
        }
    };
    RenderParents = __decorate([
        react_i18next_1.translate()
        // TODO rename component, render is method
    ], RenderParents);
    return RenderParents;
}(React.Component));
exports.default = RenderParents;
