"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var redux_form_1 = require("redux-form");
var React = require("react");
var fields_1 = require("../fields");
var input_1 = require("config/input");
var AddressBlock = /** @class */ (function (_super) {
    __extends(AddressBlock, _super);
    function AddressBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddressBlock.prototype.render = function () {
        var _a = this.props, t = _a.t, required = _a.required, validators = _a.validators;
        var _b = this.props.namePrefix, namePrefix = _b === void 0 ? "" : _b;
        if (namePrefix !== "") {
            namePrefix += ".";
        }
        var fullNamePrefix = namePrefix + "address_attributes.";
        return t ? (React.createElement(React.Fragment, null,
            React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.street_address.label"), component: fields_1.default, name: fullNamePrefix + "street", require: required ? "true" : undefined, validate: required ? [validators.required] : undefined, type: "text", maxLength: input_1.default.street_address.maxLength, placeholder: t("provider_add_form.fields.street_address.placeholder") }),
            React.createElement("div", { className: "three-line" },
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.city.label"), component: fields_1.default, name: fullNamePrefix + "city", require: required ? "true" : undefined, validate: required ? [validators.required] : undefined, type: "text", maxLength: input_1.default.city.maxLength, placeholder: t("provider_add_form.fields.city.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.state.label"), component: fields_1.default, name: fullNamePrefix + "state", require: required ? "true" : undefined, validate: required ? [validators.required] : undefined, type: "text", maxLength: input_1.default.state.maxLength, placeholder: t("provider_add_form.fields.state.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.zip_code.label"), component: fields_1.default, name: fullNamePrefix + "zip_code", require: required ? "true" : undefined, validate: required
                        ? [validators.required, validators.maxLength10]
                        : [validators.maxLength10], type: "number", maxLength: 10, placeholder: t("provider_add_form.fields.zip_code.placeholder") })))) : null;
    };
    return AddressBlock;
}(React.Component));
exports.default = AddressBlock;
