"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_cropper_1 = require("react-cropper");
require("cropperjs/dist/cropper.css");
var classNames = require("classnames");
var popup_1 = require("../popup");
require("./style.less");
var ImageUploader = /** @class */ (function (_super) {
    __extends(ImageUploader, _super);
    function ImageUploader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            imageSrc: _this.props.currentImg || "",
            imageFilename: _this.props.imageName || "",
            error: false,
            openCroping: false
        };
        _this.changeImage = function (e) {
            var reader = new FileReader();
            var size = e.target.files[0].size / 1024 / 1024;
            if (size <= 2) {
                reader.readAsDataURL(e.target.files[0]);
                _this.setState({ imageFilename: e.target.files[0].name });
                reader.onload = function () {
                    if (typeof reader.result === "string") {
                        _this.setState({ imageSrc: reader.result });
                    }
                };
            }
            else {
                _this.setState({ error: true });
            }
        };
        _this.openDialog = function () { return _this.setState({ openCroping: true }); };
        _this.closeCropping = function () { return _this.setState({ openCroping: false }); };
        _this.handleCrop = function () {
            var croppedImage = _this.refs.cropper
                .getCroppedCanvas()
                .toDataURL();
            _this.props.changeImage(croppedImage, _this.state.imageFilename, false);
            _this.setState({ openCroping: false });
        };
        _this.removeSelected = function () {
            _this.setState({ imageSrc: "", imageFilename: "", error: false });
        };
        _this.deleteOldImage = function () {
            _this.removeSelected();
            _this.props.changeImage("", "", true);
        };
        return _this;
    }
    ImageUploader.prototype.render = function () {
        var _a = this.state, imageSrc = _a.imageSrc, openCroping = _a.openCroping, error = _a.error;
        var _b = this.props, currentImg = _b.currentImg, t = _b.t;
        return (React.createElement("div", { className: "change-avatar" },
            React.createElement("button", { type: "button", className: "button", onClick: this.openDialog },
                React.createElement("div", { className: classNames({
                        view__avatar: true,
                        view__avatar_default: !currentImg
                    }) }, currentImg && React.createElement("img", { src: currentImg })),
                currentImg
                    ? t("change_image.change_image")
                    : t("change_image.upload_photo")),
            currentImg && (React.createElement("button", { type: "button", className: "button view__remove-avatar", onClick: this.deleteOldImage }, t("change_image.delete_photo"))),
            React.createElement(popup_1.default, { openned: openCroping, extendedClass: "change-avatar-popup", onClose: this.closeCropping }, imageSrc ? (React.createElement(React.Fragment, null,
                React.createElement(react_cropper_1.default, { ref: "cropper", src: imageSrc, style: { height: 400, width: "100%" }, aspectRatio: 1 / 1, guides: false, viewMode: 1 }),
                React.createElement("button", { type: "button", className: "button button__red", onClick: this.handleCrop }, t("change_image.crop")),
                React.createElement("button", { type: "button", className: "button button__white view__remove-avatar", onClick: this.deleteOldImage }, t("change_image.remove_photo")))) : (React.createElement("div", null,
                React.createElement("label", { htmlFor: "upload-image", className: " button__red button view__click-to-change" },
                    t("change_image.upload_photo"),
                    React.createElement("input", { type: "file", id: "upload-image", accept: "image/jpeg, image/pjpeg, image/png", onChange: this.changeImage })),
                error && (React.createElement("div", null,
                    React.createElement("p", { className: "upload-image__error" }, t("change_image.error")),
                    React.createElement("button", { type: "button", className: "button__white view__remove-avatar", onClick: this.removeSelected }, t("change_image.remove_photo")))))))));
    };
    ImageUploader = __decorate([
        react_i18next_1.translate()
    ], ImageUploader);
    return ImageUploader;
}(React.Component));
exports.default = ImageUploader;
