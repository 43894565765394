"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var react_i18next_1 = require("react-i18next");
var PasswordResetSuccess = function (_a) {
    var t = _a.t;
    return (React.createElement("div", null,
        React.createElement("h1", null, t("reset_password.new_password")),
        React.createElement("p", { className: "hint" }, t("reset_password.reset_success")),
        React.createElement(react_router_dom_1.Link, { className: "$ button button__red button__block", to: "/login" }, t("reset_password.back_to_login"))));
};
exports.default = react_i18next_1.translate()(PasswordResetSuccess);
