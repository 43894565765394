"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var top_menu_1 = require("data/top_menu");
require("./style.less");
var nav_1 = require("../nav");
var user_1 = require("../helpers/user");
var container_1 = require("./user_info/container");
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Header.prototype.render = function () {
        var _a = this.props, withNav = _a.withNav, user = _a.user, logoToLanding = _a.logoToLanding;
        return (React.createElement("header", null,
            logoToLanding ? (React.createElement("a", { className: "logo", href: "https://www.organizationaltutors.com/" },
                React.createElement("img", { src: require("images/common/logo.png"), alt: "" }))) : (React.createElement("a", { className: "logo", href: "#" },
                React.createElement("img", { src: require("images/common/logo.png"), alt: "" }))),
            withNav ? (React.createElement(nav_1.default, { extendClassName: "header-nav", items: top_menu_1.default((user && user.role)) })) : null,
            React.createElement(container_1.default, null)));
    };
    Header.defaultProps = {
        withNav: true
    };
    Header = __decorate([
        user_1.default,
        react_redux_1.connect(function (state) { return ({
            user: state.user.my
        }); })
    ], Header);
    return Header;
}(React.Component));
exports.default = Header;
