"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var sortable_1 = require("./sortable");
var RenderEductaions = /** @class */ (function (_super) {
    __extends(RenderEductaions, _super);
    function RenderEductaions() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onSortEnd = function (props) {
            var oldIndex = props.oldIndex, newIndex = props.newIndex;
            _this.props.fields.move(oldIndex, newIndex);
        };
        return _this;
    }
    RenderEductaions.prototype.render = function () {
        var _a = this.props, fields = _a.fields, degrees = _a.degrees, validators = _a.validators;
        return (React.createElement(sortable_1.default, { fields: fields, degrees: degrees, useDragHandle: true, onSortEnd: this.onSortEnd, validators: validators }));
    };
    RenderEductaions.prototype.componentDidMount = function () {
        var fields = this.props.fields;
        if (fields && fields.length === 0) {
            fields.push("");
        }
    };
    return RenderEductaions;
}(React.Component));
exports.default = RenderEductaions;
