"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var FieldRadio = /** @class */ (function (_super) {
    __extends(FieldRadio, _super);
    function FieldRadio() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FieldRadio.prototype.render = function () {
        var _this = this;
        var _a;
        var props = this.props;
        var meta = props.meta;
        var isErrors = meta.touched && meta.error;
        var label = props.label;
        var disabled = props.disabled;
        var value = props.radioValue;
        return (React.createElement("div", { className: "field" },
            React.createElement("input", __assign({ ref: function (item) { return (_this.input = item); }, className: classnames_1.default((_a = {
                        "input-error": isErrors
                    },
                    _a[props.className] = !!props.className,
                    _a)), autoFocus: props.autoFocus }, props.input, { value: value, disabled: disabled, type: "radio", id: props.id })),
            React.createElement("label", { className: "label-for-radio", htmlFor: props.id }, label),
            React.createElement("span", { className: "error" }, isErrors && meta.error),
            props.renderInner ? props.renderInner(props) : null,
            props.underFieldActionButton ? (React.createElement("div", { className: classnames_1.default({
                    "underfield-button": true,
                    "with-error": isErrors
                }) }, props.underFieldActionButton)) : null));
    };
    return FieldRadio;
}(React.Component));
exports.default = FieldRadio;
