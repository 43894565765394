"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var ExperienceHelper = /** @class */ (function () {
    function ExperienceHelper() {
    }
    ExperienceHelper.getOptions = function () {
        return this.ranges.map(function (range) { return ({
            value: range.id,
            label: range.title
        }); });
    };
    ExperienceHelper.findRangeById = function (id) {
        return _.find(ExperienceHelper.ranges, function (range) {
            if (range && id) {
                return range.id === id;
            }
            return false;
        });
    };
    ExperienceHelper.getToById = function (id) {
        var range = ExperienceHelper.findRangeById(id);
        var nowYear = new Date().getFullYear();
        if (range && range.from)
            return nowYear - range.from;
    };
    ExperienceHelper.getFromById = function (id) {
        var range = ExperienceHelper.findRangeById(id);
        var nowYear = new Date().getFullYear();
        if (range && range.to)
            return nowYear - range.to;
    };
    ExperienceHelper.ranges = [
        {
            id: 1,
            title: "1-2 years",
            from: 1,
            to: 2
        },
        {
            id: 2,
            title: "3-5 years",
            from: 3,
            to: 5
        },
        {
            id: 3,
            title: "6-10 years",
            from: 6,
            to: 10
        },
        {
            id: 4,
            title: "More than 10 years",
            from: 10
        },
        {
            id: 0,
            title: "Any"
        }
    ];
    return ExperienceHelper;
}());
exports.default = ExperienceHelper;
