"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./style.less");
var react_i18next_1 = require("react-i18next");
var SortTitle = /** @class */ (function (_super) {
    __extends(SortTitle, _super);
    function SortTitle() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleSelectSort = function () {
            var _a = _this.props, onSelect = _a.onSelect, sortName = _a.sortName, currentSort = _a.currentSort, descPreffix = _a.descPreffix;
            if (onSelect && sortName) {
                onSelect(currentSort === sortName ? currentSort + descPreffix : sortName);
            }
        };
        return _this;
    }
    SortTitle.prototype.render = function () {
        var _a = this.props, t = _a.t, text = _a.text, currentSort = _a.currentSort, sortName = _a.sortName, descPreffix = _a.descPreffix;
        return t && descPreffix ? (React.createElement("div", { className: "sort-title", onClick: this.handleSelectSort },
            React.createElement("span", null, text ? t(text) : null),
            currentSort === sortName ? (React.createElement("i", { className: "fas fa-arrow-up margin-left-10" })) : null,
            currentSort === sortName + descPreffix ? (React.createElement("i", { className: "fas fa-arrow-down margin-left-10" })) : null)) : null;
    };
    SortTitle.defaultProps = {
        descPreffix: "_desc"
    };
    SortTitle = __decorate([
        react_i18next_1.translate()
    ], SortTitle);
    return SortTitle;
}(React.Component));
exports.default = SortTitle;
