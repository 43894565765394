"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var row_1 = require("./row");
var table_footer_1 = require("./table_footer");
var invoice_1 = require("constants/invoice");
var InvoiceServiceItemForm = /** @class */ (function (_super) {
    __extends(InvoiceServiceItemForm, _super);
    function InvoiceServiceItemForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.addItem = function () {
            var _a = _this.props, fields = _a.fields, serviceProviderFee = _a.serviceProviderFee;
            fields.push({
                quantity: 1,
                serviceProviderFee: serviceProviderFee,
                session_duration: invoice_1.STANDARD_SESSION_DURATION,
            });
        };
        return _this;
    }
    InvoiceServiceItemForm.prototype.render = function () {
        var _a = this.props, t = _a.t, validators = _a.validators, fields = _a.fields, summ = _a.summ, itemsCount = _a.itemsCount, userIsTutor = _a.userIsTutor, clientSessionDuration = _a.clientSessionDuration;
        return (React.createElement(React.Fragment, null,
            React.createElement("tbody", { className: "small-table" }, fields.map(function (rowName, i) {
                return (React.createElement(row_1.default, { t: t, i: i, fields: fields, rowName: rowName, key: i, validators: validators, itemsCount: itemsCount, userIsTutor: userIsTutor, clientSessionDuration: clientSessionDuration }));
            })),
            React.createElement(table_footer_1.default, { addItem: this.addItem, summ: summ, userIsTutor: userIsTutor })));
    };
    InvoiceServiceItemForm = __decorate([
        react_i18next_1.translate()
    ], InvoiceServiceItemForm);
    return InvoiceServiceItemForm;
}(React.Component));
exports.default = InvoiceServiceItemForm;
