"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var react_router_dom_1 = require("react-router-dom");
var TablePagination = /** @class */ (function (_super) {
    __extends(TablePagination, _super);
    function TablePagination() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.step = 2;
        _this.getAllPageCount = function () {
            var meta = _this.props.meta;
            var allPages = 0;
            if (meta) {
                allPages = Math.ceil(meta.count_all / meta.count_on_page);
            }
            return allPages;
        };
        _this.getPages = function () {
            var result = [];
            var _a = _this.props, page = _a.page, meta = _a.meta;
            if (meta) {
                var allPages = _this.getAllPageCount();
                if (page) {
                    for (var i = page - _this.step; i <= page + _this.step; i++) {
                        if (i > 0 && i <= allPages) {
                            result.push(i);
                        }
                    }
                }
            }
            return result;
        };
        return _this;
    }
    TablePagination.prototype.render = function () {
        var _this = this;
        var _a = this.props, t = _a.t, meta = _a.meta, onUpdatePage = _a.onUpdatePage, page = _a.page;
        if (!(t && meta && meta.count_all > 0 && onUpdatePage && page)) {
            return null;
        }
        return (React.createElement("div", { className: "main-page-paginate" },
            page - this.step > 0 ? (React.createElement(React.Fragment, null,
                React.createElement("a", { onClick: function () {
                        onUpdatePage(1);
                    } }, t("paginate.first")),
                React.createElement("span", null, "..."))) : null,
            this.getPages().map(function (item, key) {
                return item !== page ? (React.createElement("a", { key: key, onClick: function () {
                        onUpdatePage(item);
                    } }, item)) : (React.createElement("span", { className: "current", key: key }, item));
            }),
            this.getAllPageCount() > page + this.step && (React.createElement(React.Fragment, null,
                React.createElement("span", null, "..."),
                React.createElement("a", { onClick: function () {
                        onUpdatePage(_this.getAllPageCount());
                    } }, t("paginate.last"))))));
    };
    TablePagination = __decorate([
        react_i18next_1.translate(),
        react_router_dom_1.withRouter
    ], TablePagination);
    return TablePagination;
}(React.Component));
exports.default = TablePagination;
