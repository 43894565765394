"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("constants/client");
function workspace(state, action) {
    if (state === void 0) { state = null; }
    switch (action.type) {
        case client_1.CLIENT_CONST.CLIENT_GET_WORKSPACE_SUCCESS:
            return Object.assign({}, action.payload);
        default:
            return state;
    }
}
exports.default = workspace;
