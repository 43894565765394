"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_form_1 = require("redux-form");
var prefered_time_1 = require("helpers/prefered_time");
var checkbox_1 = require("../../form/fields/checkbox");
var DayField = function (_a) {
    var fields = _a.fields, validators = _a.validators;
    return fields.map(function (parent, index) {
        var day = prefered_time_1.daysOfWeek[index];
        return (React.createElement(redux_form_1.Field, { component: checkbox_1.default, key: "key-" + index, id: "repeat-on-" + day, label: day.slice(0, 3), extendedClass: "field_day", name: parent, validate: !index && validators.evenOneRecurringSessionDay }));
    });
};
var RepeatOnDays = /** @class */ (function (_super) {
    __extends(RepeatOnDays, _super);
    function RepeatOnDays() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RepeatOnDays.prototype.render = function () {
        var validators = this.props.validators;
        return (React.createElement("div", { className: "repeat-on-days" },
            React.createElement(redux_form_1.FieldArray, { name: "recurring.days", component: DayField, validators: validators })));
    };
    return RepeatOnDays;
}(React.Component));
exports.default = RepeatOnDays;
