"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var simple_1 = require("layouts/simple");
var auth_1 = require("components/helpers/auth");
var react_router_1 = require("react-router");
var queryString = require("query-string");
var enter_email_form_container_1 = require("./enterEmailForm/enter_email.form_container");
var password_reset_from_container_1 = require("./resetPasswordForm/password_reset.from_container");
var ResetPasswordPage = /** @class */ (function (_super) {
    __extends(ResetPasswordPage, _super);
    function ResetPasswordPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ResetPasswordPage.prototype.render = function () {
        if (auth_1.default.isAuthorized()) {
            return React.createElement(react_router_1.Redirect, { to: "/" });
        }
        var location = this.props.location;
        var token = queryString.parse(location.search).token;
        return (React.createElement(simple_1.default, { logoToLanding: true },
            React.createElement("div", { className: "auth-page-content" }, token ? (React.createElement(password_reset_from_container_1.default, { token: token })) : (React.createElement(enter_email_form_container_1.default, null)))));
    };
    return ResetPasswordPage;
}(React.Component));
exports.default = ResetPasswordPage;
