"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i18n = require("i18next");
var XHR = require("i18next-xhr-backend");
var instance = i18n.use(XHR).init({
    debug: false,
    fallbackLng: "en",
    react: {
        wait: true
    }
});
exports.default = instance;
