"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var classNames = require("classnames");
require("./style.less");
var react_router_dom_1 = require("react-router-dom");
var spinner_1 = require("components/spinner");
var sort_title_1 = require("components/sort_title");
var ProvidersTable = /** @class */ (function (_super) {
    __extends(ProvidersTable, _super);
    function ProvidersTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClickRow = function (id) {
            var history = _this.props.history;
            if (history) {
                history.push("/providers/view/" + id);
            }
        };
        return _this;
    }
    ProvidersTable.prototype.render = function () {
        var _this = this;
        var _a = this.props, t = _a.t, items = _a.items, onReset = _a.onReset, currentSort = _a.currentSort, handleSelectSort = _a.handleSelectSort, isFilterEmpty = _a.isFilterEmpty;
        if (!items) {
            return React.createElement(spinner_1.default, null);
        }
        if (items.length === 0 && isFilterEmpty) {
            return (React.createElement("div", { className: "empty" },
                React.createElement("h2", null, t("providers_table.empty.no_providers"))));
        }
        if (items.length === 0) {
            return (React.createElement("div", { className: "empty" },
                React.createElement("h2", null, t("providers_table.empty.title")),
                React.createElement("p", null, t("providers_table.empty.text")),
                React.createElement("a", { onClick: onReset, className: "button button__white" }, t("providers_table.empty.clear"))));
        }
        return (React.createElement("div", { className: "main-page-table content-table" },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort || "name", sortName: "name", text: "providers_table.headers.provider_name", onSelect: handleSelectSort })),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "fee_range", text: "providers_table.headers.fee_range", onSelect: handleSelectSort })),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "work_experience", text: "providers_table.headers.experience", onSelect: handleSelectSort })),
                        React.createElement("th", null, t("providers_table.headers.client_age_range")),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "created_at", text: "providers_table.headers.created_at", onSelect: handleSelectSort })))),
                React.createElement("tbody", null, items.map(function (item, key) {
                    return (React.createElement("tr", { key: key, onClick: _this.handleClickRow.bind(_this, item.id), className: "content-table-row" },
                        React.createElement("td", { className: classNames({
                                "main-page-table__cell": true,
                                "main-page-table__cell_archived": item.status === "archived"
                            }) },
                            item.status === "archived" && (React.createElement("i", { className: "fas fa-user-times margin-right-10" })),
                            item.name),
                        React.createElement("td", null, item.fee_range ? item.fee_range.name : "n/a"),
                        React.createElement("td", null, item.work_experience || "n/a"),
                        React.createElement("td", null, item.age_ranges.map(function (item, key) {
                            return (React.createElement("span", { key: key },
                                key !== 0 ? "," : null,
                                " ",
                                item.name));
                        })),
                        React.createElement("td", null, item.created_at)));
                })))));
    };
    ProvidersTable = __decorate([
        react_i18next_1.translate(),
        react_router_dom_1.withRouter
    ], ProvidersTable);
    return ProvidersTable;
}(React.Component));
exports.default = ProvidersTable;
