"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_form_1 = require("redux-form");
var fields_1 = require("components/form/fields");
var CreateItemRow = /** @class */ (function (_super) {
    __extends(CreateItemRow, _super);
    function CreateItemRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CreateItemRow.prototype.render = function () {
        var _a = this.props, fields = _a.fields, i = _a.i, rowName = _a.rowName, t = _a.t, validators = _a.validators, itemsCount = _a.itemsCount, userIsTutor = _a.userIsTutor, clientSessionDuration = _a.clientSessionDuration;
        var _b = fields.get(i), _c = _b.quantity, quantity = _c === void 0 ? 0 : _c, _d = _b.rate, rate = _d === void 0 ? 0 : _d, _e = _b.session_duration, session_duration = _e === void 0 ? 0 : _e, serviceProviderFee = _b.serviceProviderFee;
        return (React.createElement("tr", { key: rowName, className: "not-selectable" },
            React.createElement("td", { className: "field-cell" },
                React.createElement(redux_form_1.Field, { component: fields_1.default, require: "true", name: rowName + ".description", validate: validators.required, type: "text", placeholder: t("invoice_edit.fields.item_description.placeholder") })),
            React.createElement("td", { className: "field-cell" },
                React.createElement(redux_form_1.Field, { component: fields_1.default, require: "true", name: rowName + ".quantity", validate: [
                        validators.required,
                        validators.minNumberMore0,
                        validators.twoSymbolsAfterComa
                    ], type: "number", placeholder: t("invoice_edit.fields.qty.placeholder"), min: "0", step: "0.01" })),
            userIsTutor && (React.createElement("td", null,
                React.createElement(redux_form_1.Field, { type: "number", component: fields_1.default, require: "true", validate: [
                        validators.required,
                        validators.minNumberMore0,
                    ], name: rowName + ".session_duration", placeholder: t("client_add_form.fields.session_duration.label") }))),
            React.createElement("td", { className: "field-cell field-with-sign dollar" },
                React.createElement(redux_form_1.Field, { component: fields_1.default, require: "true", name: rowName + ".rate", validate: [
                        validators.required,
                        validators.minNumberMore0,
                        validators.integer
                    ], type: "number", min: "0", step: "1" })),
            React.createElement("td", null,
                serviceProviderFee,
                "%"),
            React.createElement("td", null,
                "$",
                (rate * quantity * (session_duration / clientSessionDuration)).toFixed(2)),
            React.createElement("td", null, itemsCount > 1 && (React.createElement("button", { type: "button", className: "button button__transparent", onClick: function () { return fields.remove(i); } },
                React.createElement("i", { className: "fas fa-trash" }))))));
    };
    return CreateItemRow;
}(React.Component));
exports.default = CreateItemRow;
