"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var content_1 = require("./content");
var React = require("react");
var ReactDOM = require("react-dom");
var classnames_1 = require("classnames");
require("./style.less");
var Popup = /** @class */ (function (_super) {
    __extends(Popup, _super);
    function Popup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Popup.prototype.render = function () {
        var _a = this.props, openned = _a.openned, onClose = _a.onClose, extendedClass = _a.extendedClass, popupExtendedClass = _a.popupExtendedClass, blockClickOutside = _a.blockClickOutside;
        var wrapperClasses = {
            openned: openned,
            "popup-wrapper": true
        };
        if (extendedClass) {
            wrapperClasses[extendedClass] = true;
        }
        return ReactDOM.createPortal(React.createElement("div", { className: classnames_1.default(wrapperClasses) }, openned ? (React.createElement("div", { className: "popup-wrapper-inner" },
            React.createElement(content_1.default, { onClose: onClose, popupExtendedClass: popupExtendedClass || "", blockClickOutside: blockClickOutside }, this.props.children))) : null), document.body);
    };
    return Popup;
}(React.Component));
exports.default = Popup;
