"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var invoice_1 = require("../models/invoice");
var InvoiceStatusesHelper = /** @class */ (function () {
    function InvoiceStatusesHelper() {
    }
    InvoiceStatusesHelper.getOptions = function (statuses, t) {
        return (statuses || []).map(function (status, i) {
            return __assign({}, status, { label: 
                // statuses came from backend with first upper letter, Manually_paid
                status.toLowerCase() === invoice_1.INVOICE_STATUSES.MANUALLY_PAID
                    ? t("invoice.statuses.manually_paid")
                    : lodash_1.startCase(status), value: i });
        });
    };
    return InvoiceStatusesHelper;
}());
exports.default = InvoiceStatusesHelper;
