"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_toastify_1 = require("react-toastify");
require("./style.less");
var redux_form_1 = require("redux-form");
var react_router_1 = require("react-router");
var action_panel_1 = require("../action_panel");
var validators_1 = require("components/form/wrapper/validators");
var image_uploader_1 = require("components/image_uploader");
var fee_range_1 = require("helpers/fee_range");
var page_title_1 = require("components/page_title");
var form_fields_stateless_1 = require("./form_fields.stateless");
var api_adapter_1 = require("./api_adapter");
var ProviderAddEditForm = /** @class */ (function (_super) {
    __extends(ProviderAddEditForm, _super);
    function ProviderAddEditForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            image: null,
            imageRemoved: false
        };
        _this.getPageTitle = function () {
            var _a = _this.props, t = _a.t, initialValues = _a.initialValues;
            return _this.props.id
                ? initialValues.first_name + " " + initialValues.last_name + " "
                : t("page_titles.add_new_tutor");
        };
        _this.isSubmit = function () {
            var _a = _this.props, pristine = _a.pristine, submitting = _a.submitting, valid = _a.valid;
            return (!!_this.state.image && valid) || !(pristine || submitting || !valid);
        };
        _this.getTitle = function () {
            var _a = _this.props, id = _a.id, initialValues = _a.initialValues;
            return id && initialValues
                ? initialValues.first_name + " " + initialValues.last_name
                : undefined;
        };
        _this.getSubTitle = function () {
            var _a = _this.props, id = _a.id, initialValues = _a.initialValues;
            if (id &&
                initialValues &&
                initialValues.educations_attributes &&
                initialValues.educations_attributes.length > 0) {
                var educations = initialValues.educations_attributes;
                if (educations[0] && educations[0].degree && educations[0].degree.title) {
                    return educations[0].degree.title;
                }
            }
            return undefined;
        };
        _this.getSubmitText = function () {
            var t = _this.props.t;
            return _this.isOnCreateForm()
                ? t("action_panel.buttons.save")
                : t("action_panel.buttons.update");
        };
        _this.getImageSrc = function () {
            if (_this.state.imageRemoved)
                return "";
            if (_this.state.image)
                return _this.state.image.data;
            else
                return _this.props.initialValues && _this.props.initialValues.image;
        };
        _this.redirectToView = function (newId) {
            var _a = _this.props, history = _a.history, id = _a.id;
            if (newId) {
                history.push("/providers/view/" + newId);
            }
            else if (id) {
                history.push("/providers/view/" + id);
            }
            else {
                history.push("/");
            }
        };
        _this.changeImage = function (base64, fileName, removed) {
            _this.setState({
                image: { data: base64, filename: fileName },
                imageRemoved: removed
            });
        };
        _this.isOnCreateForm = function () {
            return !_this.props.id;
        };
        _this.handleSubmit = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onCreate, onUpdate, id, adapter, preparedValues, result, error_1, result, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onCreate = _a.onCreate, onUpdate = _a.onUpdate, id = _a.id;
                        adapter = new api_adapter_1.ServiceProviderApiAdapter();
                        preparedValues = __assign({}, adapter.prepareRequest(values), { image: this.state.image });
                        if (!this.isOnCreateForm()) return [3 /*break*/, 5];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, onCreate(preparedValues)];
                    case 2:
                        result = _b.sent();
                        this.submitSuccessHandling(result, "create");
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        this.submitFailHandling(error_1, "create");
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 8];
                    case 5:
                        _b.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, onUpdate(id, preparedValues)];
                    case 6:
                        result = _b.sent();
                        this.submitSuccessHandling(result, "update");
                        return [3 /*break*/, 8];
                    case 7:
                        error_2 = _b.sent();
                        this.submitFailHandling(error_2, "update");
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        _this.submitSuccessHandling = function (result, transltePathPart) {
            var t = _this.props.t;
            react_toastify_1.toast.success(t("provider_add_form." + transltePathPart + "_success"), {
                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                hideProgressBar: true
            });
            _this.redirectToView(result.service_provider.id);
        };
        _this.submitFailHandling = function (error, transltePathPart) {
            var t = _this.props.t;
            react_toastify_1.toast.error(t("provider_add_form." + transltePathPart + "_error"), {
                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                hideProgressBar: true
            });
            if (typeof error === "string") {
                throw new redux_form_1.SubmissionError({ _error: error });
            }
            throw new redux_form_1.SubmissionError(error);
        };
        return _this;
    }
    ProviderAddEditForm.prototype.render = function () {
        var _this = this;
        var _a = this.props, t = _a.t, handleSubmit = _a.handleSubmit, error = _a.error, workspace = _a.workspace, validators = _a.validators, initialValues = _a.initialValues;
        var isSubmit = this.isSubmit();
        var pageTitle = this.getPageTitle();
        return t && handleSubmit && validators && workspace ? (React.createElement(redux_form_1.Form, { className: "add-edit-form", onSubmit: handleSubmit(this.handleSubmit) },
            React.createElement(action_panel_1.default, { title: this.getTitle(), subTitle: this.getSubTitle(), onSubmit: handleSubmit(this.handleSubmit), onCancel: function () { return _this.redirectToView(); }, submitButtonText: this.getSubmitText(), isSubmit: isSubmit }),
            React.createElement("div", { className: "center-content" },
                React.createElement("div", { className: "left" },
                    React.createElement(image_uploader_1.default, { currentImg: this.getImageSrc(), imageName: initialValues.image_file_name, changeImage: this.changeImage })),
                React.createElement("div", { className: "right" },
                    React.createElement("div", { className: "right-content with-dynamic-block" },
                        React.createElement(page_title_1.default, { pageTitle: pageTitle }),
                        React.createElement(form_fields_stateless_1.EditProviderFormFields, __assign({}, this.props)),
                        error && typeof error === "string" && (React.createElement("div", { className: "form-base-error error" }, error))))),
            React.createElement(action_panel_1.default, { title: this.getTitle(), subTitle: this.getSubTitle(), onSubmit: handleSubmit(this.handleSubmit), onCancel: function () { return _this.redirectToView(); }, submitButtonText: this.getSubmitText(), isSubmit: isSubmit }))) : null;
    };
    ProviderAddEditForm = __decorate([
        validators_1.default,
        react_router_1.withRouter,
        redux_form_1.reduxForm({
            form: "provider_edit",
            enableReinitialize: true,
            keepDirtyOnReinitialize: true,
            onChange: function (values, dispatch, props) {
                if (props.pristine) {
                    return;
                }
                /**
                 * Actual redux-form typings doesn't contain previousValues param
                 */
                var previousValues = arguments[3];
                fee_range_1.default.handleFeeChanging(values, previousValues, props.workspace, dispatch, "provider_edit", true);
            }
        }),
        react_i18next_1.translate()
    ], ProviderAddEditForm);
    return ProviderAddEditForm;
}(React.Component));
exports.default = ProviderAddEditForm;
