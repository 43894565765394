"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var react_router_dom_1 = require("react-router-dom");
var auth_1 = require("../helpers/auth");
var PrivateRoute = function (_a) {
    var component = _a.component, roles = _a.roles, user = _a.user, rest = __rest(_a, ["component", "roles", "user"]);
    var renderFn = function (Component) { return function (props) {
        var redirectProps = {
            to: {
                pathname: "/login",
                state: { from: props.location }
            }
        };
        if (!Component) {
            return null;
        }
        // HANDLE ROLE
        var validRole = _.find(roles, function (role) { return role === auth_1.default.getRole() || role === user.role; });
        if (validRole) {
            return React.createElement(Component, __assign({}, props));
        }
        if (auth_1.default.isAuthorized()) {
            redirectProps.to.pathname = "/";
        }
        return React.createElement(react_router_dom_1.Redirect, __assign({}, redirectProps));
    }; };
    return React.createElement(react_router_dom_1.Route, __assign({}, rest, { render: renderFn(component) }));
};
exports.default = PrivateRoute;
