"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var redux_form_1 = require("redux-form");
var FeeRangeHelper = /** @class */ (function () {
    function FeeRangeHelper() {
    }
    FeeRangeHelper.getOptions = function (ranges, usingForFilters) {
        if (usingForFilters === void 0) { usingForFilters = false; }
        var result = (ranges || []).map(function (item) {
            return Object.assign(item, {
                label: item.name + ": $" + item.from + " - $" + item.to,
                value: item.id
            });
        });
        var unspecified = _.cloneDeep(FeeRangeHelper.unspecifiedOption);
        if (usingForFilters) {
            unspecified.disabled = false;
        }
        result.push(unspecified);
        return result;
    };
    FeeRangeHelper.handleFeeChanging = function (values, previousValues, workspace, dispatch, formName, withEmptyFee) {
        if (!workspace || !values.preference_attributes) {
            return;
        }
        var previousFeeRange = {};
        var previousFee = 0;
        var previousOnlineFee = 0;
        var currentFee = values.preference_attributes.preferred_fee;
        var currentOnlineFee = values.preference_attributes.preferred_online_fee;
        var currentFeeRange = values.preference_attributes.fee_range;
        if (previousValues.preference_attributes) {
            previousFeeRange = previousValues.preference_attributes.fee_range;
            previousFee = previousValues.preference_attributes.preferred_fee;
            previousOnlineFee = previousValues.preference_attributes.preferred_online_fee;
        }
        var onlineFee = currentOnlineFee &&
            currentOnlineFee.length > 0 &&
            _.isInteger(_.toNumber(currentOnlineFee));
        var averageFee = onlineFee ?
            (_.toNumber(currentFee) + _.toNumber(currentOnlineFee)) / 2 :
            _.toNumber(currentFee);
        if (currentFee !== previousFee || currentOnlineFee !== previousOnlineFee) {
            // FEE CHANGED
            if (currentFee === "") {
                if (withEmptyFee) {
                    dispatch(redux_form_1.change(formName, "preference_attributes.fee_range", FeeRangeHelper.unspecifiedOption));
                }
                return;
            }
            if (!_.isInteger(_.toNumber(currentFee))) {
                return;
            }
            var options = FeeRangeHelper.getOptions(workspace.fee_ranges);
            var selectedFeeRange = _.find(options, function (feeRangeConfig) {
                return feeRangeConfig.from <= averageFee && feeRangeConfig.to >= averageFee;
            });
            if (!selectedFeeRange) {
                selectedFeeRange = FeeRangeHelper.unspecifiedOption;
            }
            if (!currentFeeRange || selectedFeeRange.id !== currentFeeRange.id) {
                dispatch(redux_form_1.change(formName, "preference_attributes.fee_range", selectedFeeRange));
            }
        }
    };
    FeeRangeHelper.unspecifiedOption = {
        label: "Unspecified",
        value: 0,
        disabled: true,
        id: 0
    };
    return FeeRangeHelper;
}());
exports.default = FeeRangeHelper;
