"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var redux_form_1 = require("redux-form");
var validators_1 = require("components/form/wrapper/validators");
var lodash_1 = require("lodash");
var select_1 = require("components/form/fields/select");
require("./../../style.less");
var invoice_1 = require("constants/invoice");
var react_toastify_1 = require("react-toastify");
var overflow_loader_1 = require("components/overflow-loader");
var SelectBankAccountForm = /** @class */ (function (_super) {
    __extends(SelectBankAccountForm, _super);
    function SelectBankAccountForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.prepareValues = function (sourceValues) {
            var values = lodash_1.cloneDeep(sourceValues);
            var invoice = _this.props.invoice;
            values.uuid = invoice.uuid;
            values.bank_account_id = values.bank_account_id.id;
            values.amount = invoice.amount;
            values.updated_at = invoice.updated_at;
            return values;
        };
        _this.handleSubmit = function (sourceValues) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onFirstStep, chargeAchBankAction, onNext, t, values, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onFirstStep = _a.onFirstStep, chargeAchBankAction = _a.chargeAchBankAction, onNext = _a.onNext, t = _a.t;
                        values = this.prepareValues(sourceValues);
                        if (!(values.bank_account_id === "new")) return [3 /*break*/, 1];
                        onNext();
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, chargeAchBankAction(values)];
                    case 2:
                        result = _b.sent();
                        if (result.type !== invoice_1.INVOICE_CONST.CHARGE_ACH_SUCCESS) {
                            if (result.payload.reason === "conflict") {
                                react_toastify_1.toast.error(t("client_invoice.notifications.invoice_was_updated"), {
                                    position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                    hideProgressBar: true
                                });
                                onFirstStep();
                            }
                            else {
                                throw new redux_form_1.SubmissionError({
                                    _error: result.payload.base
                                });
                            }
                        }
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.getBankAccounts = function () {
            var items = _this.props
                .sources.filter(function (source) { return source.object == "bank_account" && source.status == "verified"; })
                .map(function (item) { return ({
                label: item.account_holder_name + " " + item.last4,
                value: item.id
            }); });
            items.push({ label: "Verify new account", value: "new" });
            return items;
        };
        return _this;
    }
    SelectBankAccountForm.prototype.render = function () {
        var _a = this.props, t = _a.t, handleSubmit = _a.handleSubmit, error = _a.error, validators = _a.validators, invoice = _a.invoice, onBack = _a.onBack, submitting = _a.submitting;
        var amount = parseFloat(invoice.amount).toFixed(2);
        return t && handleSubmit && validators ? (React.createElement(React.Fragment, null,
            React.createElement(overflow_loader_1.default, { isLoaderVisible: submitting }),
            React.createElement(redux_form_1.Form, { onSubmit: handleSubmit(this.handleSubmit) },
                React.createElement("div", null,
                    React.createElement("div", { className: "add-edit-form client-invoice-form info-block invoice-wizard-step" },
                        React.createElement("h3", null, t("client_invoice.step_3_bank.title_3")),
                        React.createElement(redux_form_1.Field, { label: t("bank_payment_form.fields.select_bank_account.label"), component: select_1.default, options: this.getBankAccounts(), name: "bank_account_id", validate: validators.required, type: "text", require: "true", disabled: submitting, placeholder: t("bank_payment_form.fields.select_bank_account.placeholder") }),
                        error && typeof error === "string" && (React.createElement("div", { className: "error base" }, error))),
                    React.createElement("div", { className: "wizard-buttons-panel" },
                        React.createElement("div", { className: "buttons-container" },
                            React.createElement("button", { onClick: onBack, className: "button button__back", disabled: submitting },
                                React.createElement("i", { className: "fas fa-arrow-left margin-right-10" }),
                                t("wizard_default.buttons.back")),
                            React.createElement("button", { type: "submit", className: "button button__red button__next", disabled: submitting }, t("client_invoice.next_button.pay_amount", { amount: amount })))))))) : null;
    };
    SelectBankAccountForm = __decorate([
        validators_1.default,
        redux_form_1.reduxForm({
            form: "ach_select_bank_account_form",
            enableReinitialize: true,
            keepDirtyOnReinitialize: false
        }),
        react_i18next_1.translate()
    ], SelectBankAccountForm);
    return SelectBankAccountForm;
}(React.Component));
exports.default = SelectBankAccountForm;
