"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reduxMasks = require("redux-form-input-masks");
exports.phoneMask = reduxMasks.createTextMask({
    pattern: "+1 (999) 999 9999"
});
exports.percentMask = reduxMasks.createTextMask({
    pattern: "99",
    placeholder: " ",
    allowEmpty: true
});
