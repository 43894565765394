"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
require("./style.less");
var sort_title_1 = require("components/sort_title");
var ClientsTable = /** @class */ (function (_super) {
    __extends(ClientsTable, _super);
    function ClientsTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getServiceProviderFullName = function (client) {
            return client.service_provider
                ? client.service_provider.first_name + "\n          " + client.service_provider.last_name
                : "n/a";
        };
        _this.handleClickRow = function (id) {
            var history = _this.props.history;
            if (history) {
                history.push("/clients/view/" + id);
            }
        };
        return _this;
    }
    ClientsTable.prototype.render = function () {
        var _this = this;
        var _a = this.props, t = _a.t, items = _a.items, onReset = _a.onReset, currentSort = _a.currentSort, currentUserRole = _a.currentUserRole, handleSelectSort = _a.handleSelectSort, showProvider = _a.showProvider, showManager = _a.showManager, isFilterEmpty = _a.isFilterEmpty;
        var children = this.props.children;
        if (items && !items.length && isFilterEmpty) {
            return (React.createElement("div", { className: "empty" },
                React.createElement("h2", null, t("client_table.empty.no_clients"))));
        }
        if (items && !items.length) {
            return (React.createElement("div", { className: "empty" },
                React.createElement("h2", null, t("client_table.empty.title")),
                !this.props.children && (React.createElement(React.Fragment, null,
                    React.createElement("p", null, t("client_table.empty.text")),
                    React.createElement("a", { onClick: onReset, className: "button button__white" }, t("client_table.empty.clear"))))));
        }
        return (React.createElement("div", { className: "main-page-table content-table" },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort || "name", sortName: "name", text: "client_table.headers.client_name", onSelect: handleSelectSort })),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "age", text: "client_table.headers.age", onSelect: handleSelectSort })),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "school", text: "client_table.headers.school", onSelect: handleSelectSort })),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "fee_range", text: "client_table.headers.fee", onSelect: handleSelectSort })),
                        showProvider && (React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "service_provider", text: "client_table.headers.sp", onSelect: handleSelectSort }))),
                        currentUserRole === "admin" && showManager && (React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "manager", text: "client_table.headers.manager", onSelect: handleSelectSort }))),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "created_at", text: "client_table.headers.created_at", onSelect: handleSelectSort })))),
                React.createElement("tbody", null, items &&
                    items.map(function (item, key) {
                        var childrenWithProps = React.Children.map(children, function (child) {
                            if (React.isValidElement(child)) {
                                return React.cloneElement(child, {
                                    rowData: item
                                });
                            }
                        });
                        return (React.createElement("tr", { key: key, onClick: _this.handleClickRow.bind(_this, item.id), className: "content-table-row" },
                            React.createElement("td", { className: classNames({
                                    "main-page-table__cell": true,
                                    "main-page-table__cell_archived": item.status === "archived"
                                }) },
                                item.status === "archived" && (React.createElement("i", { className: "fas fa-user-times margin-right-10" })),
                                item.name),
                            React.createElement("td", null, item.age ? item.age : "n/a"),
                            React.createElement("td", null, item.school),
                            _this.props.children && (React.createElement("td", null, _this.getClientFeeRange(item))),
                            !_this.props.children && (React.createElement("td", null, _this.getClientFeeRange(item))),
                            showProvider && (React.createElement("td", null, _this.getServiceProviderFullName(item))),
                            currentUserRole === "admin" && showManager && (React.createElement("td", null, item.manager)),
                            _this.props.children ? (React.createElement("td", { className: "with-child-component" },
                                React.createElement("div", { style: { display: "inline-block" } }, item.created_at),
                                React.createElement("div", { style: { display: "inline-block" } }, childrenWithProps))) : (React.createElement("td", null, item.created_at))));
                    })))));
    };
    ClientsTable.prototype.getClientFeeRange = function (client) {
        return client.fee_range ? client.fee_range.name : "n/a";
    };
    ClientsTable = __decorate([
        react_i18next_1.translate(),
        react_router_dom_1.withRouter
    ], ClientsTable);
    return ClientsTable;
}(React.Component));
exports.default = ClientsTable;
