"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var moment = require("moment");
var react_datepicker_1 = require("react-datepicker");
require("./style.less");
var lists_1 = require("constants/lists");
var Calendar = /** @class */ (function (_super) {
    __extends(Calendar, _super);
    function Calendar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            fetchError: false
        };
        _this.onChangeCalendarDate = function (date) {
            var updateSelectedDateAction = _this.props.updateSelectedDateAction;
            updateSelectedDateAction(date);
        };
        _this.getSessions = function (month) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, getSessionsPerDateAction, providerData, clientData, monthToString, id, values, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.setState({ fetchError: false });
                        _b = this.props, getSessionsPerDateAction = _b.getSessionsPerDateAction, providerData = _b.providerData, clientData = _b.clientData;
                        monthToString = month.format();
                        id = clientData ? "client" : "service_provider";
                        values = (_a = {
                                month: monthToString
                            },
                            _a[id] = clientData ? clientData.id : providerData.id,
                            _a);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getSessionsPerDateAction(values, lists_1.LIST_ENTITIES.MOUNTH_SESSIONS)];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _c.sent();
                        this.setState({ fetchError: true });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.getDayClassName = function (day) {
            var sessionsArray = _this.props.sessionsArray;
            if (!sessionsArray) {
                return "";
            }
            var dayWithSessions = sessionsArray.find(function (session) {
                return day.isSame(session.date, "day");
            });
            return dayWithSessions
                ? "calendar__day_with-sessions calendar__day_sessions-amount-" + dayWithSessions.amount
                : null;
        };
        _this.handleMonthChange = function (newMonth) {
            _this.getSessions(newMonth);
        };
        return _this;
    }
    Calendar.prototype.componentDidMount = function () {
        this.props.updateSelectedDateAction(moment());
        this.update();
    };
    Calendar.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.lastUpdateTime !== nextProps.lastUpdateTime) {
            this.update();
        }
    };
    Calendar.prototype.render = function () {
        var _a = this.props, t = _a.t, selectedDate = _a.selectedDate;
        var fetchError = this.state.fetchError;
        return (React.createElement("div", { className: "calendar-wrapper" },
            React.createElement(react_datepicker_1.default, { inline: true, selected: selectedDate, dayClassName: this.getDayClassName, onChange: this.onChangeCalendarDate, onMonthChange: this.handleMonthChange }, fetchError && (React.createElement("p", { className: "calendar_errors" }, t("calendar.error"))))));
    };
    /**
     * @method update
     */
    Calendar.prototype.update = function () {
        if (this.props.selectedDate) {
            this.getSessions(this.props.selectedDate);
        }
    };
    return Calendar;
}(React.Component));
exports.default = Calendar;
