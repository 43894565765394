"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var _ = require("lodash");
exports.daySessionsConverter = function (state, items, query) {
    var begin, end, daysDiff;
    _.each(items, function (session) {
        begin = moment(session.begin_at);
        end = moment(session.end_at);
        session.time = begin.format("hh:mm") + " - " + end.format("hh:mm A");
        daysDiff = end.diff(begin, "day");
        if (daysDiff > 0) {
            session.day = "";
            if (moment(query.date).diff(begin, "day") !== 0) {
                session.day += begin.format("MMMM DD") + ", ";
            }
            // +1 becuase daysDiff contain only duration days
            session.day += daysDiff + 1 + " " + (daysDiff === 1 ? "day" : "days");
        }
    });
    return items;
};
