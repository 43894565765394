"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var InvoiceActionPanel = /** @class */ (function (_super) {
    __extends(InvoiceActionPanel, _super);
    function InvoiceActionPanel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InvoiceActionPanel.prototype.render = function () {
        var _a = this.props, isSubmit = _a.isSubmit, onSubmit = _a.onSubmit, onCancel = _a.onCancel, title = _a.title, subTitle = _a.subTitle, t = _a.t;
        return (React.createElement("div", { className: "action-panel action-panel__invoice" },
            React.createElement("div", { className: "center-content" },
                React.createElement("div", { className: "description" },
                    React.createElement("h2", { className: "action-panel-title" }, title),
                    React.createElement("p", { className: "action-panel-subtitle" }, subTitle)),
                React.createElement("div", { className: "actions" },
                    React.createElement("button", { className: classNames({
                            "button button__red": true,
                            disabled: !isSubmit
                        }), onClick: onSubmit }, t("action_panel.buttons.update")),
                    React.createElement("button", { className: "button button__white", onClick: onCancel }, t("action_panel.buttons.cancel"))))));
    };
    InvoiceActionPanel = __decorate([
        react_i18next_1.translate()
    ], InvoiceActionPanel);
    return InvoiceActionPanel;
}(React.Component));
exports.default = InvoiceActionPanel;
