"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
require("moment-duration-format");
var HumanizeDurationHelper = /** @class */ (function () {
    function HumanizeDurationHelper() {
    }
    HumanizeDurationHelper.convert = function (firstDate, secondDate) {
        if (!moment.isMoment(firstDate) || !moment.isMoment(secondDate)) {
            return "";
        }
        var diff = secondDate.diff(firstDate);
        diff = diff < 0 ? 0 : diff;
        return moment
            .duration(diff, "milliseconds")
            .format("d [days] h [hours] m [min]");
    };
    return HumanizeDurationHelper;
}());
exports.default = HumanizeDurationHelper;
