"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var redux_form_1 = require("redux-form");
var lodash_1 = require("lodash");
var classNames = require("classnames");
require("./style.less");
var fields_1 = require("components/form/fields");
var create_item_1 = require("./create_item");
var table_footer_1 = require("./create_item/table_footer");
var invoice_1 = require("../../../../constants/invoice");
var InvoiceDescriptionTable = /** @class */ (function (_super) {
    __extends(InvoiceDescriptionTable, _super);
    function InvoiceDescriptionTable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InvoiceDescriptionTable.prototype.render = function () {
        var _a = this.props, itemsCurrentValues = _a.itemsCurrentValues, removeItem = _a.removeItem, t = _a.t, clientSessionDuration = _a.clientSessionDuration, validators = _a.validators, summ = _a.summ, itemsCount = _a.itemsCount, _b = _a.isEditable, isEditable = _b === void 0 ? true : _b, isAdminOrManager = _a.isAdminOrManager, userIsTutor = _a.userIsTutor, _c = _a.serviceProviderFee, serviceProviderFee = _c === void 0 ? 0 : _c;
        return (React.createElement("div", { className: "main-page-table content-table" },
            React.createElement("table", { className: "invoice-table" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, t("invoice_edit.table.description")),
                        React.createElement("th", null, t("invoice_edit.table.qty")),
                        userIsTutor && React.createElement("th", null, t("invoice_edit.table.duration")),
                        React.createElement("th", null, t("invoice_edit.table.rate")),
                        (isAdminOrManager || userIsTutor) && (React.createElement("th", null, t("invoice_edit.table.tutors_fee"))),
                        isAdminOrManager && (React.createElement("th", null, t("invoice_edit.table.supervisors_fee"))),
                        React.createElement("th", null, t("invoice_edit.table.amount")),
                        isEditable && React.createElement("th", null))),
                React.createElement("tbody", { className: "small-table" }, itemsCurrentValues.map(function (item, i) { return (React.createElement("tr", { key: item.id, className: "not-selectable" },
                    React.createElement("td", { className: "invoice-table_description" },
                        React.createElement("div", { className: classNames({
                                "field field-with-sign margin-left dollar": isAdminOrManager && item.supervisor_related
                            }) }, item.description)),
                    React.createElement("td", { className: "field-cell" }, isEditable ? (React.createElement(redux_form_1.Field, { component: fields_1.default, require: "true", validate: [
                            validators.required,
                            validators.minNumberMore0,
                            validators.twoSymbolsAfterComa,
                        ], name: "items[" + i + "].quantity", type: "number", min: 0, step: "0.01" })) : item.is_fee_item ? ("—") : ("" + item.quantity)),
                    userIsTutor && (React.createElement("td", null, isEditable ? (React.createElement(redux_form_1.Field, { type: "number", component: fields_1.default, require: "true", validate: [
                            validators.required,
                            validators.minNumberMore0,
                        ], name: "items[" + i + "].session_duration", placeholder: t("client_add_form.fields.session_duration.label") })) : (item.is_fee_item
                        ? "—"
                        : itemsCurrentValues[i].quantity *
                            (item.session_duration || invoice_1.STANDARD_SESSION_DURATION) + " " + t("invoice_edit.table.min")))),
                    React.createElement("td", null, item.is_fee_item ? "—" : "$" + lodash_1.round(item.rate)),
                    (isAdminOrManager || userIsTutor) && (React.createElement("td", null, item.is_fee_item ? "—" : item.service_provider_fee + "%")),
                    isAdminOrManager && (React.createElement("td", null, item.is_fee_item ? "—" : item.manager_fee + "%")),
                    React.createElement("td", null,
                        "$",
                        isEditable ? (itemsCurrentValues[i].rate *
                            itemsCurrentValues[i].quantity *
                            (item.session_duration /
                                clientSessionDuration)).toFixed(2) : itemsCurrentValues[i].amount),
                    isEditable && (React.createElement("td", null, itemsCount > 1 && (React.createElement("button", { type: "button", className: "button button__transparent", onClick: function () { return removeItem(i); } },
                        React.createElement("i", { className: "fas fa-trash" }))))))); })),
                isEditable ? (React.createElement(redux_form_1.FieldArray, { name: "addedItems", component: create_item_1.default, summ: summ, itemsCount: itemsCount, validators: validators, userIsTutor: userIsTutor, clientSessionDuration: clientSessionDuration, serviceProviderFee: serviceProviderFee })) : (React.createElement(table_footer_1.default, { isAdminOrManager: isAdminOrManager, userIsTutor: userIsTutor, summ: summ })))));
    };
    InvoiceDescriptionTable = __decorate([
        react_i18next_1.translate()
    ], InvoiceDescriptionTable);
    return InvoiceDescriptionTable;
}(React.PureComponent));
exports.default = InvoiceDescriptionTable;
