"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var lists_1 = require("actions/lists");
function withReloadList(entities) {
    return function (WrappedComponent) {
        var WithReloadList = /** @class */ (function (_super) {
            __extends(WithReloadList, _super);
            function WithReloadList() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.reloadLists = function () {
                    var _a = _this.props, lists = _a.lists, getListAction = _a.getListAction;
                    if (entities) {
                        _.each(entities, function (entity) {
                            return getListAction(lists[entity].query, entity);
                        });
                    }
                    else {
                        throw new Error("entities are required for withReloadList decorator");
                    }
                };
                return _this;
            }
            WithReloadList.prototype.render = function () {
                return (React.createElement(WrappedComponent, __assign({}, this.props, { reloadLists: this.reloadLists })));
            };
            WithReloadList = __decorate([
                react_redux_1.connect(function (state) {
                    return {
                        lists: state.lists
                    };
                }, function (dispatch) {
                    return {
                        getListAction: redux_1.bindActionCreators(lists_1.getList, dispatch)
                    };
                })
            ], WithReloadList);
            return WithReloadList;
        }(React.Component));
        return WithReloadList;
    };
}
exports.default = withReloadList;
