"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LIST_ENTITIES;
(function (LIST_ENTITIES) {
    // root
    LIST_ENTITIES["ALL_CLIENTS_PAGINATED_LIST"] = "ALL_CLIENTS_PAGINATED_LIST";
    LIST_ENTITIES["ALL_PROVIDERS_PAGINATED_LIST"] = "ALL_PROVIDERS_PAGINATED_LIST";
    LIST_ENTITIES["ALL_INVOICES_CLIENTS_PAGINATED_LIST"] = "ALL_INVOICES_CLIENTS_PAGINATED_LIST";
    LIST_ENTITIES["ALL_INVOICES_PROVIDERS_PAGINATED_LIST"] = "ALL_INVOICES_PROVIDERS_PAGINATED_LIST";
    // for new session modal
    LIST_ENTITIES["ALL_PROVIDER_CLIENTS_LIST"] = "ALL_PROVIDER_CLIENTS";
    // for clients table on provider page with active 'my client'
    LIST_ENTITIES["MANAGER_PROVIDER_CLIENTS_PAGINATED_LIST"] = "MANAGER_PROVIDER_CLIENTS_PAGINATED_LIST";
    // for clients table on provider page with active 'all clients'
    LIST_ENTITIES["ALL_PROVIDER_CLIENTS_PAGINATED_LIST"] = "ALL_PROVIDER_CLIENTS_PAGINATED_LIST";
    // for assign client modal
    LIST_ENTITIES["ALL_UNASSIGNED_CLIENTS_LIST"] = "ALL_UNASSIGNED_CLIENTS_LIST";
    // for reassign provider modal
    LIST_ENTITIES["ALL_PROVIDERS_LIST"] = "ALL_PROVIDERS_LIST";
    //list of preinvoices
    LIST_ENTITIES["ALL_INVOICES_LIST"] = "ALL_INVOICES_LIST";
    //list of preinvoice statuses
    LIST_ENTITIES["ALL_INVOICES_STATUSES_LIST"] = "ALL_INVOICES_STATUSES_LIST";
    // sessions amount in calendar
    LIST_ENTITIES["MOUNTH_SESSIONS"] = "MOUNTH_SESSIONS";
    // sessions list in schedule
    LIST_ENTITIES["DAY_SESSIONS"] = "DAY_SESSIONS";
    // all managers list for intake manager
    LIST_ENTITIES["ALL_MANAGERS"] = "ALL_MANAGERS";
    LIST_ENTITIES["DEFAULT"] = "DEFAULT";
})(LIST_ENTITIES = exports.LIST_ENTITIES || (exports.LIST_ENTITIES = {}));
var LIST_ACTIONS;
(function (LIST_ACTIONS) {
    LIST_ACTIONS["GET_LIST"] = "GET_LIST";
    LIST_ACTIONS["GET_LIST_SUCCESS"] = "GET_LIST_SUCCESS";
    LIST_ACTIONS["GET_LIST_FAIL"] = "GET_LIST_FAIL";
    LIST_ACTIONS["CLEAN_FILTERS"] = "CLEAN_FILTERS";
    LIST_ACTIONS["ENABLE_PREVIOUS_FILTERS"] = "ENABLE_PREVIOUS_FILTERS";
    LIST_ACTIONS["DISABLE_PREVIOUS_FILTERS"] = "DISABLE_PREVIOUS_FILTERS";
    LIST_ACTIONS["SAVE_FILTERS"] = "SAVE_FILTERS";
})(LIST_ACTIONS = exports.LIST_ACTIONS || (exports.LIST_ACTIONS = {}));
