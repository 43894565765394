"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var USER_CONST;
(function (USER_CONST) {
    USER_CONST["LOGIN_SUCCESS"] = "LOGIN_SUCCESS";
    USER_CONST["LOGIN_FAIL"] = "LOGIN_FAIL";
    USER_CONST["GET_MY_USER_SUCCESS"] = "GET_MY_USER_SUCCESS";
    USER_CONST["GET_MY_USER_FAIL"] = "GET_MY_USER_FAIL";
    USER_CONST["LOGOUT_SUCCESS"] = "LOGOUT_SUCCESS";
    USER_CONST["LOGOUT_FAIL"] = "LOGOUT_FAIL";
    USER_CONST["FORCE_LOGOUT"] = "FORCE_LOGOUT";
    USER_CONST["GET_MANAGERS_SUCCESS"] = "GET_MANAGERS_SUCCESS";
    USER_CONST["GET_MANAGERS_FAIL"] = "GET_MANAGERS_FAIL";
    USER_CONST["UPDATE_SUCCESS"] = "UPDATE_SUCCESS";
    USER_CONST["UPDATE_FAIL"] = "UPDATE_FAIL";
    USER_CONST["RESET_PASSWORD_SUCCESS"] = "RESET_PASSWORD_SUCCESS";
    USER_CONST["RESET_PASSWORD_FAIL"] = "RESET_PASSWORD_FAIL";
})(USER_CONST = exports.USER_CONST || (exports.USER_CONST = {}));
var Role;
(function (Role) {
    Role["admin"] = "admin";
    Role["manager"] = "manager";
    Role["serviceProvider"] = "service_provider";
})(Role = exports.Role || (exports.Role = {}));
