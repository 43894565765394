"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var redux_form_1 = require("redux-form");
var react_router_dom_1 = require("react-router-dom");
var fields_1 = require("components/form/fields");
var select_1 = require("components/form/fields/select");
var popup_1 = require("components/popup");
var detail_1 = require("./detail");
var fee_range_1 = require("helpers/fee_range");
var age_range_1 = require("helpers/age_range");
var experience_1 = require("helpers/experience");
var advanced_search_button_1 = require("components/helpers/advanced_search_button");
var component_1 = require("components/schedule_preferences/component");
var prefered_time_1 = require("helpers/prefered_time");
var updateFilterTimer = null;
var ProvidersFilter = /** @class */ (function (_super) {
    __extends(ProvidersFilter, _super);
    function ProvidersFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showDetail: false
        };
        _this.handleOpenDetail = function () {
            _this.setState({
                showDetail: true
            });
        };
        _this.handleCloseDetail = function () {
            _this.setState({
                showDetail: false
            });
        };
        return _this;
    }
    ProvidersFilter.prototype.render = function () {
        var _a = this.props, t = _a.t, workspace = _a.workspace, searchValues = _a.searchValues, changeDayValue = _a.changeDayValue, dayPrefix = _a.dayPrefix, daysSelected = _a.daysSelected;
        var showDetail = this.state.showDetail;
        return t && workspace ? (React.createElement("div", { className: "main-page-filter" },
            React.createElement("form", null,
                React.createElement("div", { className: "search" },
                    React.createElement(redux_form_1.Field, { component: fields_1.default, name: "search", type: "text", id: "search", placeholder: t("providers_filter.search.placeholder") }),
                    React.createElement("label", { htmlFor: "search" },
                        React.createElement("i", { className: "icon search" })),
                    React.createElement(advanced_search_button_1.default, { handleOpenDetail: this.handleOpenDetail, searchValues: searchValues })),
                React.createElement(redux_form_1.Field, { clearable: true, backspaceRemoves: true, component: select_1.default, name: "fee_range", options: fee_range_1.default.getOptions(workspace.fee_ranges, true), placeholder: t("providers_filter.fee_range.placeholder") }),
                React.createElement(redux_form_1.Field, { clearable: true, backspaceRemoves: true, component: select_1.default, name: "age_range", options: age_range_1.default.getOptions(workspace.age_ranges), placeholder: t("providers_filter.education.placeholder") }),
                React.createElement(popup_1.default, { openned: showDetail, popupExtendedClass: "always-on-top", onClose: this.handleCloseDetail },
                    React.createElement(detail_1.default, { workspace: workspace, onClose: this.handleCloseDetail })),
                React.createElement(react_router_dom_1.Link, { className: "button__red button", to: "/providers/add" }, t("providers_filter.add_new"))),
            React.createElement("div", { className: "form schedule-container" },
                React.createElement("p", { className: "schedule-container_lable" }, t("providers_filter.available_on")),
                React.createElement(component_1.default, { changeDayValue: changeDayValue, dayPrefix: dayPrefix, editable: true, daysSelected: daysSelected })))) : null;
    };
    ProvidersFilter = __decorate([
        react_i18next_1.translate(),
        redux_form_1.reduxForm({
            form: "providerFilter",
            enableReinitialize: true,
            keepDirtyOnReinitialize: true,
            onChange: function (values, dispatch, props) {
                var fetchProviders = props.fetchProviders;
                clearTimeout(updateFilterTimer);
                if (fetchProviders) {
                    updateFilterTimer = setTimeout(function () {
                        var params = {
                            q: values.search,
                            page: 1
                        };
                        if (values.age_range) {
                            params.age_range = values.age_range.id;
                        }
                        if (values.fee_range) {
                            params.fee_range = values.fee_range.id;
                        }
                        if (values.expertise) {
                            params.age_range = values.expertise.id;
                        }
                        var characteristics = [];
                        if (values.personalities) {
                            characteristics = characteristics.concat(values.personalities.map(function (item) {
                                return item.id;
                            }));
                        }
                        if (values.expertises) {
                            characteristics = characteristics.concat(values.expertises.map(function (item) {
                                return item.id;
                            }));
                        }
                        if (values.techniques) {
                            characteristics = characteristics.concat(values.techniques.map(function (item) {
                                return item.id;
                            }));
                        }
                        if (characteristics.length) {
                            params.characteristics = characteristics;
                        }
                        if (values.gender) {
                            params.gender = values.gender.id;
                        }
                        if (values.experience_range) {
                            if (values.experience_range.id !== 0) {
                                params.experience_from = experience_1.default.getFromById(values.experience_range.id);
                                params.experience_to = experience_1.default.getToById(values.experience_range.id);
                            }
                            else {
                                params.experience_from = undefined;
                                params.experience_to = undefined;
                            }
                        }
                        if (values.prefered_location) {
                            params.preferred_location = values.prefered_location;
                        }
                        if (values.available_periods) {
                            params.available_periods = prefered_time_1.prepareFilterValuesToPut(values.available_periods.days);
                        }
                        fetchProviders(params);
                    }, 500);
                }
            }
        })
    ], ProvidersFilter);
    return ProvidersFilter;
}(React.Component));
exports.default = ProvidersFilter;
