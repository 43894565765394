"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var invoice_calculation_1 = require("helpers/invoice_calculation");
var ChoosePaymentMethod = /** @class */ (function (_super) {
    __extends(ChoosePaymentMethod, _super);
    function ChoosePaymentMethod() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { type: "card" };
        _this.onNext = function (type) {
            _this.props.onNext(type);
        };
        _this.onBack = function () {
            _this.props.onBack();
        };
        _this.getInvoiceTotal = function () {
            var invoice = _this.props.invoice;
            switch (_this.state.type) {
                case "card": return { amount: invoice_calculation_1.default.countTotalAmountForCard(invoice, false) };
                case "international_card": return { amount: invoice_calculation_1.default.countTotalAmountForCard(invoice, true) };
                default: return { amount: invoice_calculation_1.default.amountToFixed(invoice.amount) };
            }
        };
        _this.selectBankType = function () {
            _this.setState({ type: "bank" });
        };
        _this.selectCardType = function () {
            _this.setState({ type: "card" });
        };
        _this.selectInternationalCardType = function () {
            _this.setState({ type: "international_card" });
        };
        _this.handleSubmit = function (e) {
            e.preventDefault();
            _this.onNext(_this.state.type);
        };
        return _this;
    }
    ChoosePaymentMethod.prototype.render = function () {
        var t = this.props.t;
        return (React.createElement("div", { className: "invoice_details" },
            React.createElement("form", { id: "choose-method-form", onSubmit: this.handleSubmit },
                React.createElement("div", { className: "info-block invoice-wizard-step choose-method" },
                    React.createElement("h3", null,
                        " ",
                        t("client_invoice.step_2.title"),
                        " "),
                    React.createElement("input", { id: "pay-with-card", type: "radio", name: "pay-type", value: "pay-with-card", defaultChecked: true }),
                    React.createElement("label", { htmlFor: "pay-with-card", className: "label-for-radio with-hint", onClick: this.selectCardType }, t("client_invoice.step_2.pay_with_card")),
                    React.createElement("span", { className: "label-hint" }, t("client_invoice.step_2.card_hint")),
                    React.createElement("input", { id: "pay-with-international-card", type: "radio", name: "pay-type", value: "pay-with-international-card" }),
                    React.createElement("label", { htmlFor: "pay-with-international-card", className: "label-for-radio with-hint", onClick: this.selectInternationalCardType }, t("client_invoice.step_2.pay_with_international_card")),
                    React.createElement("span", { className: "label-hint" }, t("client_invoice.step_2.international_card_hint")),
                    React.createElement("span", { className: "label-hint" }, t("client_invoice.step_2.card_we_can_accept")),
                    React.createElement("input", { id: "pay-with-bank", type: "radio", name: "pay-type", value: "pay-with-bank" }),
                    React.createElement("label", { htmlFor: "pay-with-bank", onClick: this.selectBankType, className: "label-for-radio with-hint" }, t("client_invoice.step_2.pay_with_bank")),
                    React.createElement("span", { className: "label-hint" }, t("client_invoice.step_2.bank_transfer_hint"))),
                React.createElement("div", { className: "wizard-buttons-panel" },
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement("button", { onClick: this.onBack, className: "button button__back" },
                            React.createElement("i", { className: "fas fa-arrow-left margin-right-10" }),
                            t("wizard_default.buttons.back")),
                        React.createElement("button", { type: "submit", className: "button button__red button__next" }, t("client_invoice.next_button.pay_amount", this.getInvoiceTotal())))))));
    };
    ChoosePaymentMethod = __decorate([
        react_i18next_1.translate()
    ], ChoosePaymentMethod);
    return ChoosePaymentMethod;
}(React.Component));
exports.default = ChoosePaymentMethod;
