"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var redux_form_1 = require("redux-form");
var React = require("react");
var react_i18next_1 = require("react-i18next");
var fields_1 = require("components/form/fields");
var classnames = require("classnames");
var RenderEmail = /** @class */ (function (_super) {
    __extends(RenderEmail, _super);
    function RenderEmail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleAdd = function () {
            var fields = _this.props.fields;
            if (fields) {
                fields.push("");
            }
        };
        _this.handleRemove = function (index) {
            var fields = _this.props.fields;
            if (fields) {
                fields.remove(index);
            }
        };
        return _this;
    }
    RenderEmail.prototype.render = function () {
        var _this = this;
        var _a = this.props, fields = _a.fields, t = _a.t, validators = _a.validators, customError = _a.customError;
        return t ? (React.createElement("div", { className: classnames({
                emails: true,
                "error-block-wrapper": customError && customError["contact.emails"]
            }) },
            fields.map(function (member, index) { return (React.createElement("div", { className: "phone-item", key: index },
                React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.email.label"), component: fields_1.default, require: "true", name: member, validate: [validators.required, validators.email], type: "text", placeholder: t("provider_add_form.fields.email.placeholder") }),
                React.createElement("button", { type: "button", className: classnames({
                        "button button__transparent": true,
                        "hidden-visible": index === 0
                    }), onClick: _this.handleRemove.bind(_this, index) },
                    React.createElement("i", { className: "fas fa-trash" })))); }),
            React.createElement("button", { className: "button button__transparent", type: "button", onClick: this.handleAdd }, t("provider_add_form.add_more")),
            customError && customError["contact.emails"] ? (React.createElement("div", { className: "error error-block" }, customError["contact.emails"])) : null)) : null;
    };
    RenderEmail.prototype.componentDidMount = function () {
        var fields = this.props.fields;
        if (fields && fields.length === 0) {
            fields.push("");
        }
    };
    RenderEmail = __decorate([
        react_i18next_1.translate()
    ], RenderEmail);
    return RenderEmail;
}(React.Component));
exports.default = RenderEmail;
