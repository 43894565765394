"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var _a;
var lists_1 = require("constants/lists");
var session_1 = require("api/session");
var user_1 = require("api/user");
var errors_herlper_actions_1 = require("./errors_herlper_actions");
var entityActionsMap = (_a = {},
    _a[lists_1.LIST_ENTITIES.MOUNTH_SESSIONS] = {
        apiCall: session_1.SessionApi.getSessions,
        resultAdapter: function (resultData) { return ({ data: resultData.sessions_amount }); }
    },
    _a[lists_1.LIST_ENTITIES.DAY_SESSIONS] = {
        apiCall: session_1.SessionApi.getSessionsByDate,
        resultAdapter: function (resultData) { return ({ data: resultData.sessions }); }
    },
    _a[lists_1.LIST_ENTITIES.ALL_MANAGERS] = {
        apiCall: user_1.UserApi.getManagersList,
        resultAdapter: function (resultData) { return ({ data: resultData.managers }); }
    },
    _a);
// LISTS
function getList(query, entity) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var config, result, resultBody;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    config = entityActionsMap[entity];
                    return [4 /*yield*/, config.apiCall(query)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultBody = _a.sent();
                    if (config.resultAdapter) {
                        resultBody = config.resultAdapter(resultBody);
                    }
                    errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                    return [2 /*return*/, dispatch({
                            type: lists_1.LIST_ACTIONS.GET_LIST_SUCCESS,
                            payload: {
                                result: resultBody,
                                query: query,
                                entity: entity
                            }
                        })];
            }
        });
    }); };
}
exports.getList = getList;
// FILTERS
function saveFilters(filters, entity) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, dispatch({
                    type: lists_1.LIST_ACTIONS.SAVE_FILTERS,
                    payload: {
                        filters: filters,
                        entity: entity
                    }
                })];
        });
    }); };
}
exports.saveFilters = saveFilters;
function enablePreviousFilters(entity) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, dispatch({
                    type: lists_1.LIST_ACTIONS.ENABLE_PREVIOUS_FILTERS,
                    payload: {
                        entity: entity
                    }
                })];
        });
    }); };
}
exports.enablePreviousFilters = enablePreviousFilters;
function disablePreviousFilters(entity) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, dispatch({
                    type: lists_1.LIST_ACTIONS.DISABLE_PREVIOUS_FILTERS,
                    payload: {
                        entity: entity
                    }
                })];
        });
    }); };
}
exports.disablePreviousFilters = disablePreviousFilters;
function cleanPreviousFilters(entity) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, dispatch({
                    type: lists_1.LIST_ACTIONS.CLEAN_FILTERS,
                    payload: {
                        entity: entity
                    }
                })];
        });
    }); };
}
exports.cleanPreviousFilters = cleanPreviousFilters;
