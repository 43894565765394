"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var main_1 = require("layouts/main");
var private_1 = require("components/helpers/user/private");
var container_1 = require("./table/container");
var container_2 = require("./filter/container");
var container_3 = require("./warning/container");
var InvoicesPage = /** @class */ (function (_super) {
    __extends(InvoicesPage, _super);
    function InvoicesPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InvoicesPage.prototype.render = function () {
        return (React.createElement(main_1.default, null,
            React.createElement("div", { className: "main-page" },
                React.createElement(container_3.default, null),
                React.createElement("div", { className: "main-page-header" },
                    React.createElement("div", { className: "center-content" },
                        React.createElement(container_2.default, null))),
                React.createElement("div", { className: "main-page-data" },
                    React.createElement(container_1.default, null)))));
    };
    InvoicesPage = __decorate([
        private_1.default
    ], InvoicesPage);
    return InvoicesPage;
}(React.Component));
exports.default = InvoicesPage;
