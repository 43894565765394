"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_form_1 = require("redux-form");
var react_i18next_1 = require("react-i18next");
var react_toastify_1 = require("react-toastify");
var react_router_1 = require("react-router");
var moment = require("moment");
var lodash_1 = require("lodash");
var action_panel_1 = require("./action_panel");
var textarea_1 = require("components/form/fields/textarea");
var datepicker_1 = require("components/form/fields/datepicker");
var validators_1 = require("components/form/wrapper/validators");
var description_table_1 = require("./description_table");
require("./style.less");
var invoice_1 = require("constants/invoice");
var InvoiceEdit = /** @class */ (function (_super) {
    __extends(InvoiceEdit, _super);
    function InvoiceEdit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.calculateSumm = function () {
            var _a = _this.props.formValues, items = _a.items, _b = _a.addedItems, addedItems = _b === void 0 ? [] : _b;
            var allItems = items.concat(addedItems);
            return allItems.reduce(function (accumulator, item) { return (accumulator + ((item.quantity || 0) * (item.rate || 0) * (item.session_duration / _this.getClientSessionDuration()))); }, 0);
        };
        _this.getClientSessionDuration = function () { return (lodash_1.get(_this.props.initialValues, 'client_session_duration', invoice_1.STANDARD_SESSION_DURATION)); };
        _this.redirectToView = function () {
            return _this.props.history.push("/invoices/view/" + _this.props.id);
        };
        _this.preparedValues = function (values) { return ({
            preinvoice_items: values.items.concat((values.addedItems || [])).map(function (item) { return (__assign({}, item, { session_duration: (parseInt(String(item.session_duration)) || invoice_1.STANDARD_SESSION_DURATION) })); }),
            due_date: values.due_date.toString(),
            note: values.note
        }); };
        _this.handleSubmit = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var _a, updateInvoice, id, t, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, updateInvoice = _a.updateInvoice, id = _a.id, t = _a.t;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, updateInvoice(id, this.preparedValues(values))];
                    case 2:
                        _b.sent();
                        react_toastify_1.toast.success(t("invoice_edit.update_success"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        this.redirectToView();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        react_toastify_1.toast.error(t("invoice_edit.update_error"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        throw new redux_form_1.SubmissionError(error_1);
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    InvoiceEdit.prototype.render = function () {
        var _a = this.props, t = _a.t, validators = _a.validators, initialValues = _a.initialValues, removeItem = _a.removeItem, formValues = _a.formValues, handleSubmit = _a.handleSubmit, pristine = _a.pristine, valid = _a.valid, currentUserRole = _a.currentUserRole;
        var isSubmit = !pristine && valid;
        var itemsCount = formValues.items.length +
            (formValues.addedItems ? formValues.addedItems.length : 0);
        var userIsTutor = currentUserRole === "service_provider";
        return (React.createElement(redux_form_1.Form, { className: "add-edit-form add-edit-form__invoice", onSubmit: handleSubmit(this.handleSubmit) },
            React.createElement(action_panel_1.default, { onSubmit: handleSubmit(this.handleSubmit), onCancel: this.redirectToView, isSubmit: isSubmit, title: initialValues.guid, subTitle: initialValues.status }),
            React.createElement("div", { className: "center-content" },
                React.createElement("div", { className: "content" },
                    React.createElement("section", { className: "section__general" },
                        React.createElement("div", { className: "two-line" },
                            React.createElement("div", null,
                                React.createElement("h3", null, t("invoice_edit.bill_to")),
                                React.createElement("p", null, initialValues.client_name),
                                React.createElement("p", null, initialValues.client_email)),
                            React.createElement(redux_form_1.Field, { label: t("invoice_edit.fields.due_date.label"), component: datepicker_1.default, name: "due_date", type: "text", placeholder: t("invoice_edit.fields.due_date.placeholder"), minDate: moment() })),
                        React.createElement(redux_form_1.Field, { label: t("invoice_edit.fields.description.label"), component: textarea_1.default, name: "note", placeholder: t("invoice_edit.fields.description.placeholder") })),
                    React.createElement(description_table_1.default, { itemsCurrentValues: formValues.items, removeItem: removeItem, summ: this.calculateSumm(), validators: validators, itemsCount: itemsCount, userIsTutor: userIsTutor, serviceProviderFee: initialValues.service_provider_fee, clientSessionDuration: this.getClientSessionDuration() }))),
            React.createElement(action_panel_1.default, { onSubmit: handleSubmit(this.handleSubmit), onCancel: this.redirectToView, isSubmit: isSubmit, title: initialValues.guid, subTitle: initialValues.status })));
    };
    InvoiceEdit = __decorate([
        validators_1.default,
        react_router_1.withRouter,
        redux_form_1.reduxForm({
            form: "invoice_edit",
            enableReinitialize: true,
            keepDirtyOnReinitialize: true
        }),
        react_i18next_1.translate()
    ], InvoiceEdit);
    return InvoiceEdit;
}(React.PureComponent));
exports.default = InvoiceEdit;
