"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var spinner_1 = require("../spinner");
var component_1 = require("./component");
var sessions_1 = require("actions/sessions");
var ScheduleContainer = /** @class */ (function (_super) {
    __extends(ScheduleContainer, _super);
    function ScheduleContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScheduleContainer.prototype.render = function () {
        var _a = this.props, selectedDate = _a.selectedDate, schedule_preference_text = _a.schedule_preference_text, providerData = _a.providerData, clientData = _a.clientData, firstSession = _a.firstSession;
        return selectedDate ? (React.createElement(component_1.default, { selectedDate: selectedDate, clientData: clientData, providerData: providerData, firstSession: firstSession, schedule_preference_text: schedule_preference_text })) : (React.createElement(spinner_1.default, null));
    };
    ScheduleContainer.prototype.componentWillMount = function () {
        if (this.props.clientData) {
            this.props.getFirstSessionAction({ client: this.props.clientData.id });
        }
    };
    ScheduleContainer = __decorate([
        react_redux_1.connect(function (state) {
            return {
                firstSession: state.sessions.firstSession,
                selectedDate: state.sessions.selectedDate
            };
        }, function (dispatch) {
            return {
                getFirstSessionAction: redux_1.bindActionCreators(sessions_1.getFirstSession, dispatch)
            };
        })
    ], ScheduleContainer);
    return ScheduleContainer;
}(React.Component));
exports.default = ScheduleContainer;
