"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var spinner_1 = require("components/spinner");
var _1 = require(".");
var clients_1 = require("actions/clients");
var lists_1 = require("actions/lists");
var client_1 = require("constants/client");
var lists_2 = require("constants/lists");
var reload_list_wrapper_1 = require("components/decorators/reload_list_wrapper");
var reload_schedule_first_session_1 = require("components/decorators/reload_schedule_first_session");
var ClientViewContainer = /** @class */ (function (_super) {
    __extends(ClientViewContainer, _super);
    function ClientViewContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            client: null,
            managerName: ""
        };
        _this.getCLient = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, getClientAction, id, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, getClientAction = _a.getClientAction, id = _a.id;
                        if (!(id && getClientAction)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getClientAction(id)];
                    case 1:
                        result = _b.sent();
                        if (result.type === client_1.CLIENT_CONST.CLIENT_GET_SUCCESS) {
                            this.setState({
                                client: result.payload,
                                managerName: result.payload.manager.first_name + " " + result.payload.manager.last_name
                            });
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.handleArchive = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var _a, archiveAction, reloadLists, reloadFirstSession, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, archiveAction = _a.archiveAction, reloadLists = _a.reloadLists, reloadFirstSession = _a.reloadFirstSession;
                        if (!archiveAction) return [3 /*break*/, 2];
                        return [4 /*yield*/, archiveAction(id)];
                    case 1:
                        result = _b.sent();
                        if (result.type === client_1.CLIENT_CONST.CLIENT_ARCHIVE_SUCCESS) {
                            this.getCLient();
                            reloadLists();
                            reloadFirstSession(id);
                        }
                        else {
                            throw result.payload;
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.handleRestore = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var _a, restoreAction, reloadLists, reloadFirstSession, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, restoreAction = _a.restoreAction, reloadLists = _a.reloadLists, reloadFirstSession = _a.reloadFirstSession;
                        if (!restoreAction) return [3 /*break*/, 2];
                        return [4 /*yield*/, restoreAction(id)];
                    case 1:
                        result = _b.sent();
                        if (result.type === client_1.CLIENT_CONST.CLIENT_RESTORE_SUCCESS) {
                            this.getCLient();
                            reloadLists();
                            reloadFirstSession(id);
                        }
                        else {
                            throw result.payload;
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.changeManager = function (newManagerName) {
            _this.setState({
                managerName: newManagerName
            });
        };
        return _this;
    }
    ClientViewContainer.prototype.render = function () {
        var _a = this.props, id = _a.id, userRole = _a.userRole;
        var _b = this.state, client = _b.client, managerName = _b.managerName;
        return client ? (React.createElement(_1.default, { onBackToClientsHandler: this.props.enablePreviousFiltersAction, client: client, id: id, onArchive: this.handleArchive, onRestore: this.handleRestore, changeManager: this.changeManager, userRole: userRole, managerName: managerName, updateClient: this.getCLient })) : (React.createElement(spinner_1.default, null));
    };
    ClientViewContainer.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.getCLient();
                return [2 /*return*/];
            });
        });
    };
    ClientViewContainer = __decorate([
        react_redux_1.connect(function (state) {
            return {
                workspace: state.client.workspace,
                userRole: state.user.my ? state.user.my.role : null
            };
        }, function (dispatch) {
            return {
                enablePreviousFiltersAction: redux_1.bindActionCreators(lists_1.enablePreviousFilters, dispatch),
                getClientAction: redux_1.bindActionCreators(clients_1.getClient, dispatch),
                restoreAction: redux_1.bindActionCreators(clients_1.restore, dispatch),
                archiveAction: redux_1.bindActionCreators(clients_1.archive, dispatch)
            };
        }),
        reload_list_wrapper_1.default([lists_2.LIST_ENTITIES.MOUNTH_SESSIONS, lists_2.LIST_ENTITIES.DAY_SESSIONS]),
        reload_schedule_first_session_1.default()
    ], ClientViewContainer);
    return ClientViewContainer;
}(React.Component));
exports.default = ClientViewContainer;
