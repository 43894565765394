"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_tag_input_1 = require("react-tag-input");
var _1 = require(".");
var createTags = function (items) {
    return (items || []).map(function (item) { return (__assign({}, item, { id: "" + item.id, text: item.text || item.title || item.name || item.label, generated: true })); });
};
var FieldTagsRender = /** @class */ (function (_super) {
    __extends(FieldTagsRender, _super);
    function FieldTagsRender() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            suggestions: [],
            unselected: [],
            filtrated: []
        };
        _this.generateUnselectedTags = function () {
            var tags = _this.props.meta.initial || [];
            var suggestions = _this.props.suggestions;
            return suggestions.filter(function (suggestion) {
                return tags.findIndex(function (tag) { return tag.id == suggestion.id; }) === -1;
            });
        };
        _this.handleDelete = function (i) {
            var tags = _this.props.input.value || [];
            var newInputValues = tags.filter(function (tag, index) { return index !== i; });
            var newUnselected = createTags(_this.props.suggestions.filter(function (tag) {
                return newInputValues.findIndex(function (inputValue) { return inputValue.id == tag.id; }) === -1;
            }));
            _this.setState({
                unselected: newUnselected,
                filtrated: newUnselected
            });
            _this.props.input.onChange(newInputValues);
        };
        _this.handleFilterSuggestions = function (textInputValue, possibleSuggestionsArray) {
            return _this.state.filtrated;
        };
        _this.isTagMadeByEnter = function (tag) {
            return tag.id === tag.text && !tag.generated;
        };
        _this.handleAddition = function (tag) {
            var tagIndex = _this.state.suggestions.findIndex(function (suggestion) {
                return suggestion.text.toLowerCase() === tag.text.toLowerCase();
            });
            if (tagIndex === -1) {
                if (_this.state.filtrated.length === 0) {
                    return;
                }
                var lastTag = _this.state.filtrated[0];
                if (lastTag.text.toLowerCase().indexOf(tag.text.toLowerCase()) !== -1) {
                    tag = lastTag;
                }
                else {
                    return;
                }
            }
            var selectedTags = createTags(_this.props.input.value || []);
            if (selectedTags.find(function (item) { return item.text.toLowerCase() === tag.text.toLowerCase(); })) {
                return;
            }
            if (_this.isTagMadeByEnter(tag)) {
                tag = _this.state.suggestions[tagIndex];
            }
            _this.setState({
                unselected: _this.state.unselected.filter(function (item) { return item.id !== tag.id; }),
                filtrated: _this.state.unselected.filter(function (item) { return item.id !== tag.id; })
            });
            _this.props.input.onChange(selectedTags.concat([tag]));
        };
        _this.handleInputChange = function (value) {
            var lowerCasedValue = value.toLowerCase();
            var filtrated = _this.state.unselected.filter(function (item) { return item.text.toLowerCase().indexOf(lowerCasedValue) !== -1; });
            _this.setState({
                filtrated: filtrated
            });
        };
        _this.handleInputBlur = function () {
            _this.setState({
                filtrated: _this.state.unselected
            });
        };
        return _this;
    }
    FieldTagsRender.prototype.componentWillMount = function () {
        var unselected = this.generateUnselectedTags();
        this.setState({
            suggestions: createTags(this.props.suggestions),
            unselected: createTags(unselected),
            filtrated: createTags(unselected)
        });
    };
    FieldTagsRender.prototype.componentWillReceiveProps = function (nextProps) {
        if (nextProps.input.value !== this.props.input.value &&
            nextProps.input.value === "") {
            var unselected = this.generateUnselectedTags();
            this.setState({
                unselected: createTags(unselected),
                filtrated: createTags(unselected)
            });
        }
    };
    FieldTagsRender.prototype.render = function () {
        var _this = this;
        var props = this.props;
        var tags = [];
        tags = createTags(props.input.value);
        var renderInput = function () { return (React.createElement(react_tag_input_1.WithContext, { tags: tags, suggestions: _this.state.filtrated, handleInputChange: _this.handleInputChange, handleInputBlur: _this.handleInputBlur, delimiters: [13, 9], autofocus: false, minQueryLength: 0, handleFilterSuggestions: _this.handleFilterSuggestions, placeholder: props.placeholder || props.t("customTagPlaceholder"), handleAddition: _this.handleAddition, handleDelete: _this.handleDelete })); };
        return React.createElement(_1.default, __assign({}, props, { renderInput: renderInput }));
    };
    FieldTagsRender = __decorate([
        react_i18next_1.translate()
    ], FieldTagsRender);
    return FieldTagsRender;
}(React.Component));
exports.default = FieldTagsRender;
