"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var clients_1 = require("actions/clients");
var _1 = require(".");
var client_1 = require("constants/client");
var lists_1 = require("constants/lists");
var reload_list_wrapper_1 = require("components/decorators/reload_list_wrapper");
var ProviderViewClientsListContainer = /** @class */ (function (_super) {
    __extends(ProviderViewClientsListContainer, _super);
    function ProviderViewClientsListContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fetchUnassignedClientsList = function (params) {
            if (params === void 0) { params = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var _a, getClientListAction, allUnassignedList, unnasginedClientsRequestParams;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this.props, getClientListAction = _a.getClientListAction, allUnassignedList = _a.allUnassignedList;
                            unnasginedClientsRequestParams = {
                                per_page: "all",
                                service_provider: 0
                            };
                            return [4 /*yield*/, getClientListAction(Object.assign({}, unnasginedClientsRequestParams, params, allUnassignedList.query), lists_1.LIST_ENTITIES.ALL_UNASSIGNED_CLIENTS_LIST)];
                        case 1: return [2 /*return*/, _b.sent()];
                    }
                });
            });
        };
        /**
         * It need for update provider clients list.
         * @method fetchProviderClientsList
         */
        _this.fetchProviderClientsList = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var _a, getClientListAction, lists;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, getClientListAction = _a.getClientListAction, lists = _a.lists;
                        return [4 /*yield*/, getClientListAction(lists[entity].query, entity)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.assignClientToProvider = function (values, entity) { return __awaiter(_this, void 0, void 0, function () {
            var _a, assignClientToProviderAction, reloadLists, providerId, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, assignClientToProviderAction = _a.assignClientToProviderAction, reloadLists = _a.reloadLists;
                        providerId = this.props.providerData.id;
                        if (!assignClientToProviderAction) return [3 /*break*/, 2];
                        return [4 /*yield*/, assignClientToProviderAction(values.clientId.id, providerId)];
                    case 1:
                        result = _b.sent();
                        if (result.type === client_1.CLIENT_CONST.ASSIGN_CLIENT_TO_PROVIDER_SUCCESS) {
                            this.fetchProviderClientsList(entity);
                            reloadLists();
                        }
                        else {
                            // todo
                        }
                        return [2 /*return*/, result];
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.unassignClientFromProvider = function (clientId, entity) { return __awaiter(_this, void 0, void 0, function () {
            var _a, unassignClientFromProviderAction, reloadLists, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, unassignClientFromProviderAction = _a.unassignClientFromProviderAction, reloadLists = _a.reloadLists;
                        if (!unassignClientFromProviderAction) return [3 /*break*/, 2];
                        return [4 /*yield*/, unassignClientFromProviderAction(clientId)];
                    case 1:
                        result = _b.sent();
                        if (result.type === client_1.CLIENT_CONST.UNASSIGN_CLIENT_FROM_PROVIDER_SUCCESS) {
                            this.fetchProviderClientsList(entity);
                            reloadLists();
                        }
                        else {
                            // todo
                        }
                        return [2 /*return*/, result];
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    /**
     * @method render
     */
    ProviderViewClientsListContainer.prototype.render = function () {
        return (React.createElement(_1.default, { fetchUnassignedClientsList: this.fetchUnassignedClientsList, allUnassignedClientsList: this.props.allUnassignedList, providerData: this.props.providerData, assignClientToProvider: this.assignClientToProvider, unassignClientFromProvider: this.unassignClientFromProvider, currentUserId: this.props.currentUserId, currentUserRole: this.props.currentUserRole }));
    };
    ProviderViewClientsListContainer = __decorate([
        react_redux_1.connect(function (state) {
            return {
                currentUserId: state.user.my.id,
                currentUserRole: state.user.my ? state.user.my.role : null,
                lists: state.lists,
                allUnassignedList: state.lists[lists_1.LIST_ENTITIES.ALL_UNASSIGNED_CLIENTS_LIST]
            };
        }, function (dispatch) {
            return {
                getClientListAction: redux_1.bindActionCreators(clients_1.getClientList, dispatch),
                assignClientToProviderAction: redux_1.bindActionCreators(clients_1.assignClientToProvider, dispatch),
                unassignClientFromProviderAction: redux_1.bindActionCreators(clients_1.unassignClientFromProvider, dispatch)
            };
        }),
        reload_list_wrapper_1.default([
            lists_1.LIST_ENTITIES.MOUNTH_SESSIONS,
            lists_1.LIST_ENTITIES.DAY_SESSIONS
        ])
    ], ProviderViewClientsListContainer);
    return ProviderViewClientsListContainer;
}(React.Component));
exports.default = ProviderViewClientsListContainer;
