"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_1 = require("constants/user");
var _ = require("lodash");
var config = [
    {
        roles: [user_1.Role.admin, user_1.Role.manager],
        title: "top_menu.all_service_providers",
        link: "/providers"
    },
    {
        roles: [user_1.Role.manager, user_1.Role.serviceProvider],
        title: "top_menu.my_clients",
        link: "/clients"
    },
    {
        roles: [user_1.Role.admin],
        title: "top_menu.all_clients",
        link: "/clients"
    }
];
exports.default = (function (role) {
    var result = [];
    _.each(config, function (item) {
        if (_.find(item.roles, function (roleInConfig) { return roleInConfig === role; })) {
            result.push(item);
        }
    });
    return result;
});
