"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ApiСommunicationAdapter = /** @class */ (function () {
    function ApiСommunicationAdapter() {
    }
    ApiСommunicationAdapter.prototype.prepareTag = function (object, fromKey, toKey) {
        if (object[fromKey]) {
            object[toKey] = object[fromKey].map(function (item) {
                return item.id;
            });
        }
    };
    ApiСommunicationAdapter.prototype.prepareSelect = function (object, fieldKey) {
        if (object[fieldKey]) {
            object[fieldKey] = object[fieldKey].id;
        }
    };
    ApiСommunicationAdapter.prototype.prepareNotZeroSelect = function (object, fromKey, toKey) {
        if (object[fromKey] && object[fromKey].id !== 0) {
            object[toKey] = object[fromKey].id;
        }
    };
    ApiСommunicationAdapter.prototype.emptyStringToNull = function (object, fieldKey) {
        if (object[fieldKey] === "") {
            object[fieldKey] = null;
        }
    };
    return ApiСommunicationAdapter;
}());
exports.ApiСommunicationAdapter = ApiСommunicationAdapter;
