"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var auth_1 = require("../helpers/auth");
var overflow_loader_1 = require("../overflow-loader");
var SECONDS_BEFORE_RELOAD = 3;
/**
 * @class App
 */
var AuthProvider = /** @class */ (function (_super) {
    __extends(AuthProvider, _super);
    function AuthProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            token: auth_1.default.getToken(),
            isLoaderVisible: false,
            timer: SECONDS_BEFORE_RELOAD
        };
        _this.onVisibilityChangeHandler = function () {
            if (document.hidden) {
                _this.setState({ token: auth_1.default.getToken() });
            }
            else {
                if (_this.state.token !== auth_1.default.getToken()) {
                    _this.setState({ isLoaderVisible: true });
                    var interval_1 = setInterval(function () {
                        if (_this.state.timer === 0) {
                            clearInterval(interval_1);
                            window.location.reload();
                        }
                        else {
                            _this.setState({ timer: _this.state.timer - 1 });
                        }
                    }, 1000);
                }
            }
        };
        return _this;
    }
    AuthProvider.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            this.props.children,
            React.createElement(overflow_loader_1.default, { isLoaderVisible: this.state.isLoaderVisible, text: this.props.t("reload", { timer: this.state.timer }) })));
    };
    AuthProvider.prototype.componentWillMount = function () {
        document.addEventListener("visibilitychange", this.onVisibilityChangeHandler);
    };
    AuthProvider.prototype.componentWillUnmount = function () {
        document.removeEventListener("visibilitychange", this.onVisibilityChangeHandler);
    };
    AuthProvider = __decorate([
        react_i18next_1.translate()
    ], AuthProvider);
    return AuthProvider;
}(React.Component));
exports.default = AuthProvider;
