"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var react_router_1 = require("react-router");
var _a = require("reactstrap"), Popover = _a.Popover, PopoverBody = _a.PopoverBody;
var ProviderTableRowActions = /** @class */ (function (_super) {
    __extends(ProviderTableRowActions, _super);
    function ProviderTableRowActions(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isOpened: false,
            id: "popover-id-" + new Date().getTime()
        };
        _this.onViewDetais = function (e) {
            e.stopPropagation();
            var _a = _this.props, history = _a.history, rowData = _a.rowData;
            if (history) {
                history.push("/clients/view/" + rowData.id);
            }
        };
        _this.onUnasignClient = function (e) {
            e.stopPropagation();
            _this.onClosePopover();
            _this.props.unassignClientFromProvider(_this.props.rowData.id);
        };
        _this.onClosePopover = function () {
            _this.setState({
                isOpened: false
            });
        };
        _this.onIconClick = _this.onIconClick.bind(_this);
        return _this;
    }
    ProviderTableRowActions.prototype.render = function () {
        var t = this.props.t;
        return t ? (React.createElement(React.Fragment, null,
            React.createElement("a", { className: "", id: this.state.id, onClick: this.onIconClick },
                React.createElement("i", { className: "fas fa-ellipsis-v" })),
            React.createElement(Popover, { placement: "bottom", isOpen: this.state.isOpened, target: this.state.id, toggle: this.toggle.bind(this) },
                React.createElement(PopoverBody, null,
                    React.createElement("ul", { className: "actions-list" },
                        React.createElement("li", null,
                            React.createElement("a", { onClick: this.onViewDetais }, t("provider_clients_list.popover.view_client_details"))),
                        React.createElement("li", null,
                            React.createElement("a", { onClick: this.onUnasignClient }, t("provider_clients_list.popover.unasign")))))))) : null;
    };
    ProviderTableRowActions.prototype.toggle = function () {
        var _this = this;
        this.setState({
            isOpened: !this.state.isOpened
        }, function () {
            if (!_this.state.isOpened &&
                _this.props.rowData &&
                _this.props.unselectRow) {
                _this.props.unselectRow(_this.props.rowData.id);
            }
        });
    };
    ProviderTableRowActions.prototype.onIconClick = function (e) {
        e.stopPropagation();
        var handleLastRowAction = this.props.handleLastRowAction;
        if (handleLastRowAction) {
            handleLastRowAction(this.props.rowData.id);
        }
        this.toggle();
    };
    ProviderTableRowActions = __decorate([
        react_router_1.withRouter,
        react_i18next_1.translate()
    ], ProviderTableRowActions);
    return ProviderTableRowActions;
}(React.Component));
exports.default = ProviderTableRowActions;
