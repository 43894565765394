"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_router_1 = require("react-router");
var react_router_dom_1 = require("react-router-dom");
var clear_filter_1 = require("./clear_filter");
var NavItem = /** @class */ (function (_super) {
    __extends(NavItem, _super);
    function NavItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NavItem.prototype.render = function () {
        var _a = this.props, t = _a.t, item = _a.item, match = _a.match;
        return t && item && match ? (React.createElement("li", null,
            React.createElement(clear_filter_1.default, { link: item.link }, item.link ? (React.createElement(react_router_dom_1.Link, { className: match.path.indexOf(item.link) >= 0 ? "current" : "", to: item.link, replace: true }, t(item.title))) : null))) : null;
    };
    NavItem = __decorate([
        react_i18next_1.translate(),
        react_router_1.withRouter
    ], NavItem);
    return NavItem;
}(React.Component));
exports.default = NavItem;
