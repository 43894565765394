"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_stripe_elements_1 = require("react-stripe-elements");
var react_i18next_1 = require("react-i18next");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var invoice_1 = require("actions/invoice");
var invoice_2 = require("constants/invoice");
require("./../../style.less");
var invoice_calculation_1 = require("helpers/invoice_calculation");
var react_toastify_1 = require("react-toastify");
var overflow_loader_1 = require("components/overflow-loader");
var CheckoutForm = /** @class */ (function (_super) {
    __extends(CheckoutForm, _super);
    function CheckoutForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            card_error: "",
            submitting: false
        };
        _this.handleSubmit = function (e) {
            e.preventDefault();
            var _a = _this.props, chargeInvoiceAction = _a.chargeInvoiceAction, invoice = _a.invoice, onFirstStep = _a.onFirstStep, t = _a.t, isInternationalPayment = _a.isInternationalPayment;
            _this.setState({ submitting: true });
            _this.props.stripe
                .createToken({ name: "card-payment" })
                .then(function (createTokenResult) {
                if (createTokenResult.error) {
                    _this.setState({
                        card_error: createTokenResult.error.message,
                        submitting: false
                    });
                }
                else {
                    chargeInvoiceAction({
                        stripe_token: createTokenResult.token,
                        uuid: invoice.uuid,
                        amount: invoice_calculation_1.default.countTotalAmountForCard(invoice, isInternationalPayment),
                        updated_at: invoice.updated_at
                    })
                        .then(function (result) {
                        if (result.type === invoice_2.INVOICE_CONST.CHARGE_INVOICE_SUCCESS) {
                            _this.setState({ submitting: false });
                        }
                        else {
                            if (result.payload.reason === "conflict") {
                                react_toastify_1.toast.error(t("client_invoice.notifications.invoice_was_updated"), {
                                    position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                    hideProgressBar: true
                                });
                                _this.setState({ submitting: false });
                                onFirstStep();
                            }
                            else {
                                _this.setState({
                                    card_error: result.payload.base,
                                    submitting: false
                                });
                            }
                        }
                    })
                        .catch(function (error) {
                        _this.setState({ card_error: error, submitting: false });
                    });
                }
            })
                .catch(function (error) {
                _this.setState({
                    card_error: t("client_invoice.notifications.stripe_not_allowed"),
                    submitting: false
                });
            });
        };
        _this.onBack = function () {
            _this.props.onBack();
        };
        _this.onCardChange = function () {
            _this.setState({ card_error: "" });
        };
        return _this;
    }
    CheckoutForm.prototype.render = function () {
        var _a = this.props, t = _a.t, invoice = _a.invoice, isInternationalPayment = _a.isInternationalPayment;
        var _b = this.state, card_error = _b.card_error, submitting = _b.submitting;
        var amount = invoice_calculation_1.default.countTotalAmountForCard(invoice, isInternationalPayment);
        return (React.createElement("div", { className: "checkout" },
            React.createElement(overflow_loader_1.default, { isLoaderVisible: submitting }),
            React.createElement("form", { id: "stripe-card-form", onSubmit: this.handleSubmit },
                React.createElement("div", { className: "add-edit-form client-invoice-form info-block invoice-wizard-step" },
                    React.createElement("h3", null,
                        " ",
                        t("client_invoice.step_3_card.title"),
                        " "),
                    React.createElement(react_stripe_elements_1.CardElement, { onChange: this.onCardChange, classes: { base: "stripe-field", focus: "is-focused" } }),
                    React.createElement("div", { className: "card-error error base" }, card_error)),
                React.createElement("div", { className: "wizard-buttons-panel" },
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement("button", { onClick: this.onBack, className: "button button__back" },
                            React.createElement("i", { className: "fas fa-arrow-left margin-right-10" }),
                            t("wizard_default.buttons.back")),
                        React.createElement("button", { type: "submit", className: "button button__red button__next" }, t("client_invoice.next_button.pay_amount", { amount: amount })))))));
    };
    CheckoutForm = __decorate([
        react_i18next_1.translate(),
        react_redux_1.connect(function (state) {
            return {
                invoice: state.clientInvoice.invoice
            };
        }, function (dispatch) {
            return {
                chargeInvoiceAction: redux_1.bindActionCreators(invoice_1.chargeInvoice, dispatch)
            };
        })
    ], CheckoutForm);
    return CheckoutForm;
}(React.Component));
exports.default = react_stripe_elements_1.injectStripe(CheckoutForm);
