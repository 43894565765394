"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ServiceProviderHelper = /** @class */ (function () {
    function ServiceProviderHelper() {
    }
    ServiceProviderHelper.getOptions = function (serviceProviders) {
        var items = (serviceProviders || []).map(function (item) {
            return Object.assign(item, {
                label: item.name,
                value: item.id
            });
        });
        items.unshift({
            label: "Unassigned",
            value: 0
        });
        return items;
    };
    return ServiceProviderHelper;
}());
exports.default = ServiceProviderHelper;
