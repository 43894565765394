"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var moment = require("moment");
var redux_form_1 = require("redux-form");
var datepicker_1 = require("../../form/fields/datepicker");
var defaultStartTime = moment()
    .set("hour", 10)
    .set("minute", 0);
exports.RecurringStartTimeField = function (_a) {
    var t = _a.t, isOverlapsWarningVisible = _a.isOverlapsWarningVisible, validators = _a.validators, formChange = _a.formChange, clientSessionDuration = _a.clientSessionDuration, formTouch = _a.formTouch;
    return (React.createElement(redux_form_1.Field, { label: t("add_session_form.fields.session_start_time.label"), component: datepicker_1.default, selected: defaultStartTime, showTimeSelect: true, showTimeSelectOnly: true, dateFormat: "hh:mm A", timeFormat: "hh:mm A", timeIntervals: 5, timeCaption: "time", selectsStart: true, disabled: isOverlapsWarningVisible, disableTyping: true, onChangeCallback: function (newDate) {
            if (moment.isMoment(newDate) && newDate.isValid()) {
                formTouch("add_new_session", "recurring.end_time");
                var sessionEndTime = moment(newDate).add(clientSessionDuration, "minutes");
                if (sessionEndTime.day() !== newDate.day()) {
                    sessionEndTime = moment(newDate).set({
                        hour: 23,
                        minute: 55,
                        second: 0,
                        millisecond: 0
                    });
                }
                formChange("recurring.end_time", sessionEndTime);
            }
        }, require: "true", validate: [validators.required, validators.moment], name: "recurring.begin_time", type: "text", placeholder: t("add_session_form.fields.session_start_time.placeholder") }));
};
exports.RecurringEndTimeField = function (_a) {
    var t = _a.t, validators = _a.validators, isOverlapsWarningVisible = _a.isOverlapsWarningVisible;
    return (React.createElement(redux_form_1.Field, { label: t("add_session_form.fields.session_end_time.label"), component: datepicker_1.default, showTimeSelect: true, showTimeSelectOnly: true, dateFormat: "hh:mm A", timeFormat: "hh:mm A", timeIntervals: 5, timeCaption: "time", selectsEnd: true, disabled: isOverlapsWarningVisible, disableTyping: true, require: "true", validate: [
            validators.required,
            validators.moment,
            validators.recurringAfterBeginTime
        ], name: "recurring.end_time", type: "text", placeholder: t("add_session_form.fields.session_end_time.placeholder") }));
};
// ONE TIME
exports.OneTimeStartAtField = function (_a) {
    var t = _a.t, validators = _a.validators, isOverlapsWarningVisible = _a.isOverlapsWarningVisible, formChange = _a.formChange, clientSessionDuration = _a.clientSessionDuration;
    return (React.createElement(redux_form_1.Field, { label: t("add_session_form.fields.session_start_time.label"), component: datepicker_1.default, showTimeSelect: true, timeFormat: "hh:mm A", timeIntervals: 5, dateFormat: "LLL", timeCaption: "time", selectsStart: true, disabled: isOverlapsWarningVisible, onChangeCallback: function (newDate) {
            if (moment.isMoment(newDate) && newDate.isValid()) {
                formChange("oneTime.end_at", moment(newDate).add(clientSessionDuration, "minutes"));
            }
        }, require: "true", validate: [validators.required, validators.moment, validators.minYear2010], name: "oneTime.begin_at", type: "text", placeholder: t("add_session_form.fields.session_start_time.placeholder") }));
};
exports.OneTimeEndAtField = function (_a) {
    var t = _a.t, isOverlapsWarningVisible = _a.isOverlapsWarningVisible, validators = _a.validators;
    return (React.createElement(redux_form_1.Field, { label: t("add_session_form.fields.session_end_time.label"), component: datepicker_1.default, showTimeSelect: true, timeFormat: "hh:mm A", timeIntervals: 5, dateFormat: "LLL", timeCaption: "time", selectsEnd: true, disabled: isOverlapsWarningVisible, require: "true", validate: [
            validators.required,
            validators.moment,
            validators.oneTimeAfterBeginAt
        ], name: "oneTime.end_at", type: "text", placeholder: t("add_session_form.fields.session_end_time.placeholder") }));
};
