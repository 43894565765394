"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Cookie = require("js-cookie");
var jwtDecode = require("jwt-decode");
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    AuthService.updateToken = function (token) {
        Cookie.set("token", token);
        var decoded = jwtDecode(token);
        Cookie.set("decoded", JSON.stringify(decoded));
    };
    AuthService.logout = function () {
        Cookie.remove("token");
        Cookie.remove("decoded");
    };
    AuthService.isAuthorized = function () {
        return !!(Cookie.get("token") && Cookie.get("decoded"));
    };
    AuthService.getRole = function () {
        var decoded = JSON.parse(Cookie.get("decoded") || "{}");
        return AuthService.isAuthorized() ? decoded.role : null;
    };
    AuthService.isAuthorizedAsManager = function () {
        var decoded = JSON.parse(Cookie.get("decoded") || "{}");
        return decoded.role === "manager";
    };
    AuthService.isAuthorizedAsServiceProvider = function () {
        var decoded = JSON.parse(Cookie.get("decoded") || "{}");
        return decoded.role === "service_provider";
    };
    AuthService.isSuperAdmin = function () {
        var decoded = JSON.parse(Cookie.get("decoded") || "{}");
        return decoded.super_admin;
    };
    AuthService.getToken = function () {
        return AuthService.isAuthorized() ? Cookie.get("token") : null;
    };
    return AuthService;
}());
exports.default = AuthService;
