"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var simple_1 = require("layouts/simple");
var ExpiredLink = /** @class */ (function (_super) {
    __extends(ExpiredLink, _super);
    function ExpiredLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExpiredLink.prototype.render = function () {
        var t = this.props.t;
        return (React.createElement(simple_1.default, { logoToLanding: true },
            React.createElement("div", { className: "auth-page-content" },
                React.createElement("h1", null, t("error_page.link_expired")),
                React.createElement(react_router_dom_1.Link, { className: "$ button button__red button__block", to: "/login" }, t("error_page.back_to_login")))));
    };
    return ExpiredLink;
}(React.Component));
exports.default = react_i18next_1.translate()(ExpiredLink);
