"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _1 = require(".");
var react_select_1 = require("react-select");
function transformValue(value, options, multi) {
    if (!multi)
        return typeof value === "object" ? value.id : value;
    if (typeof value === "string")
        return [];
    return options.filter(function (option) { return value.indexOf(option.value) !== -1; });
}
var FieldSelectRender = /** @class */ (function (_super) {
    __extends(FieldSelectRender, _super);
    function FieldSelectRender() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FieldSelectRender.prototype.render = function () {
        var _a = this.props, _b = _a.menuStyle, menuStyle = _b === void 0 ? {} : _b, _c = _a.clearable, clearable = _c === void 0 ? false : _c, _d = _a.backspaceRemoves, backspaceRemoves = _d === void 0 ? false : _d, options = _a.options, disabled = _a.disabled, input = _a.input, placeholder = _a.placeholder, _e = _a.multi, multi = _e === void 0 ? false : _e, _f = _a.searchable, searchable = _f === void 0 ? true : _f, meta = _a.meta, simpleValue = _a.simpleValue, removeSelected = _a.removeSelected, valueComponent = _a.valueComponent, closeOnSelect = _a.closeOnSelect, onClose = _a.onClose;
        var isErrors = meta.touched && meta.error;
        var singleChangeHandler = function (value) {
            input.onChange({
                id: value ? value.value : ""
            });
        };
        var multiChangeHandler = function (values) {
            input.onChange(values.map(function (value) { return value.value; }));
        };
        var style = { backgroundColor: disabled ? '#e6e6e6' : '' };
        var renderInput = function () { return (React.createElement(react_select_1.default, { style: style, menuStyle: menuStyle, clearable: clearable, backspaceRemoves: backspaceRemoves, options: options, 
            // value={value}
            value: transformValue(input.value, options, multi), disabled: disabled, name: input.name, onChange: multi ? multiChangeHandler : singleChangeHandler, className: isErrors ? "input-wrapper-error" : "", placeholder: placeholder, multi: multi, searchable: searchable, simpleValue: simpleValue, removeSelected: removeSelected, valueComponent: valueComponent, closeOnSelect: closeOnSelect, onClose: onClose })); };
        return React.createElement(_1.default, __assign({}, this.props, { renderInput: renderInput }));
    };
    return FieldSelectRender;
}(React.Component));
exports.default = FieldSelectRender;
