"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var auth_1 = require("components/helpers/auth");
var headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
};
function getDefaultParams(ignoreToken) {
    if (ignoreToken === void 0) { ignoreToken = false; }
    var result = {
        headers: __assign({}, headers)
    };
    if (auth_1.default.isAuthorized() && !ignoreToken) {
        result.headers.Authorization = "" + auth_1.default.getToken();
    }
    return result;
}
exports.getDefaultParams = getDefaultParams;
