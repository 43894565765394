"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_form_1 = require("redux-form");
var humanize_duration_1 = require("helpers/humanize-duration");
var Duration = /** @class */ (function (_super) {
    __extends(Duration, _super);
    function Duration() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Duration.prototype.render = function () {
        var _a = this.props, values = _a.values, _b = _a.recurring, recurring = _b === void 0 ? false : _b;
        var duration = "";
        if (values) {
            duration = recurring
                ? humanize_duration_1.default.convert(values.recurring.begin_time, values.recurring.end_time)
                : humanize_duration_1.default.convert(values.oneTime.begin_at, values.oneTime.end_at);
        }
        return React.createElement("div", { className: "duration" }, duration);
    };
    Duration = __decorate([
        react_redux_1.connect(function (state) {
            return {
                values: redux_form_1.getFormValues("add_new_session")(state)
            };
        })
    ], Duration);
    return Duration;
}(React.Component));
exports.default = Duration;
