"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var MODAL_OPEN_CLASS = "modal-opened";
var PopupContent = /** @class */ (function (_super) {
    __extends(PopupContent, _super);
    function PopupContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PopupContent.prototype.componentDidMount = function () {
        document.getElementById("root").classList.add(MODAL_OPEN_CLASS);
    };
    PopupContent.prototype.componentWillUnmount = function () {
        document.getElementById("root").classList.remove(MODAL_OPEN_CLASS);
    };
    PopupContent.prototype.render = function () {
        var _a = this.props, onClose = _a.onClose, popupExtendedClass = _a.popupExtendedClass, blockClickOutside = _a.blockClickOutside, children = _a.children;
        // Breaks assign new client modal
        // const Wrapper = blockClickOutside
        //   ? React.Fragment
        //   : ({ children }: any) => (
        //       <ClickOutside onClickOutside={onClose!}>{children}</ClickOutside>
        //     );
        return (React.createElement("div", { className: "popup " + (popupExtendedClass || "") },
            React.createElement("button", { className: "icon close popup-close", onClick: onClose }),
            React.createElement("div", { className: "content" }, children)));
    };
    return PopupContent;
}(React.Component));
exports.default = PopupContent;
