"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var redux_form_1 = require("redux-form");
var tags_1 = require("components/form/fields/tags");
var select_1 = require("components/form/fields/select");
var service_provider_helper_1 = require("helpers/service_provider_helper");
var gender_1 = require("helpers/gender");
var ClientsFilterDetail = /** @class */ (function (_super) {
    __extends(ClientsFilterDetail, _super);
    function ClientsFilterDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientsFilterDetail.prototype.render = function () {
        var _a = this.props, t = _a.t, workspace = _a.workspace, allServiceProviders = _a.allServiceProviders;
        return t && workspace ? (React.createElement("div", { className: "main-page-filter-detail form" },
            React.createElement("h2", null, t("cleints_filter_detail.title")),
            React.createElement(redux_form_1.Field, { label: t("clients_filter.service_provider.label"), clearable: true, backspaceRemoves: true, component: select_1.default, name: "service_provider", options: service_provider_helper_1.default.getOptions(allServiceProviders), placeholder: t("clients_filter.service_provider.placeholder") }),
            React.createElement(redux_form_1.Field, { label: t("clients_filter.gender.label"), component: select_1.default, clearable: true, options: gender_1.default.getGendersWithNoPreference(workspace.genders), name: "gender", type: "text", placeholder: t("clients_filter.gender.placeholder") }),
            React.createElement(redux_form_1.Field, { label: t("clients_filter.preferred_gender.label"), clearable: true, backspaceRemoves: true, component: select_1.default, name: "preferred_gender", type: "text", options: gender_1.default.getGendersWithNoPreference(workspace.genders), placeholder: t("clients_filter.preferred_gender.placeholder") }),
            React.createElement(redux_form_1.Field, { label: t("clients_filter.expertise.label"), component: tags_1.default, suggestions: workspace.expertises, name: "expertises", type: "text", placeholder: t("clients_filter.expertise.placeholder") }))) : null;
    };
    ClientsFilterDetail = __decorate([
        react_i18next_1.translate()
    ], ClientsFilterDetail);
    return ClientsFilterDetail;
}(React.Component));
exports.default = ClientsFilterDetail;
