"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var service_provider_1 = require("actions/service_provider");
var lists_1 = require("actions/lists");
var service_provider_2 = require("constants/service_provider");
var spinner_1 = require("components/spinner");
var _1 = require(".");
var clients_1 = require("actions/clients");
var lists_2 = require("constants/lists");
var reload_list_wrapper_1 = require("components/decorators/reload_list_wrapper");
var prefered_time_1 = require("helpers/prefered_time");
var ProviderViewContainer = /** @class */ (function (_super) {
    __extends(ProviderViewContainer, _super);
    function ProviderViewContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            providerData: null
        };
        _this.convertTimeSlots = function (provider) {
            var attributes = provider.preference_attributes.available_time_slots_attributes;
            attributes.days = prefered_time_1.prepareScheduleValuesFromGet(attributes.days);
        };
        _this.fetchClientsList = function (entity) { return __awaiter(_this, void 0, void 0, function () {
            var _a, getClientListAction, lists;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, getClientListAction = _a.getClientListAction, lists = _a.lists;
                        return [4 /*yield*/, getClientListAction(lists[entity].query, entity)];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        }); };
        _this.handleArchive = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var _a, archiveAction, getServiceProviderAction, reloadLists, result, result_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, archiveAction = _a.archiveAction, getServiceProviderAction = _a.getServiceProviderAction, reloadLists = _a.reloadLists;
                        return [4 /*yield*/, archiveAction(id)];
                    case 1:
                        result = _b.sent();
                        if (!(result.type === service_provider_2.SERVICE_PROVIDER_CONST.SERVICE_PROVIDER_ARCHIVE_SUCCESS)) return [3 /*break*/, 5];
                        return [4 /*yield*/, getServiceProviderAction(id)];
                    case 2:
                        result_1 = _b.sent();
                        this.onSuccessGetProvider(result_1);
                        /**
                         * TODO info about current active table should be in redux, after that fix it
                         */
                        return [4 /*yield*/, this.fetchClientsList(lists_2.LIST_ENTITIES.ALL_PROVIDER_CLIENTS_PAGINATED_LIST)];
                    case 3:
                        /**
                         * TODO info about current active table should be in redux, after that fix it
                         */
                        _b.sent();
                        return [4 /*yield*/, this.fetchClientsList(lists_2.LIST_ENTITIES.MANAGER_PROVIDER_CLIENTS_PAGINATED_LIST)];
                    case 4:
                        _b.sent();
                        reloadLists();
                        return [3 /*break*/, 6];
                    case 5: throw result.payload;
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.handleRestore = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var _a, restoreAction, getServiceProviderAction, reloadLists, result, result_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, restoreAction = _a.restoreAction, getServiceProviderAction = _a.getServiceProviderAction, reloadLists = _a.reloadLists;
                        return [4 /*yield*/, restoreAction(id)];
                    case 1:
                        result = _b.sent();
                        if (!(result.type === service_provider_2.SERVICE_PROVIDER_CONST.SERVICE_PROVIDER_RESTORE_SUCCESS)) return [3 /*break*/, 3];
                        reloadLists();
                        return [4 /*yield*/, getServiceProviderAction(id)];
                    case 2:
                        result_2 = _b.sent();
                        this.onSuccessGetProvider(result_2);
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.switchBilling = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var _a, switchBillingAction, getServiceProviderAction, reloadLists, result, result_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, switchBillingAction = _a.switchBillingAction, getServiceProviderAction = _a.getServiceProviderAction, reloadLists = _a.reloadLists;
                        return [4 /*yield*/, switchBillingAction(id)];
                    case 1:
                        result = _b.sent();
                        if (!(result.type ===
                            service_provider_2.SERVICE_PROVIDER_CONST.SERVICE_PROVIDER_SWITCH_BILLING_SUCCESS)) return [3 /*break*/, 3];
                        reloadLists();
                        return [4 /*yield*/, getServiceProviderAction(id)];
                    case 2:
                        result_3 = _b.sent();
                        this.onSuccessGetProvider(result_3);
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.onSuccessGetProvider = function (result) {
            _this.convertTimeSlots(result.payload);
            _this.setState({
                providerData: result.payload
            });
        };
        return _this;
    }
    ProviderViewContainer.prototype.render = function () {
        var id = this.props.id;
        var providerData = this.state.providerData;
        return providerData ? (React.createElement(_1.default, { onBackToProvidersHandler: this.props.enablePreviousFiltersAction, providerData: providerData, id: id, onArchive: this.handleArchive, onRestore: this.handleRestore, switchBilling: this.switchBilling, currentUserRole: this.props.currentUserRole })) : (React.createElement(spinner_1.default, null));
    };
    ProviderViewContainer.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, getServiceProviderAction, id, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, getServiceProviderAction = _a.getServiceProviderAction, id = _a.id;
                        return [4 /*yield*/, getServiceProviderAction(id)];
                    case 1:
                        result = _b.sent();
                        if (result.type === service_provider_2.SERVICE_PROVIDER_CONST.SERVICE_PROVIDER_GET_SUCCESS) {
                            this.onSuccessGetProvider(result);
                        }
                        else {
                            // TODO
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProviderViewContainer = __decorate([
        reload_list_wrapper_1.default([
            lists_2.LIST_ENTITIES.MOUNTH_SESSIONS,
            lists_2.LIST_ENTITIES.DAY_SESSIONS
        ]),
        react_redux_1.connect(function (state) {
            return {
                workspace: state.serviceProvider.workspace,
                currentUserRole: state.user.my ? state.user.my.role : null,
                lists: state.lists
            };
        }, function (dispatch) {
            return {
                enablePreviousFiltersAction: redux_1.bindActionCreators(lists_1.enablePreviousFilters, dispatch),
                getClientListAction: redux_1.bindActionCreators(clients_1.getClientList, dispatch),
                archiveAction: redux_1.bindActionCreators(service_provider_1.archiveServiceProvider, dispatch),
                restoreAction: redux_1.bindActionCreators(service_provider_1.restoreServiceProvider, dispatch),
                getServiceProviderAction: redux_1.bindActionCreators(service_provider_1.getServiceProvider, dispatch),
                switchBillingAction: redux_1.bindActionCreators(service_provider_1.switchBilling, dispatch)
            };
        })
    ], ProviderViewContainer);
    return ProviderViewContainer;
}(React.Component));
exports.default = ProviderViewContainer;
