"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var private_1 = require("components/helpers/user/private");
var main_1 = require("layouts/main");
var container_1 = require("./item/container");
var InvoicesViewPage = /** @class */ (function (_super) {
    __extends(InvoicesViewPage, _super);
    function InvoicesViewPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InvoicesViewPage.prototype.render = function () {
        var match = this.props.match;
        return match ? (React.createElement(main_1.default, null,
            React.createElement(container_1.default, { id: parseInt(match.params.id, 10) }))) : null;
    };
    return InvoicesViewPage;
}(React.Component));
exports.default = private_1.default(react_router_1.withRouter(InvoicesViewPage));
