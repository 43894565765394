"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var lists_1 = require("actions/lists");
var redux_form_1 = require("redux-form");
var _ = require("lodash");
var pagination_1 = require("./pagination");
var lists_2 = require("constants/lists");
var spinner_1 = require("../spinner");
var TableWrapper = /** @class */ (function (_super) {
    __extends(TableWrapper, _super);
    function TableWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleReset = function () {
            var _a = _this.props, resetAction = _a.resetAction, cleanTablePreviousFiltersAction = _a.cleanTablePreviousFiltersAction, _b = _a.filtrationFormName, filtrationFormName = _b === void 0 ? "" : _b, _c = _a.entity, entity = _c === void 0 ? lists_2.LIST_ENTITIES.DEFAULT : _c;
            cleanTablePreviousFiltersAction(entity).then(function () {
                resetAction(filtrationFormName);
            });
        };
        _this.onUpdatePage = function (page) {
            var _a = _this.props, _b = _a.entity, entity = _b === void 0 ? "" : _b, lists = _a.lists;
            var query = _.cloneDeep(lists[entity].query);
            query.page = page;
            _this.props.updateTable(query, entity);
        };
        _this.onUpdateSort = function (sortName) {
            var _a = _this.props, _b = _a.entity, entity = _b === void 0 ? "" : _b, lists = _a.lists;
            var query = _.cloneDeep(lists[entity].query);
            query.sort = sortName;
            query.page = 1;
            _this.props.updateTable(query, entity);
        };
        return _this;
    }
    TableWrapper.prototype.componentWillMount = function () {
        var _a = this.props, lists = _a.lists, _b = _a.entity, entity = _b === void 0 ? "" : _b, updateTable = _a.updateTable, initialSort = _a.initialSort;
        if (this.props.isNeedUpdateOnMount) {
            var query = _.cloneDeep(lists[entity].query);
            query.sort = initialSort;
            updateTable(query, entity);
        }
    };
    TableWrapper.prototype.render = function () {
        var _a = this.props, renderTable = _a.children, withPagination = _a.withPagination, lists = _a.lists, _b = _a.entity, entity = _b === void 0 ? "" : _b;
        var tableData = lists[entity];
        if (tableData.loading && !tableData.items.length) {
            return React.createElement(spinner_1.default, null);
        }
        var tableProps = {
            items: tableData.items,
            handleSelectSort: this.onUpdateSort,
            onUpdatePage: this.onUpdatePage,
            onReset: this.handleReset,
            currentSort: tableData.query.sort
        };
        return (React.createElement(React.Fragment, null,
            renderTable(tableProps),
            withPagination && (React.createElement(pagination_1.default, { onUpdatePage: this.onUpdatePage, meta: tableData.meta, page: tableData.query.page }))));
    };
    return TableWrapper;
}(React.Component));
exports.default = react_redux_1.connect(function (state) { return ({
    lists: state.lists
}); }, function (dispatch) { return ({
    cleanTablePreviousFiltersAction: redux_1.bindActionCreators(lists_1.cleanPreviousFilters, dispatch),
    resetAction: redux_1.bindActionCreators(redux_form_1.reset, dispatch)
}); })(TableWrapper);
