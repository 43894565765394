"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var item_1 = require("./item");
var react_sortable_hoc_1 = require("react-sortable-hoc");
var RenderEductaionsSortable = /** @class */ (function (_super) {
    __extends(RenderEductaionsSortable, _super);
    function RenderEductaionsSortable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleAddEducation = function () {
            var fields = _this.props.fields;
            if (fields) {
                fields.push("");
            }
        };
        _this.handleRemoveEducation = function (index) {
            var fields = _this.props.fields;
            if (fields) {
                fields.remove(index);
            }
        };
        return _this;
    }
    RenderEductaionsSortable.prototype.render = function () {
        var _this = this;
        var _a = this.props, fields = _a.fields, t = _a.t, validators = _a.validators, degrees = _a.degrees;
        return t ? (React.createElement("div", { className: "educations" },
            fields.map(function (member, index) { return (React.createElement(item_1.default, { key: "item-" + index, validators: validators, member: member, index: index, itemIndex: index, degrees: degrees, t: t, fieldsLength: fields.length, onHandleRemoveEducation: _this.handleRemoveEducation })); }),
            fields.length == 5 ? (React.createElement("p", { className: "text-magenta" }, t("provider_add_form.maximum_educations"))) : (React.createElement("button", { className: "button button__transparent", type: "button", onClick: this.handleAddEducation }, t("provider_add_form.add_more"))))) : null;
    };
    RenderEductaionsSortable = __decorate([
        react_i18next_1.translate(),
        react_sortable_hoc_1.SortableContainer
    ], RenderEductaionsSortable);
    return RenderEductaionsSortable;
}(React.Component));
exports.default = RenderEductaionsSortable;
