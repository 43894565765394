"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AgeRangeHelper = /** @class */ (function () {
    function AgeRangeHelper() {
    }
    AgeRangeHelper.getOptions = function (ranges) {
        return (ranges || []).map(function (item) {
            return Object.assign(item, {
                label: item.name,
                value: item.id
            });
        });
    };
    return AgeRangeHelper;
}());
exports.default = AgeRangeHelper;
