"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var routes_1 = require("../routes");
var i18n_1 = require("i18n");
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_redux_1 = require("react-redux");
var react_toastify_1 = require("react-toastify");
var store_1 = require("store");
var react_stripe_elements_1 = require("react-stripe-elements");
require("./style.less");
var auth_provider_1 = require("./auth_provider");
/**
 * @class App
 */
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.render = function () {
        var stripeApiKey = process.env.STRIPE_API_KEY || "";
        return (React.createElement(react_i18next_1.I18nextProvider, { i18n: i18n_1.default },
            React.createElement(react_stripe_elements_1.StripeProvider, { apiKey: stripeApiKey },
                React.createElement(react_redux_1.Provider, { store: store_1.default() },
                    React.createElement(auth_provider_1.default, null,
                        React.createElement("div", { className: "page" },
                            React.createElement(routes_1.default, null),
                            React.createElement(react_toastify_1.ToastContainer, null)))))));
    };
    return App;
}(React.Component));
exports.default = App;
