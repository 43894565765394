"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var stripe_1 = require("actions/stripe");
var react_i18next_1 = require("react-i18next");
var queryString = require("query-string");
var react_toastify_1 = require("react-toastify");
var billing_1 = require("constants/billing");
require("./style.less");
var modal_1 = require("../modal");
var overflow_loader_1 = require("../overflow-loader");
var auth_1 = require("../helpers/auth");
var user_1 = require("actions/user");
var StripeConnectButton = /** @class */ (function (_super) {
    __extends(StripeConnectButton, _super);
    function StripeConnectButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isDisconnectModalOpened: false,
            isOverflowLoaderVisible: false
        };
        _this.showDisconnectModal = function () {
            _this.setState({ isDisconnectModalOpened: true });
        };
        _this.hideDisconnectModal = function () {
            _this.setState({ isDisconnectModalOpened: false });
        };
        _this.showOverflowLoader = function () {
            _this.setState({ isOverflowLoaderVisible: true });
        };
        _this.hideOverflowLoader = function () {
            _this.setState({ isOverflowLoaderVisible: false });
        };
        _this.onStripeConnectHandler = function () {
            if (!auth_1.default.isAuthorized()) {
                react_toastify_1.toast.error(_this.props.t("stripe.connect_failed_message"), {
                    position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true
                });
                _this.props.cleanUserAction();
                return;
            }
            _this.props.stripeOauthLoginAction();
            _this.showOverflowLoader();
        };
        _this.onStripeDisconnectHandler = function () {
            var t = _this.props.t;
            _this.props.stripeDisconnectAction()
                .then(function (result) {
                if (result.type === billing_1.BILLING_CONST.STRIPE_DISCONNECT_SUCCESS) {
                    react_toastify_1.toast.success(t("stripe.disconnect_modal.on_success_message"), {
                        position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true
                    });
                    _this.hideDisconnectModal();
                }
                else {
                    react_toastify_1.toast.error(t("stripe.disconnect_modal.on_fail_message"), {
                        position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true
                    });
                }
            })
                .catch(function () {
                react_toastify_1.toast.error(t("add_session_form.notifications.edit_success"), {
                    position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true
                });
            });
        };
        _this.isAfterStripeRedirect = function (queryObj) {
            return queryObj.code && queryObj.state; // && queryObj.scope;
        };
        _this.makeQueryForLoginAction = function (queryObj) {
            return {
                code: queryObj.code,
                state: queryObj.state
                // scope: queryObj.scope
            };
        };
        return _this;
    }
    StripeConnectButton.prototype.render = function () {
        var _a = this.props, t = _a.t, stripe = _a.stripe;
        var _b = this.state, isDisconnectModalOpened = _b.isDisconnectModalOpened, isOverflowLoaderVisible = _b.isOverflowLoaderVisible;
        return t ? (React.createElement(React.Fragment, null,
            stripe.is_connected === false && (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "user-detail-hint" }, t("user_details_form.hints.billing")),
                React.createElement("a", { className: "stripe-connect", onClick: this.onStripeConnectHandler },
                    React.createElement("span", null, t("stripe.connect_button"))))),
            stripe.is_connected && (React.createElement("a", { className: "stripe-connect light-blue", onClick: this.showDisconnectModal },
                React.createElement("span", null, t("stripe.disconnect_button")))),
            stripe.conenct_error && (React.createElement("span", { className: "error error-block" },
                " ",
                stripe.conenct_error,
                " ")),
            React.createElement(modal_1.default, { onSubmit: this.onStripeDisconnectHandler, onCancel: this.hideDisconnectModal, isModalOpened: isDisconnectModalOpened, textPath: "stripe.disconnect_modal.text", titlePath: "stripe.disconnect_modal.title", submitTextPath: "stripe.disconnect_modal.submit" }),
            React.createElement(overflow_loader_1.default, { isLoaderVisible: isOverflowLoaderVisible }))) : null;
    };
    StripeConnectButton.prototype.componentWillMount = function () {
        var _this = this;
        var t = this.props.t;
        var query = window.location.hash.split("?");
        var queryObj = queryString.parse(query[1]);
        if (this.isAfterStripeRedirect(queryObj)) {
            this.showOverflowLoader();
            this.props.acceptStripeOauthAction(this.makeQueryForLoginAction(queryObj))
                .then(function () {
                _this.hideOverflowLoader();
                react_toastify_1.toast.success(t("stripe.connect_modal.on_success_message"), {
                    position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true
                });
            })
                .catch(function () {
                _this.hideOverflowLoader();
                react_toastify_1.toast.success(t("stripe.connect_modal.on_fail_message"), {
                    position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true
                });
            });
            window.location.href = query[0];
        }
        else {
            this.props.isStripeConnectedAction();
        }
    };
    StripeConnectButton = __decorate([
        react_redux_1.connect(function (state) {
            return {
                stripe: state.billing.stripe
            };
        }, function (dispatch) {
            return {
                stripeOauthLoginAction: redux_1.bindActionCreators(stripe_1.stripeOauthLogin, dispatch),
                acceptStripeOauthAction: redux_1.bindActionCreators(stripe_1.acceptStripeOauth, dispatch),
                isStripeConnectedAction: redux_1.bindActionCreators(stripe_1.isStripeConnected, dispatch),
                stripeDisconnectAction: redux_1.bindActionCreators(stripe_1.stripeDisconnect, dispatch),
                cleanUserAction: redux_1.bindActionCreators(user_1.cleanUser, dispatch)
            };
        }),
        react_i18next_1.translate()
    ], StripeConnectButton);
    return StripeConnectButton;
}(React.Component));
exports.default = StripeConnectButton;
