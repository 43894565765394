"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var redux_form_1 = require("redux-form");
var lodash_1 = require("lodash");
var _1 = require(".");
var clients_1 = require("actions/clients");
var lists_1 = require("actions/lists");
var wrapper_1 = require("components/table/wrapper");
var ClientsTableContainer = /** @class */ (function (_super) {
    __extends(ClientsTableContainer, _super);
    function ClientsTableContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getClientListActionWrapper = function (params, entity) {
            var _a = _this.props, managerId = _a.managerId, providerId = _a.providerId, currentUserRole = _a.currentUserRole, userId = _a.userId;
            // It need because this container is using for clients table on provider page
            if (managerId) {
                params.manager = managerId;
            }
            else {
                // it need for case when admin changed to all clients after only his clients list
                delete params.manager;
            }
            if (providerId || currentUserRole === 'service_provider') {
                params.service_provider = providerId || userId;
            }
            _this.props.getClientListAction(params, entity);
        };
        return _this;
    }
    ClientsTableContainer.prototype.render = function () {
        var _a = this.props, children = _a.children, entity = _a.entity, providerId = _a.providerId, managerId = _a.managerId, isFilterEmpty = _a.isFilterEmpty, isNeedUpdateOnMount = _a.isNeedUpdateOnMount, currentUserRole = _a.currentUserRole;
        var showProvider = !providerId;
        var showManager = !managerId;
        return (React.createElement("div", { className: "container" },
            React.createElement(wrapper_1.default, { entity: entity, updateTable: this.getClientListActionWrapper, withPagination: true, isNeedUpdateOnMount: isNeedUpdateOnMount, filtrationFormName: "clientFilter" }, function (tableProps) { return (React.createElement(_1.default, __assign({ children: children, currentUserRole: currentUserRole, showManager: showManager, showProvider: showProvider, isFilterEmpty: isFilterEmpty }, tableProps))); })));
    };
    return ClientsTableContainer;
}(React.Component));
exports.default = react_redux_1.connect(function (state) { return ({
    userId: state.user.my.kind_id,
    currentUserRole: state.user.my ? state.user.my.role : null,
    isFilterEmpty: lodash_1.isEmpty(redux_form_1.getFormValues("clientFilter")(state))
}); }, function (dispatch) { return ({
    cleanClientsPreviousFiltersAction: redux_1.bindActionCreators(lists_1.cleanPreviousFilters, dispatch),
    getClientListAction: redux_1.bindActionCreators(clients_1.getClientList, dispatch)
}); })(ClientsTableContainer);
