"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var react_select_1 = require("react-select");
var classnames = require("classnames");
require("./style.less");
exports.EmailConfirmationPopupContent = function (_a) {
    var t = _a.t, sendEmail = _a.sendEmail, validators = _a.validators, options = _a.options, onChangeEmail = _a.onChangeEmail, selectedEmail = _a.selectedEmail, sendEmailError = _a.sendEmailError;
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, t("invoice_view.check_email")),
        React.createElement("form", null,
            React.createElement("div", { className: "field" },
                React.createElement(react_select_1.Creatable, { onChange: onChangeEmail, isValidNewOption: function (option) {
                        return (!validators.required(option.label) &&
                            !validators.email(option.label));
                    }, value: selectedEmail, promptTextCreator: function (label) {
                        return t("invoice_view.new_email", { label: label });
                    }, placeholder: t("invoice_view.new_email_placeholder"), className: classnames({ "select-error": !!sendEmailError }), options: options }),
                sendEmailError && React.createElement("span", { className: "error" }, sendEmailError)),
            React.createElement("button", { className: "button button__red", onClick: sendEmail }, t("invoice_view.actions.send")))));
};
exports.CancelConfirmationPopupContent = function (_a) {
    var t = _a.t, closeModal = _a.closeModal, cancelInvoice = _a.cancelInvoice;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "warning" }, t("invoice_view.cancel_notify")),
        React.createElement("button", { className: "button button__cancel half-width", onClick: closeModal }, t("invoice_view.actions.back")),
        React.createElement("button", { className: "button button__red half-width", onClick: cancelInvoice }, t("invoice_view.actions.confirm"))));
};
exports.ConnectStripeWarningPopupContent = function (_a) {
    var t = _a.t, closeModal = _a.closeModal;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "warning" }, t("invoice_view.connect_stripe_notify")),
        React.createElement("button", { className: "button button__cancel half-width", onClick: closeModal }, t("invoice_view.actions.back")),
        React.createElement(react_router_dom_1.Link, { to: "/user_details", className: "button button__red half-width" }, t("invoice_view.actions.connect_stripe"))));
};
exports.PaidConfirmationPopupContent = function (_a) {
    var closeModal = _a.closeModal, markAsPaid = _a.markAsPaid, t = _a.t;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "warning" }, t("invoice_view.pending_notify")),
        React.createElement("button", { className: "button button__cancel half-width", onClick: closeModal }, t("action_panel.buttons.cancel")),
        React.createElement("button", { className: "button button__red half-width", onClick: markAsPaid }, t("invoice_view.actions.mark_as_paid"))));
};
