"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var client_types_contacts_1 = require("./client_types_contacts");
var client_type_stateless_1 = require("./client_type.stateless");
var ClientContacts = /** @class */ (function (_super) {
    __extends(ClientContacts, _super);
    function ClientContacts() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientContacts.prototype.render = function () {
        var _a = this.props, validators = _a.validators, t = _a.t, error = _a.error;
        return (React.createElement(React.Fragment, null,
            React.createElement(client_type_stateless_1.ClientType, { t: t }),
            React.createElement(client_types_contacts_1.default, { validators: validators, t: t, error: error })));
    };
    return ClientContacts;
}(React.Component));
exports.default = ClientContacts;
