"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Wizard = /** @class */ (function (_super) {
    __extends(Wizard, _super);
    function Wizard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            currentIndex: 0
        };
        _this.getCurrentStepConfig = function () {
            var config = _this.props.config;
            var currentIndex = _this.state.currentIndex;
            return config && config[currentIndex];
        };
        _this.findStepIndexById = function (id) {
            return _this.props.config.findIndex(function (stepConfig) { return stepConfig.id === id; });
        };
        _this.onNext = function (stepData) {
            var stepConfig = _this.getCurrentStepConfig();
            var newCurrentStepIndex = _this.findStepIndexById(stepConfig.getNextStepID(stepData));
            _this.setState({ currentIndex: newCurrentStepIndex }, function () {
                _this.onStepChange();
            });
        };
        _this.onBack = function () {
            var stepConfig = _this.getCurrentStepConfig();
            var newCurrentStepIndex = _this.findStepIndexById(stepConfig.getPreviousStepID());
            _this.setState({ currentIndex: newCurrentStepIndex }, function () {
                _this.onStepChange();
            });
        };
        _this.onFirstStep = function () {
            _this.setState({ currentIndex: 0 }, function () {
                _this.onStepChange();
                _this.props.onForceFirstStep();
            });
        };
        _this.onStepChange = function () {
            var onStepChange = _this.props.onStepChange;
            if (onStepChange) {
                _this.props.onStepChange(_this.getCurrentStepConfig().id);
            }
        };
        return _this;
    }
    Wizard.prototype.render = function () {
        var stepConfig = this.getCurrentStepConfig();
        var stepComponent = (stepConfig && stepConfig.component) || null;
        if (React.isValidElement(stepComponent)) {
            stepComponent = React.cloneElement(stepComponent, {
                ref: "stepComponent",
                onNext: this.onNext,
                onBack: this.onBack,
                onFirstStep: this.onFirstStep
            });
        }
        return stepComponent ? React.createElement(React.Fragment, null, stepComponent) : null;
    };
    return Wizard;
}(React.Component));
exports.default = Wizard;
