"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SERVICE_PROVIDER_CONST;
(function (SERVICE_PROVIDER_CONST) {
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_CREATE_SUCCESS"] = "SERVICE_PROVIDER_CREATE_SUCCESS";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_CREATE_FAIL"] = "SERVICE_PROVIDER_CREATE_FAIL";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_UPDATE_SUCCESS"] = "SERVICE_PROVIDER_UPDATE_SUCCESS";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_UPDATE_FAIL"] = "SERVICE_PROVIDER_UPDATE_FAIL";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_GET_WORKSPACE_SUCCESS"] = "SERVICE_PROVIDER_GET_WORKSPACE_SUCCESS";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_GET_WORKSPACE_FAIL"] = "SERVICE_PROVIDER_GET_WORKSPACE_FAIL";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_GET_SUCCESS"] = "SERVICE_PROVIDER_GET_SUCCESS";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_GET_FAIL"] = "SERVICE_PROVIDER_GET_FAIL";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_RESTORE_SUCCESS"] = "SERVICE_PROVIDER_RESTORE_SUCCESS";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_RESTORE_FAIL"] = "SERVICE_PROVIDER_RESTORE_FAIL";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_ARCHIVE_SUCCESS"] = "SERVICE_PROVIDER_ARCHIVE_SUCCESS";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_ARCHIVE_FAIL"] = "SERVICE_PROVIDER_ARCHIVE_FAIL";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_SWITCH_BILLING_SUCCESS"] = "SERVICE_PROVIDER_SWITCH_BILLING_SUCCESS";
    SERVICE_PROVIDER_CONST["SERVICE_PROVIDER_SWITCH_BILLING_FAIL"] = "SERVICE_PROVIDER_SWITCH_BILLING_FAIL";
})(SERVICE_PROVIDER_CONST = exports.SERVICE_PROVIDER_CONST || (exports.SERVICE_PROVIDER_CONST = {}));
