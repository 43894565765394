"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("config/api");
var params_1 = require("./helpers/params");
var errors_herlper_actions_1 = require("actions/errors_herlper_actions");
// TODO refactoring methods, a lot of copypast here
var UserApi = /** @class */ (function () {
    function UserApi() {
    }
    UserApi.login = function (user) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "user/sign_in", __assign({}, params_1.getDefaultParams(true), { body: JSON.stringify({
                user: user
            }), method: "POST" }));
    };
    UserApi.checkEmail = function (data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "users/password", __assign({}, params_1.getDefaultParams(true), { body: JSON.stringify(data), method: "POST" }));
    };
    UserApi.resetPassword = function (data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "users/password", __assign({}, params_1.getDefaultParams(true), { body: JSON.stringify(data), method: "PUT" }));
    };
    UserApi.getMyUser = function () {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "user", __assign({}, params_1.getDefaultParams(), { method: "GET" }));
    };
    UserApi.logout = function () {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "user/sign_out", __assign({}, params_1.getDefaultParams(), { method: "DELETE" }));
    };
    UserApi.getManagers = function (client_id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "users/available_managers/" + client_id, __assign({}, params_1.getDefaultParams(), { method: "GET" }));
    };
    UserApi.getManagersList = function () {
        return fetch(api_1.default.HOST + "users/managers", __assign({}, params_1.getDefaultParams(), { method: "GET" }));
    };
    UserApi.update = function (user) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "user", __assign({}, params_1.getDefaultParams(), { body: JSON.stringify({ user: user }), method: "PUT" }));
    };
    return UserApi;
}());
exports.UserApi = UserApi;
