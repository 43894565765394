"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var schedule_day_1 = require("./schedule_day");
var prefered_time_1 = require("helpers/prefered_time");
require("./style.less");
var SchedulePreferences = /** @class */ (function (_super) {
    __extends(SchedulePreferences, _super);
    function SchedulePreferences() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SchedulePreferences.prototype.render = function () {
        var _a = this.props, daysSelected = _a.daysSelected, changeDayValue = _a.changeDayValue, dayPrefix = _a.dayPrefix, editable = _a.editable;
        return (React.createElement("ul", { className: "schedule_preference" }, prefered_time_1.daysOfWeek.map(function (day) { return (React.createElement(schedule_day_1.default, { changeDayValue: changeDayValue, day: day, dayPrefix: dayPrefix, key: day, initialDayValues: daysSelected[day], editable: editable })); })));
    };
    return SchedulePreferences;
}(React.Component));
exports.default = SchedulePreferences;
