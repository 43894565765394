"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var service_provider_1 = require("actions/service_provider");
var lists_1 = require("actions/lists");
var _1 = require(".");
var _ = require("lodash");
var redux_form_1 = require("redux-form");
var lists_2 = require("constants/lists");
var reducer_1 = require("reducers/lists/reducer");
var entity = lists_2.LIST_ENTITIES.ALL_PROVIDERS_PAGINATED_LIST;
var dayPrefix = "available_periods.days";
var ProvidersFilterContainer = /** @class */ (function (_super) {
    __extends(ProvidersFilterContainer, _super);
    function ProvidersFilterContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            initialValues: {}
        };
        _this.updateTable = function (params) {
            if (params === void 0) { params = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var getServiceProvidersListAction;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            getServiceProvidersListAction = this.props.getServiceProvidersListAction;
                            if (!getServiceProvidersListAction) return [3 /*break*/, 2];
                            return [4 /*yield*/, getServiceProvidersListAction(params, entity)];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        };
        _this.isValueExist = function (formValue, key) {
            return (formValue[key] && formValue[key].id) ||
                (formValue[key] && formValue[key].length);
        };
        return _this;
    }
    ProvidersFilterContainer.prototype.render = function () {
        var _this = this;
        var _a = this.props, workspace = _a.workspace, values = _a.values, changeDayValue = _a.changeDayValue, daysSelected = _a.daysSelected;
        var searchValues = values &&
            [
                "experience_range",
                "gender",
                "expertises",
                "techniques",
                "prefered_location",
                "personalities"
            ].some(function (key) { return _this.isValueExist(values, key); });
        return (React.createElement(_1.default, { fetchProviders: this.updateTable, initialValues: this.state.initialValues, searchValues: searchValues, workspace: workspace, changeDayValue: changeDayValue, dayPrefix: dayPrefix, daysSelected: daysSelected }));
    };
    ProvidersFilterContainer.prototype.componentDidMount = function () {
        var _a = this.props, getServiceProviderWorkspaceAction = _a.getServiceProviderWorkspaceAction, disablePreviousFiltersAction = _a.disablePreviousFiltersAction, tableQuery = _a.tableQuery, tableFilters = _a.tableFilters;
        if (getServiceProviderWorkspaceAction) {
            getServiceProviderWorkspaceAction();
        }
        if (this.props.tableFilters.usePreviousFilters) {
            this.updateInitialValues(tableFilters.previous, tableQuery, true);
            disablePreviousFiltersAction(entity);
        }
        else {
            this.fetchProvidersWithDefaultParams();
        }
    };
    /**
     * @method componentWillReceiveProps
     * It need for handle case when user comes to providers page from other page.
     * User already have got previousFilters.
     * When he will click on reset bitton we should reset initial values
     * @param nextProps
     */
    ProvidersFilterContainer.prototype.componentWillReceiveProps = function (nextProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!_.isEqual(this.props.tableFilters, nextProps.tableFilters)) {
                    this.updateInitialValues(nextProps.tableFilters.previous, reducer_1.entityDefaultParams.query, false);
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * @method componentWillUnmount
     * It calls action for save filter form values to redux storage, because we need reestablish
     *  filters after return back from other page
     */
    ProvidersFilterContainer.prototype.componentWillUnmount = function () {
        this.props.saveFiltersAction(this.props.values, entity);
    };
    /**
     * @method updateInitialValues
     * @param previousFilters form values saved on previous componentWillUnmount
     * @param query
     * @param reloadProviders
     */
    ProvidersFilterContainer.prototype.updateInitialValues = function (previousFilters, query, reloadProviders) {
        var _this = this;
        this.setState({
            initialValues: previousFilters
        }, function () {
            if (reloadProviders) {
                _this.updateTable(query);
            }
        });
    };
    ProvidersFilterContainer.prototype.fetchProvidersWithDefaultParams = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tableEntity;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tableEntity = entity;
                        return [4 /*yield*/, this.props.getServiceProvidersListAction(reducer_1.entityDefaultParams.query, tableEntity)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProvidersFilterContainer = __decorate([
        react_redux_1.connect(function (state) {
            var formValues = redux_form_1.getFormValues("providerFilter")(state);
            return {
                values: formValues,
                workspace: state.serviceProvider.workspace,
                tableQuery: state.lists[entity].query,
                tableFilters: state.lists[entity].filters,
                daysSelected: _.get(formValues, dayPrefix, {})
            };
        }, function (dispatch) {
            return {
                getServiceProviderWorkspaceAction: redux_1.bindActionCreators(service_provider_1.getServiceProviderWorkspace, dispatch),
                getServiceProvidersListAction: redux_1.bindActionCreators(service_provider_1.getServiceProvidersList, dispatch),
                disablePreviousFiltersAction: redux_1.bindActionCreators(lists_1.disablePreviousFilters, dispatch),
                saveFiltersAction: redux_1.bindActionCreators(lists_1.saveFilters, dispatch),
                changeDayValue: function (day, value) {
                    return dispatch(redux_form_1.change("providerFilter", dayPrefix + "[" + day + "]", value));
                }
            };
        })
    ], ProvidersFilterContainer);
    return ProvidersFilterContainer;
}(React.Component));
exports.default = ProvidersFilterContainer;
