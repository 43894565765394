"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var settings_icon_1 = require("../svg_icons/settings_icon");
var AdvancedSearchButton = /** @class */ (function (_super) {
    __extends(AdvancedSearchButton, _super);
    function AdvancedSearchButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isValueExist = function (formValue, key) {
            return formValue[key] && formValue[key].id;
        };
        return _this;
    }
    AdvancedSearchButton.prototype.render = function () {
        var _a = this.props, handleOpenDetail = _a.handleOpenDetail, searchValues = _a.searchValues;
        return (React.createElement("a", { className: "advanced-search", onClick: handleOpenDetail },
            React.createElement(settings_icon_1.default, { extendedClass: searchValues ? "icons-svg_settings_selected" : "" })));
    };
    return AdvancedSearchButton;
}(React.Component));
exports.default = AdvancedSearchButton;
