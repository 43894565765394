"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var session_1 = require("constants/session");
var moment = require("moment");
var initData = {
    selectedDate: moment(),
    firstSession: {}
};
function sessionsReducer(state, action) {
    if (state === void 0) { state = initData; }
    switch (action.type) {
        case session_1.SESSION_CONST.SET_SELECTED_DATE:
            return __assign({}, state, { selectedDate: action.payload.date });
        case session_1.SESSION_CONST.GET_FIRST_SESSION_SUCCESS:
            return __assign({}, state, { firstSession: {
                    session: action.payload.session && action.payload.session[0],
                    error: null
                } });
        case session_1.SESSION_CONST.GET_FIRST_SESSION_FAIL:
            return __assign({}, state, { firstSession: {
                    session: null,
                    error: action.payload
                } });
        default:
            return state;
    }
}
exports.default = sessionsReducer;
