"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CLIENT_CONST;
(function (CLIENT_CONST) {
    CLIENT_CONST["CLIENT_CREATE_SUCCESS"] = "CLIENT_CREATE_SUCCESS";
    CLIENT_CONST["CLIENT_CREATE_FAIL"] = "CLIENT_CREATE_FAIL";
    CLIENT_CONST["CLIENT_UPDATE_SUCCESS"] = "CLIENT_UPDATE_SUCCESS";
    CLIENT_CONST["CLIENT_UPDATE_FAIL"] = "CLIENT_UPDATE_FAIL";
    CLIENT_CONST["CLIENT_GET_WORKSPACE_SUCCESS"] = "CLIENT_GET_WORKSPACE_SUCCESS";
    CLIENT_CONST["CLIENT_GET_WORKSPACE_FAIL"] = "CLIENT_GET_WORKSPACE_FAIL";
    CLIENT_CONST["CLIENT_GET_SUCCESS"] = "CLIENT_GET_SUCCESS";
    CLIENT_CONST["CLIENT_GET_FAIL"] = "CLIENT_GET_FAIL";
    CLIENT_CONST["CLIENT_ARCHIVE_SUCCESS"] = "CLIENT_ARCHIVE_SUCCESS";
    CLIENT_CONST["CLIENT_ARCHIVE_FAIL"] = "CLIENT_ARCHIVE_FAIL";
    CLIENT_CONST["CLIENT_RESTORE_SUCCESS"] = "CLIENT_RESTORE_SUCCESS";
    CLIENT_CONST["CLIENT_RESTORE_FAIL"] = "CLIENT_RESTORE_SUCCESS";
    CLIENT_CONST["CLIENT_REASIGN_SUCCESS"] = "CLIENT_REASIGN_SUCCESS";
    CLIENT_CONST["CLIENT_REASIGN_FAIL"] = "CLIENT_REASIGN_FAIL";
    CLIENT_CONST["ASSIGN_CLIENT_TO_PROVIDER_SUCCESS"] = "ASSIGN_CLIENT_TO_PROVIDER_SUCCESS";
    CLIENT_CONST["ASSIGN_CLIENT_TO_PROVIDER_FAIL"] = "ASSIGN_CLIENT_TO_PROVIDER_FAIL";
    CLIENT_CONST["UNASSIGN_CLIENT_FROM_PROVIDER_SUCCESS"] = "UNASSIGN_CLIENT_FROM_PROVIDER_SUCCESS";
    CLIENT_CONST["UNASSIGN_CLIENT_FROM_PROVIDER_FAIL"] = "UNASSIGN_CLIENT_FROM_PROVIDER_FAIL";
})(CLIENT_CONST = exports.CLIENT_CONST || (exports.CLIENT_CONST = {}));
