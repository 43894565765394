"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./style.less");
var classnames = require("classnames");
var Spinner = /** @class */ (function (_super) {
    __extends(Spinner, _super);
    function Spinner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Spinner.prototype.render = function () {
        return (React.createElement("div", { className: classnames({
                spinner: true,
                spinner__inline: !!this.props.inline
            }) },
            React.createElement("div", { className: "sk-cube-grid" },
                React.createElement("div", { className: "sk-cube sk-cube1" }),
                React.createElement("div", { className: "sk-cube sk-cube2" }),
                React.createElement("div", { className: "sk-cube sk-cube3" }),
                React.createElement("div", { className: "sk-cube sk-cube4" }),
                React.createElement("div", { className: "sk-cube sk-cube5" }),
                React.createElement("div", { className: "sk-cube sk-cube6" }),
                React.createElement("div", { className: "sk-cube sk-cube7" }),
                React.createElement("div", { className: "sk-cube sk-cube8" }),
                React.createElement("div", { className: "sk-cube sk-cube9" }))));
    };
    return Spinner;
}(React.Component));
exports.default = Spinner;
