"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_1 = require("redux");
var react_redux_1 = require("react-redux");
var redux_form_1 = require("redux-form");
var lodash_1 = require("lodash");
var wrapper_1 = require("components/table/wrapper");
var _1 = require(".");
var invoice_1 = require("actions/invoice");
var lists_1 = require("constants/lists");
var InvoicesTableContainer = /** @class */ (function (_super) {
    __extends(InvoicesTableContainer, _super);
    function InvoicesTableContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InvoicesTableContainer.prototype.render = function () {
        var _a = this.props, getInvoicesListAction = _a.getInvoicesListAction, isFilterEmpty = _a.isFilterEmpty, currentUserRole = _a.currentUserRole;
        return (React.createElement(wrapper_1.default, { entity: lists_1.LIST_ENTITIES.ALL_INVOICES_LIST, updateTable: getInvoicesListAction, withPagination: true, filtrationFormName: "invoicesFilter", isNeedUpdateOnMount: true, initialSort: "created_at_desc" }, function (tableProps) { return (React.createElement(_1.default, __assign({ isFilterEmpty: isFilterEmpty, currentUserRole: currentUserRole }, tableProps))); }));
    };
    return InvoicesTableContainer;
}(React.Component));
exports.default = react_redux_1.connect(function (state) { return ({
    isFilterEmpty: lodash_1.isEmpty(redux_form_1.getFormValues("invoicesFilter")(state)),
    currentUserRole: state.user.my && state.user.my.role
}); }, function (dispatch) { return ({
    getInvoicesListAction: redux_1.bindActionCreators(invoice_1.getInvoicesList, dispatch)
}); })(InvoicesTableContainer);
