"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var react_i18next_1 = require("react-i18next");
var react_toastify_1 = require("react-toastify");
var react_router_1 = require("react-router");
var redux_form_1 = require("redux-form");
require("./style.less");
var validators_1 = require("components/form/wrapper/validators");
var action_panel_1 = require("../action_panel");
var image_uploader_1 = require("components/image_uploader");
var fee_range_1 = require("helpers/fee_range");
var page_title_1 = require("components/page_title");
var auth_1 = require("components/helpers/auth");
var form_fields_stateless_1 = require("./form-fields.stateless");
var sp_fee_setting = "sp_fee_setting";
var manager_fee_setting = "manager_fee_setting";
var ClientAddEditForm = /** @class */ (function (_super) {
    __extends(ClientAddEditForm, _super);
    function ClientAddEditForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            image: null,
            imageRemoved: false
        };
        _this.getTitle = function () {
            var _a = _this.props, id = _a.id, initialValues = _a.initialValues;
            return id && initialValues
                ? initialValues.first_name + " " + initialValues.last_name
                : undefined;
        };
        _this.getSubTitle = function () {
            var _a = _this.props, id = _a.id, initialValues = _a.initialValues;
            if (id &&
                initialValues &&
                initialValues.educations_attributes &&
                initialValues.educations_attributes.length > 0) {
                var educations = initialValues.educations_attributes;
                if (educations[0] && educations[0].degree && educations[0].degree.title) {
                    return educations[0].degree.title;
                }
            }
            return undefined;
        };
        _this.getSubmitText = function () {
            var _a = _this.props, id = _a.id, t = _a.t;
            return t
                ? id
                    ? t("action_panel.buttons.update")
                    : t("action_panel.buttons.save")
                : null;
        };
        _this.getImageSrc = function () {
            if (_this.state.imageRemoved)
                return "";
            if (_this.state.image)
                return _this.state.image.data;
            else
                return _this.props.initialValues && _this.props.initialValues.image;
        };
        _this.redirectToView = function (newId) {
            var _a = _this.props, history = _a.history, id = _a.id;
            if (history) {
                if (newId) {
                    history.push("/clients/view/" + newId);
                }
                else if (id) {
                    history.push("/clients/view/" + id);
                }
                else {
                    history.push("/clients");
                }
            }
        };
        _this.changeImage = function (base64, fileName, removed) {
            _this.setState({
                image: { data: base64, filename: fileName },
                imageRemoved: removed
            });
        };
        _this.handleSubmit = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var id, preparedValues;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = this.props.id;
                        preparedValues = __assign({}, this.prepareValues(values), { image: this.state.image });
                        if (!!id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.handleSubmitAsCreateForm(preparedValues)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.handleSubmitAsEditForm(preparedValues)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleSubmitAsCreateForm = function (preparedValues) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onCreate, t, result, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onCreate = _a.onCreate, t = _a.t;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, onCreate(preparedValues)];
                    case 2:
                        result = _b.sent();
                        react_toastify_1.toast.success(t("client_add_form.create_success"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        this.redirectToView(result.client.id);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        react_toastify_1.toast.error(t("client_add_form.create_error"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        throw new redux_form_1.SubmissionError(this.prepareError(error_1));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleSubmitAsEditForm = function (preparedValues) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onUpdate, id, t, onUpdateClient, result, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onUpdate = _a.onUpdate, id = _a.id, t = _a.t, onUpdateClient = _a.onUpdateClient;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, onUpdate(id, preparedValues)];
                    case 2:
                        result = _b.sent();
                        react_toastify_1.toast.success(t("client_add_form.update_success"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        onUpdateClient(result);
                        this.redirectToView();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _b.sent();
                        react_toastify_1.toast.error(t("client_add_form.update_error"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        throw new redux_form_1.SubmissionError(this.prepareError(error_2));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.prepareError = function (error) {
            if (typeof error === "string") {
                return { _error: error };
            }
            return error;
        };
        _this.getSide = function () {
            var workspace = _this.props.workspace;
            if (workspace) {
                var sides = workspace.sides;
                return sides.map(function (item) {
                    return Object.assign(item, {
                        label: item.title,
                        value: item.id
                    });
                });
            }
            return [];
        };
        _this.getGender = function () {
            var workspace = _this.props.workspace;
            if (workspace) {
                return workspace.genders
                    .map(function (item) {
                    return Object.assign(item, {
                        label: item.title,
                        value: item.id
                    });
                })
                    .filter(function (option) { return option.name !== "no_preference"; });
            }
            return [];
        };
        _this.getManagers = function () {
            var managersList = _this.props.managersList;
            if (managersList) {
                return managersList.map(function (item) {
                    return Object.assign(item, {
                        label: item.first_name + " " + item.last_name,
                        value: item.id
                    });
                });
            }
            return [];
        };
        _this.convertSafetyToNumber = function (object, key) {
            if (object && object[key] && typeof object[key] === "string") {
                var newValue = parseFloat(object[key]);
                if (!isNaN(newValue)) {
                    object[key] = newValue;
                }
            }
        };
        return _this;
    }
    ClientAddEditForm.prototype.render = function () {
        var _this = this;
        var _a = this.props, t = _a.t, handleSubmit = _a.handleSubmit, pristine = _a.pristine, submitting = _a.submitting, valid = _a.valid, error = _a.error, workspace = _a.workspace, validators = _a.validators, initialValues = _a.initialValues;
        var isSubmit = (!!this.state.image && valid) || !(pristine || submitting || !valid);
        var initialSpFeeSettings = initialValues &&
            initialValues.preference_attributes &&
            initialValues.preference_attributes.sp_fee_setting_attributes;
        var initialManagerFeeSettings = initialValues &&
            initialValues.preference_attributes &&
            initialValues.preference_attributes.manager_fee_setting_attributes;
        var pageTitle = this.props.id
            ? initialValues.first_name + " " + initialValues.last_name
            : t("page_titles.add_new_client");
        return t && validators && workspace ? (React.createElement(redux_form_1.Form, { className: "add-edit-form", onSubmit: handleSubmit(this.handleSubmit) },
            React.createElement(action_panel_1.default, { title: this.getTitle(), subTitle: this.getSubTitle(), onSubmit: handleSubmit(this.handleSubmit), onCancel: function () { return _this.redirectToView(); }, submitButtonText: this.getSubmitText(), isSubmit: isSubmit }),
            React.createElement("div", { className: "center-content" },
                React.createElement("div", { className: "left" }, !auth_1.default.isAuthorizedAsServiceProvider() && (React.createElement(image_uploader_1.default, { currentImg: this.getImageSrc(), imageName: initialValues.image_file_name, changeImage: this.changeImage }))),
                React.createElement("div", { className: "right" },
                    React.createElement("div", { className: "right-content with-dynamic-block" },
                        React.createElement(page_title_1.default, { pageTitle: pageTitle }),
                        React.createElement(form_fields_stateless_1.EditClientFormFields, __assign({ getSide: this.getSide, getGender: this.getGender, getManagers: this.getManagers, initialManagerFeeSettings: initialManagerFeeSettings, initialSpFeeSettings: initialSpFeeSettings, sp_fee_setting: sp_fee_setting, manager_fee_setting: manager_fee_setting }, this.props)),
                        error && typeof error === "string" && (React.createElement("div", { className: "form-base-error error" }, error))))),
            React.createElement(action_panel_1.default, { title: this.getTitle(), subTitle: this.getSubTitle(), onSubmit: handleSubmit(this.handleSubmit), onCancel: function () { return _this.redirectToView(); }, submitButtonText: this.getSubmitText(), isSubmit: isSubmit }))) : null;
    };
    ClientAddEditForm.prototype.prepareValues = function (sourceValues) {
        var values = _.cloneDeep(sourceValues);
        if (values.expertises) {
            values.expertise_ids = values.expertises.map(function (item) {
                return item.id;
            });
        }
        if (values.session_duration && values.session_duration.id) {
            values.session_duration = values.session_duration.id;
        }
        if (values.preference_attributes) {
            var initialValues = this.props.initialValues;
            var attributes = values.preference_attributes;
            if (initialValues &&
                initialValues.preference_attributes &&
                initialValues.preference_attributes.id) {
                attributes.id = initialValues.preference_attributes.id;
            }
            if (values.preference_attributes.side) {
                attributes.side_id = attributes.side.id;
            }
            if (attributes.gender) {
                attributes.gender_id = attributes.gender.id;
            }
            if (attributes.fee_range && attributes.fee_range.id !== 0) {
                attributes.fee_range_id = attributes.fee_range.id;
            }
            if (initialValues &&
                initialValues.preference_attributes.sp_fee_setting_attributes) {
                attributes.sp_fee_setting_attributes.id =
                    initialValues.preference_attributes.sp_fee_setting_attributes.id;
            }
            if (initialValues &&
                initialValues.preference_attributes.manager_fee_setting_attributes) {
                attributes.manager_fee_setting_attributes.id =
                    initialValues.preference_attributes.manager_fee_setting_attributes.id;
            }
            var supervisorPercent = attributes.manager_fee_setting_attributes.mediator_percent;
            if (supervisorPercent && supervisorPercent.id) {
                attributes.manager_fee_setting_attributes.mediator_percent = supervisorPercent.id;
            }
            var tutorPercent = attributes.sp_fee_setting_attributes.mediator_percent;
            if (tutorPercent && tutorPercent.id) {
                attributes.sp_fee_setting_attributes.mediator_percent = tutorPercent.id;
            }
            var supervisorNextPercent = attributes.manager_fee_setting_attributes.next_planned_percent;
            if (supervisorNextPercent && supervisorNextPercent.id) {
                attributes.manager_fee_setting_attributes.next_planned_percent = supervisorNextPercent.id;
            }
            var tutorNextPercent = attributes.sp_fee_setting_attributes.next_planned_percent;
            if (tutorNextPercent && tutorNextPercent.id) {
                attributes.sp_fee_setting_attributes.next_planned_percent = tutorNextPercent.id;
            }
            this.convertSafetyToNumber(attributes.manager_fee_setting_attributes, "mediator_percent");
            this.convertSafetyToNumber(attributes.sp_fee_setting_attributes, "mediator_percent");
            this.convertSafetyToNumber(attributes, "preferred_fee");
            this.convertSafetyToNumber(attributes, "preferred_online_fee");
        }
        if (values.preference_attributes &&
            values.preference_attributes.sp_fee_setting_attributes) {
            var sp_fee_settings = values.preference_attributes.sp_fee_setting_attributes;
            if (auth_1.default.isAuthorizedAsManager()) {
                sp_fee_settings.mediator_percent = parseFloat(sp_fee_settings.mediator_percent);
                if (sp_fee_settings.next_planned_percent) {
                    sp_fee_settings.next_planned_percent = parseFloat(sp_fee_settings.next_planned_percent);
                }
            }
            else {
                if (!values.sp_fee_setting_checkbox) {
                    sp_fee_settings.next_planned_percent = null;
                    sp_fee_settings.date_change = null;
                }
            }
            delete values.sp_fee_setting_checkbox;
        }
        if (values.preference_attributes &&
            values.preference_attributes.manager_fee_setting_attributes &&
            !values.manager_fee_setting_checkbox) {
            values.preference_attributes.manager_fee_setting_attributes.next_planned_percent = null;
            values.preference_attributes.manager_fee_setting_attributes.date_change = null;
        }
        delete values.manager_fee_setting_checkbox;
        if (values.intake_manager) {
            values.intake_manager_id = values.intake_manager.id;
            delete values.intake_manager;
        }
        if (values.gender) {
            values.gender = values.gender.id;
        }
        if (values.parents) {
            _.each(values.parents, function (parent) {
                parent.dependent = true;
            });
        }
        if (values.client_type === "adult") {
            values.contacts = [values.contact];
        }
        if (values.client_type === "child") {
            values.contacts = values.parents;
        }
        delete values.parents;
        delete values.contact;
        delete values.client_type;
        return values;
    };
    ClientAddEditForm = __decorate([
        validators_1.default,
        react_router_1.withRouter,
        redux_form_1.reduxForm({
            form: "client_edit",
            enableReinitialize: true,
            keepDirtyOnReinitialize: true,
            onChange: function (values, dispatch, props) {
                if (props.pristine) {
                    return;
                }
                /**
                 * Actual redux-form typings doesn't contain previousValues param
                 */
                var previousValues = arguments[3];
                fee_range_1.default.handleFeeChanging(values, previousValues, props.workspace, dispatch, "client_edit", false);
            }
        }),
        react_i18next_1.translate()
    ], ClientAddEditForm);
    return ClientAddEditForm;
}(React.Component));
exports.default = ClientAddEditForm;
