"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./style.less");
var main_1 = require("layouts/main");
var private_1 = require("components/helpers/user/private");
var react_router_1 = require("react-router");
var container_1 = require("./item/container");
var ClientViewPage = /** @class */ (function (_super) {
    __extends(ClientViewPage, _super);
    function ClientViewPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientViewPage.prototype.render = function () {
        var match = this.props.match;
        return (match && (React.createElement(main_1.default, null,
            React.createElement(container_1.default, { id: match.params.id ? parseInt(match.params.id, 10) : null }))));
    };
    ClientViewPage = __decorate([
        private_1.default,
        react_router_1.withRouter
    ], ClientViewPage);
    return ClientViewPage;
}(React.Component));
exports.default = ClientViewPage;
