"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _a;
var INVOICE_STATUSES;
(function (INVOICE_STATUSES) {
    INVOICE_STATUSES["DRAFT"] = "draft";
    INVOICE_STATUSES["SENT"] = "sent";
    INVOICE_STATUSES["PAID"] = "paid";
    INVOICE_STATUSES["MANUALLY_PAID"] = "manually_paid";
    INVOICE_STATUSES["SPLIT"] = "split";
    INVOICE_STATUSES["CANCELED"] = "canceled";
    INVOICE_STATUSES["REJECTED"] = "rejected";
    INVOICE_STATUSES["IN_PROGRESS"] = "in_progress";
    INVOICE_STATUSES["ACH_VERIFICATION"] = "ach_verification";
})(INVOICE_STATUSES = exports.INVOICE_STATUSES || (exports.INVOICE_STATUSES = {}));
exports.INVOICE_STATUSES_NAMES = (_a = {},
    _a[INVOICE_STATUSES.DRAFT] = [INVOICE_STATUSES.DRAFT],
    _a[INVOICE_STATUSES.SENT] = [INVOICE_STATUSES.SENT],
    _a[INVOICE_STATUSES.PAID] = [INVOICE_STATUSES.PAID],
    _a[INVOICE_STATUSES.MANUALLY_PAID] = [INVOICE_STATUSES.MANUALLY_PAID],
    _a[INVOICE_STATUSES.SPLIT] = [INVOICE_STATUSES.SPLIT],
    _a[INVOICE_STATUSES.CANCELED] = [INVOICE_STATUSES.CANCELED],
    _a[INVOICE_STATUSES.REJECTED] = [INVOICE_STATUSES.REJECTED],
    _a[INVOICE_STATUSES.IN_PROGRESS] = [INVOICE_STATUSES.IN_PROGRESS],
    _a[INVOICE_STATUSES.ACH_VERIFICATION] = 'In ACH verification',
    _a);
