"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var TableFooter = /** @class */ (function (_super) {
    __extends(TableFooter, _super);
    function TableFooter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TableFooter.prototype.render = function () {
        var _a = this.props, summ = _a.summ, addItem = _a.addItem, t = _a.t, isAdminOrManager = _a.isAdminOrManager, userIsTutor = _a.userIsTutor;
        var cellWidth = function () {
            if (isAdminOrManager) {
                return 3;
            }
            if (userIsTutor) {
                return 2;
            }
            return 1;
        };
        return (React.createElement("tfoot", { className: "invoice-table_footer" },
            React.createElement("tr", { className: "not-selectable" },
                React.createElement("td", { colSpan: isAdminOrManager ? 7 : 5, className: "table__separator" })),
            React.createElement("tr", { className: "not-selectable" },
                React.createElement("td", { colSpan: cellWidth() }, addItem && (React.createElement("button", { className: "button button__transparent", type: "button", onClick: addItem }, t("invoice_edit.add_more")))),
                React.createElement("td", { className: "invoice-table__total-amount invoice-table__total-amount_title", colSpan: 2 }, t("invoice_edit.amount_due")),
                React.createElement("td", { className: "invoice-table__total-amount invoice-table__total-amount_summ", colSpan: 2 },
                    "$",
                    summ.toFixed(2)))));
    };
    return TableFooter;
}(React.Component));
exports.default = react_i18next_1.translate()(TableFooter);
