"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_1 = require("constants/user");
var auth_1 = require("components/helpers/auth");
function myUser(state, action) {
    if (state === void 0) { state = null; }
    switch (action.type) {
        case user_1.USER_CONST.GET_MY_USER_FAIL:
            return null;
        case user_1.USER_CONST.GET_MY_USER_SUCCESS:
            return Object.assign({}, action.payload);
        case user_1.USER_CONST.UPDATE_SUCCESS:
            return Object.assign({}, state, action.payload);
        case user_1.USER_CONST.FORCE_LOGOUT:
            auth_1.default.logout();
            return state;
        default:
            return state;
    }
}
exports.default = myUser;
