"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./style.less");
var item_1 = require("./item");
var Nav = /** @class */ (function (_super) {
    __extends(Nav, _super);
    function Nav() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Nav.prototype.render = function () {
        var _a = this.props, items = _a.items, extendClassName = _a.extendClassName;
        return (React.createElement("nav", null,
            React.createElement("ul", { className: extendClassName || "" }, items.map(function (item, key) { return (React.createElement(item_1.default, { item: item, key: key })); }))));
    };
    return Nav;
}(React.Component));
exports.default = Nav;
