"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    city: {
        maxLength: 30
    },
    state: {
        maxLength: 10
    },
    street_address: {
        maxLength: 200
    },
    first_name: {
        maxLength: 60
    },
    last_name: {
        maxLength: 60
    },
    about: {
        maxLength: 500
    },
    preferred_fee: {
        maxLength: 9
    },
    preferred_online_fee: {
        maxLength: 9
    },
    prefered_location: {
        maxLength: 60
    },
    schedule_preference: {
        maxLength: 500
    },
    school: {
        maxLength: 200
    },
    problem: {
        maxLength: 1000
    },
    amount_verification: {
        maxLength: 5
    },
    account_holder: {
        maxLength: 64
    },
    routing_number: {
        maxLength: 9
    },
    account_number: {
        maxLength: 17
    },
    bank_account: {
        maxLength: 17
    }
};
