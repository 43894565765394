"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var prefered_time_1 = require("helpers/prefered_time");
var lodash_1 = require("lodash");
var fee_range_1 = require("helpers/fee_range");
var api_communication_adapter_1 = require("models/adapters/api-communication-adapter");
var ServiceProviderApiAdapter = /** @class */ (function (_super) {
    __extends(ServiceProviderApiAdapter, _super);
    function ServiceProviderApiAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.prepareEducations = function (provider) {
            provider.educations_attributes.forEach(function (item) {
                if (item.degree) {
                    item.degree_id = item.degree.id !== "" ? item.degree.id : null;
                }
                delete item.degree;
                _this.emptyStringToNull(item, "graduate_year");
                _this.emptyStringToNull(item, "school");
            });
            provider.educations_attributes = provider.educations_attributes.filter(function (education) {
                return education.graduate_year || education.degree_id || education.school
                    ? education
                    : null;
            });
        };
        return _this;
    }
    ServiceProviderApiAdapter.prototype.convertResponse = function (provider) {
        var preferences = provider.preference_attributes;
        if (preferences && !preferences.fee_range) {
            provider.preference_attributes.fee_range = fee_range_1.default.unspecifiedOption;
        }
        var days = preferences.available_time_slots_attributes.days;
        preferences.available_time_slots_attributes.days = prefered_time_1.prepareScheduleValuesFromGet(days);
        return provider;
    };
    ServiceProviderApiAdapter.prototype.prepareRequest = function (sourceProvider) {
        var provider = lodash_1.cloneDeep(sourceProvider);
        this.prepareEducations(provider);
        this.prepareTag(provider, "expertises", "expertise_ids");
        this.prepareTag(provider, "techniques", "technique_ids");
        this.prepareTag(provider, "personalities", "personality_ids");
        this.prepareSelect(provider, "gender");
        var preference = provider.preference_attributes;
        if (preference) {
            this.prepareTag(preference, "age_ranges", "age_range_ids");
            this.emptyStringToNull(preference, "preferred_fee");
            this.prepareNotZeroSelect(preference, "fee_range", "fee_range_id");
            if (preference.available_time_slots_attributes.days) {
                preference.available_time_slots_attributes = prefered_time_1.prepareScheduleValuesToPut(preference.available_time_slots_attributes.days);
                delete preference.available_time_slots_attributes.days;
            }
            delete preference.fee_range;
        }
        return provider;
    };
    return ServiceProviderApiAdapter;
}(api_communication_adapter_1.ApiСommunicationAdapter));
exports.ServiceProviderApiAdapter = ServiceProviderApiAdapter;
