"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_form_1 = require("redux-form");
var field_array_parents_1 = require("../field_array_parents");
var redux_form_2 = require("redux-form");
var react_redux_1 = require("react-redux");
var phone_1 = require("../../../../provider_add/components/edit_form/phone");
var email_1 = require("../../../../provider_add/components/edit_form/email");
var address_1 = require("components/form/parts/address");
var ClientTypesContacts = /** @class */ (function (_super) {
    __extends(ClientTypesContacts, _super);
    function ClientTypesContacts() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientTypesContacts.prototype.render = function () {
        var _a = this.props, values = _a.values, validators = _a.validators, error = _a.error, t = _a.t;
        return (React.createElement(React.Fragment, null,
            values.client_type === "child" && (React.createElement(redux_form_1.FieldArray, { name: "parents", component: field_array_parents_1.default, validators: validators })),
            values.client_type === "adult" && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "dynamic-block-container" },
                    React.createElement("div", { className: "dynamic-block" },
                        React.createElement("div", { className: "dynamic-fields-container" },
                            React.createElement("div", { className: "email-phone-container two-line margin-bottom vertical-top" },
                                React.createElement("div", { className: "item" },
                                    React.createElement(redux_form_1.FieldArray, { name: "contact.phones", component: phone_1.default, validators: validators })),
                                React.createElement("div", { className: "item" },
                                    React.createElement(redux_form_1.FieldArray, { name: "contact.emails", component: email_1.default, validators: validators, customError: error }))),
                            React.createElement(address_1.default, __assign({}, this.props, { required: true, validators: validators, namePrefix: "contact", t: t })))))))));
    };
    ClientTypesContacts = __decorate([
        react_redux_1.connect(function (state) {
            return {
                values: redux_form_2.getFormValues("client_edit")(state)
            };
        })
    ], ClientTypesContacts);
    return ClientTypesContacts;
}(React.Component));
exports.default = ClientTypesContacts;
