"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SESSION_CONST;
(function (SESSION_CONST) {
    SESSION_CONST["SESSION_CREATE_SUCCESS"] = "SESSION_CREATE_SUCCESS";
    SESSION_CONST["SESSION_CREATE_FAIL"] = "SESSION_CREATE_FAIL";
    SESSION_CONST["SESSION_EDIT_SUCCESS"] = "SESSION_EDIT_SUCCESS";
    SESSION_CONST["SESSION_EDIT_FAIL"] = "SESSION_EDIT_FAIL";
    SESSION_CONST["SESSION_DELETE_SUCCESS"] = "SESSION_DELETE_SUCCESS";
    SESSION_CONST["SESSION_DELETE_FAIL"] = "SESSION_DELETE_FAIL";
    SESSION_CONST["SESSION_CHECK_OVERLAPS_SUCCESS"] = "SESSION_CHECK_OVERLAPS_SUCCESS";
    SESSION_CONST["SESSION_CHECK_OVERLAPS_FAIL"] = "SESSION_CHECK_OVERLAPS_FAIL";
    SESSION_CONST["SET_SELECTED_DATE"] = "SET_SELECTED_DATE";
    SESSION_CONST["GET_FIRST_SESSION_SUCCESS"] = "GET_FIRST_SESSION_SUCCESS";
    SESSION_CONST["GET_FIRST_SESSION_FAIL"] = "GET_FIRST_SESSION_FAIL";
})(SESSION_CONST = exports.SESSION_CONST || (exports.SESSION_CONST = {}));
