"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var invoice_1 = require("actions/invoice");
var react_router_1 = require("react-router");
var invoice_2 = require("components/invoice");
var wizard_1 = require("components/wizard");
var invoice_details_1 = require("./steps/invoice_details");
var choose_method_form_1 = require("./steps/choose_method_form");
var react_stripe_elements_1 = require("react-stripe-elements");
var card_form_1 = require("./steps/card_checkout/card_form");
var select_card_form_1 = require("./steps/card_checkout/select_card_form");
var select_bank_form_1 = require("./steps/bank_checkout/select_bank_form");
var amounts_verification_form_1 = require("./steps/bank_checkout/amounts_verification_form");
var bank_form_1 = require("./steps/bank_checkout/bank_form");
var react_toastify_1 = require("react-toastify");
var react_i18next_1 = require("react-i18next");
var overflow_loader_1 = require("components/overflow-loader");
var invoice_3 = require("../../../../models/invoice");
var invoice_service_1 = require("../../../../services/invoice.service");
var InvoiceWizardFormContainer = /** @class */ (function (_super) {
    __extends(InvoiceWizardFormContainer, _super);
    function InvoiceWizardFormContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            step: null,
            isLoaderVisilble: true
        };
        _this.onStepChange = function (step) {
            _this.setState({ step: step });
        };
        _this.isCardFeeVisible = function (step) {
            return (step === "new-card-charge" ||
                step === "select-card-charge" ||
                step === "new-card-charge-after-select" ||
                step === "new-international-card-charge" ||
                step === "select-international-card-charge" ||
                step === "new-international-card-charge-after-select");
        };
        _this.isInternationalPaymentStep = function (step) {
            return (step === "new-international-card-charge" ||
                step === "select-international-card-charge" ||
                step === "new-international-card-charge-after-select");
        };
        _this.onForceFirstStep = function () {
            _this.getInvoice();
        };
        _this.getInvoice = function () {
            var _a = _this.props, match = _a.match, history = _a.history, getInvoiceByUuidAction = _a.getInvoiceByUuidAction, t = _a.t;
            if (match.params && match.params.uuid) {
                _this.showLoader();
                getInvoiceByUuidAction(match.params.uuid)
                    .then(function (result) {
                    _this.hideLoader();
                })
                    .catch(function (err) {
                    _this.hideLoader();
                    react_toastify_1.toast.error(t("client_invoice.notifications.get_invoice_error"), {
                        position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true
                    });
                });
            }
            else {
                history.replace("/404");
            }
        };
        _this.hideLoader = function () {
            _this.setState({ isLoaderVisilble: false });
        };
        _this.showLoader = function () {
            _this.setState({ isLoaderVisilble: true });
        };
        return _this;
    }
    InvoiceWizardFormContainer.prototype.render = function () {
        var _this = this;
        var _a = this.props, invoice = _a.invoice, verifyAchBankEndAction = _a.verifyAchBankEndAction, verifyAchBankStartAction = _a.verifyAchBankStartAction, chargeAchBankAction = _a.chargeAchBankAction, chargeCardAction = _a.chargeCardAction;
        var _b = this.state, step = _b.step, isLoaderVisilble = _b.isLoaderVisilble;
        var config = [
            {
                id: "invoice-details",
                component: React.createElement(invoice_details_1.default, { invoice: invoice }),
                getNextStepID: function () { return "choose-payment-type"; }
            },
            {
                id: "choose-payment-type",
                component: React.createElement(choose_method_form_1.default, { invoice: invoice }),
                getNextStepID: function (type) {
                    if (type === "card") {
                        var isCardAttached = _this.props.sources.find(function (source) {
                            return source.object === "card" && source.cvc_check === "pass" && source.country === "US";
                        });
                        return isCardAttached ? "select-card-charge" : "new-card-charge";
                    }
                    if (type === "international_card") {
                        var isCardAttached = _this.props.sources.find(function (source) {
                            return source.object === "card" && source.cvc_check === "pass" && source.country !== "US";
                        });
                        return isCardAttached ? "select-international-card-charge" : "new-international-card-charge";
                    }
                    if (type === "bank") {
                        var isExistVerifiedBankAccounts = _this.props.sources.find(function (source) {
                            return (source.object === "bank_account" &&
                                source.status === "verified");
                        });
                        return isExistVerifiedBankAccounts
                            ? "select-bank"
                            : "new-bank-charge";
                    }
                },
                getPreviousStepID: function () { return "invoice-details"; }
            },
            // CARD
            {
                id: "new-card-charge",
                component: React.createElement(card_form_1.default, { isInternationalPayment: false }),
                getNextStepID: function () {
                    return "invoice-paid";
                },
                getPreviousStepID: function () { return "choose-payment-type"; }
            },
            {
                id: "select-card-charge",
                component: (React.createElement(select_card_form_1.default, { sources: this.props.sources, invoice: invoice, chargeCardAction: chargeCardAction, isInternationalPayment: false })),
                getPreviousStepID: function () { return "choose-payment-type"; },
                getNextStepID: function () { return "new-card-charge-after-select"; }
            },
            {
                id: "new-card-charge-after-select",
                component: React.createElement(card_form_1.default, { isInternationalPayment: false }),
                getNextStepID: function () {
                    return "invoice-paid";
                },
                getPreviousStepID: function () { return "select-card-charge"; }
            },
            // INTERNATIONAL CARD
            {
                id: "new-international-card-charge",
                component: React.createElement(card_form_1.default, { isInternationalPayment: true }),
                getNextStepID: function () {
                    return "invoice-paid";
                },
                getPreviousStepID: function () { return "choose-payment-type"; }
            },
            {
                id: "select-international-card-charge",
                component: (React.createElement(select_card_form_1.default, { sources: this.props.sources, invoice: invoice, chargeCardAction: chargeCardAction, isInternationalPayment: true })),
                getPreviousStepID: function () { return "choose-payment-type"; },
                getNextStepID: function () { return "new-internatonalcard-charge-after-select"; }
            },
            {
                id: "new-international-card-charge-after-select",
                component: React.createElement(card_form_1.default, { isInternationalPayment: true }),
                getNextStepID: function () {
                    return "invoice-paid";
                },
                getPreviousStepID: function () { return "select-card-charge"; }
            },
            // ACH
            {
                id: "new-bank-charge",
                component: (React.createElement(bank_form_1.default, { invoice: invoice, verifyAchBankStartAction: verifyAchBankStartAction })),
                getPreviousStepID: function () { return "choose-payment-type"; }
            },
            {
                id: "select-bank",
                component: (React.createElement(select_bank_form_1.default, { sources: this.props.sources, invoice: invoice, chargeAchBankAction: chargeAchBankAction })),
                getPreviousStepID: function () { return "choose-payment-type"; },
                getNextStepID: function () { return "new-bank-charge-after-select"; }
            },
            {
                id: "new-bank-charge-after-select",
                component: (React.createElement(bank_form_1.default, { invoice: invoice, verifyAchBankStartAction: verifyAchBankStartAction })),
                getPreviousStepID: function () { return "select-bank"; }
            },
            {
                id: "invoice-paid",
                component: React.createElement(invoice_details_1.default, null)
            }
        ];
        return (React.createElement("div", { className: "client-invoice-view" },
            React.createElement(overflow_loader_1.default, { isLoaderVisible: isLoaderVisilble }),
            invoice ? (React.createElement(invoice_2.default, { invoice: invoice, showCardFee: this.isCardFeeVisible(step), isInternationalPayment: this.isInternationalPaymentStep(step) })) : null,
            this.isInvoiceCanBeCharged(invoice) && (React.createElement(react_stripe_elements_1.Elements, null,
                React.createElement(wizard_1.default, { config: config, onStepChange: this.onStepChange, onForceFirstStep: this.onForceFirstStep }))),
            this.isInvoiceOnACHVerificationStep1(invoice) && (React.createElement(amounts_verification_form_1.default, { invoice: invoice, verifyAchBankEndAction: verifyAchBankEndAction })),
            this.isInvoiceACHVerifiedAndWait(invoice) && (React.createElement(invoice_details_1.default, { invoice: invoice })),
            invoice_service_1.isInvoicePaid(invoice) && React.createElement(invoice_details_1.default, { invoice: invoice })));
    };
    InvoiceWizardFormContainer.prototype.isInvoiceCanBeCharged = function (invoice) {
        return (invoice &&
            (invoice.status === invoice_3.INVOICE_STATUSES.DRAFT ||
                invoice.status === invoice_3.INVOICE_STATUSES.SENT ||
                invoice.status === invoice_3.INVOICE_STATUSES.REJECTED));
    };
    InvoiceWizardFormContainer.prototype.isInvoiceOnACHVerificationStep1 = function (invoice) {
        return (invoice &&
            (invoice.status === invoice_3.INVOICE_STATUSES.ACH_VERIFICATION &&
                !invoice.payment_id &&
                !!invoice.bank_account_id));
    };
    InvoiceWizardFormContainer.prototype.isInvoiceACHVerifiedAndWait = function (invoice) {
        return (invoice &&
            (invoice.status === invoice_3.INVOICE_STATUSES.IN_PROGRESS &&
                !!invoice.payment_id &&
                !!invoice.bank_account_id));
    };
    InvoiceWizardFormContainer.prototype.componentWillMount = function () {
        this.getInvoice();
    };
    InvoiceWizardFormContainer = __decorate([
        react_router_1.withRouter,
        react_redux_1.connect(function (state) {
            return {
                invoice: state.clientInvoice.invoice,
                sources: state.clientInvoice.sources
            };
        }, function (dispatch) {
            return {
                verifyAchBankEndAction: redux_1.bindActionCreators(invoice_1.verifyAchBankEnd, dispatch),
                verifyAchBankStartAction: redux_1.bindActionCreators(invoice_1.verifyAchBankStart, dispatch),
                chargeAchBankAction: redux_1.bindActionCreators(invoice_1.chargeAchBank, dispatch),
                chargeCardAction: redux_1.bindActionCreators(invoice_1.chargeCard, dispatch),
                getInvoiceByUuidAction: redux_1.bindActionCreators(invoice_1.getInvoiceByUuid, dispatch)
            };
        }),
        react_i18next_1.translate()
    ], InvoiceWizardFormContainer);
    return InvoiceWizardFormContainer;
}(React.Component));
exports.default = InvoiceWizardFormContainer;
