"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var redux_form_1 = require("redux-form");
var react_redux_1 = require("react-redux");
var lodash_1 = require("lodash");
var fields_1 = require("components/form/fields");
var select_1 = require("components/form/fields/select");
var popup_1 = require("components/popup");
var fee_range_1 = require("helpers/fee_range");
var age_range_1 = require("helpers/age_range");
var react_router_dom_1 = require("react-router-dom");
var detail_1 = require("./detail");
var advanced_search_button_1 = require("components/helpers/advanced_search_button");
var updateFilterTimer = null;
var ClientsFilter = /** @class */ (function (_super) {
    __extends(ClientsFilter, _super);
    function ClientsFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showDetail: false
        };
        _this.handleOpenDetail = function () {
            if (_this.props.getAllServiceProviders) {
                _this.props.getAllServiceProviders();
            }
            _this.setState({
                showDetail: true
            });
        };
        _this.handleCloseDetail = function () {
            _this.setState({
                showDetail: false
            });
        };
        return _this;
    }
    ClientsFilter.prototype.render = function () {
        var _a = this.props, t = _a.t, workspace = _a.workspace, allServiceProviders = _a.allServiceProviders, searchValues = _a.searchValues, currentUserRole = _a.currentUserRole;
        var showDetail = this.state.showDetail;
        return t && workspace ? (React.createElement("div", { className: "main-page-filter" },
            React.createElement("form", null,
                React.createElement("div", { className: "search" },
                    React.createElement(redux_form_1.Field, { component: fields_1.default, name: "search", type: "text", id: "search", placeholder: t("providers_filter.search.placeholder") }),
                    React.createElement("label", { htmlFor: "search" },
                        React.createElement("i", { className: "icon search" })),
                    React.createElement(advanced_search_button_1.default, { handleOpenDetail: this.handleOpenDetail, searchValues: searchValues })),
                React.createElement(redux_form_1.Field, { clearable: true, backspaceRemoves: true, component: select_1.default, name: "fee_range", options: fee_range_1.default.getOptions(workspace.fee_ranges, true), placeholder: t("providers_filter.fee_range.placeholder") }),
                React.createElement(redux_form_1.Field, { clearable: true, backspaceRemoves: true, component: select_1.default, name: "age_range", options: age_range_1.default.getOptions(workspace.age_ranges), placeholder: t("providers_filter.age_range.placeholder") }),
                React.createElement(popup_1.default, { openned: showDetail, popupExtendedClass: "always-on-top", onClose: this.handleCloseDetail },
                    React.createElement(detail_1.default, { workspace: workspace, onClose: this.handleCloseDetail, allServiceProviders: allServiceProviders })),
                currentUserRole !== 'service_provider' && React.createElement(react_router_dom_1.Link, { className: "button__red button", to: "/clients/add" }, t("clients_filter.add"))))) : null;
    };
    ClientsFilter = __decorate([
        react_i18next_1.translate(),
        redux_form_1.reduxForm({
            form: "clientFilter",
            enableReinitialize: true,
            keepDirtyOnReinitialize: true,
            onChange: function (values, dispatch, props) {
                var fetchClients = props.fetchClients, currentUserRole = props.currentUserRole, userId = props.userId;
                clearTimeout(updateFilterTimer);
                if (fetchClients) {
                    updateFilterTimer = setTimeout(function () {
                        var params = {
                            q: values.search,
                            page: 1
                        };
                        if (values.age_range) {
                            params.age_range = values.age_range.id;
                        }
                        if (values.fee_range) {
                            params.fee_range = values.fee_range.id;
                        }
                        if (values.gender) {
                            params.gender = values.gender.id;
                        }
                        if (values.preferred_gender) {
                            params.preferred_gender = values.preferred_gender.id;
                        }
                        if (values.expertises) {
                            params.expertises = values.expertises.map(function (item) {
                                return item.id;
                            });
                        }
                        if (values.service_provider || currentUserRole === 'service_provider') {
                            params.service_provider = lodash_1.get(values, 'service_provider.id', userId);
                        }
                        fetchClients(params);
                    }, 500);
                }
            }
        })
    ], ClientsFilter);
    return ClientsFilter;
}(React.Component));
exports.default = react_redux_1.connect(function (state) { return ({
    userId: state.user.my.kind_id,
    currentUserRole: state.user.my ? state.user.my.role : null,
}); })(ClientsFilter);
