"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var classNames = require("classnames");
require("./style.less");
var react_router_1 = require("react-router");
var react_switch_1 = require("react-switch");
var react_router_dom_1 = require("react-router-dom");
var moment = require("moment");
var container_1 = require("./clients/container");
var react_toastify_1 = require("react-toastify");
var container_2 = require("components/schedule/container");
var billing_1 = require("constants/billing");
var lists_1 = require("constants/lists");
var prefered_location_1 = require("helpers/prefered_location");
var component_1 = require("components/schedule_preferences/component");
var page_title_1 = require("components/page_title");
var billingPopup_1 = require("./billingPopup");
var archivePopup_1 = require("./archivePopup");
var ProviderView = /** @class */ (function (_super) {
    __extends(ProviderView, _super);
    function ProviderView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            active: _this.props.providerData.status === "active",
            activeBilling: [
                billing_1.BILLING_STATUSES.BILLING_FIRST_TIME_ON,
                billing_1.BILLING_STATUSES.BILLING_ON
            ].includes(_this.props.providerData.billing_status),
            isArchivePopupOpened: false,
            isAssignSpPopupOpened: false,
            isBillingPopupOpened: false
        };
        _this.handleCloseArchivePopup = function () {
            _this.setState({
                isArchivePopupOpened: false
            });
        };
        _this.handleOpenArchivePopup = function () {
            _this.setState({
                isArchivePopupOpened: true
            });
        };
        _this.handleCloseBillingPopup = function () {
            _this.setState({
                isBillingPopupOpened: false
            });
        };
        _this.handleOpenBillingPopup = function () {
            _this.setState({
                isBillingPopupOpened: true
            });
        };
        _this.handleArchive = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, onArchive, id, t, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onArchive = _a.onArchive, id = _a.id, t = _a.t;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, onArchive(id)];
                    case 2:
                        _b.sent();
                        this.setState({ active: false, activeBilling: false });
                        react_toastify_1.toast.success(t("provider_view.arhive_success"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        this.handleCloseArchivePopup();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        react_toastify_1.toast.error(error_1, {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleRestore = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, onRestore, id, t, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onRestore = _a.onRestore, id = _a.id, t = _a.t;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, onRestore(id)];
                    case 2:
                        _b.sent();
                        this.setState({ active: true });
                        react_toastify_1.toast.success(t("provider_view.restore_success"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _b.sent();
                        react_toastify_1.toast.error(error_2, {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleSwitchBilling = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, switchBilling, id, t, activeBilling, toastText, error_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, switchBilling = _a.switchBilling, id = _a.id, t = _a.t;
                        activeBilling = this.state.activeBilling;
                        toastText = activeBilling
                            ? "provider_view.billing_on"
                            : "provider_view.billing_off";
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, switchBilling(id)];
                    case 2:
                        _b.sent();
                        react_toastify_1.toast.success(t(toastText), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        this.setState({
                            activeBilling: !activeBilling,
                            isBillingPopupOpened: false
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _b.sent();
                        react_toastify_1.toast.error(error_3, {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ProviderView.prototype.render = function () {
        var _a = this.props, match = _a.match, providerData = _a.providerData, t = _a.t, id = _a.id, onBackToProvidersHandler = _a.onBackToProvidersHandler;
        var _b = this.state, active = _b.active, activeBilling = _b.activeBilling, isBillingPopupOpened = _b.isBillingPopupOpened, isArchivePopupOpened = _b.isArchivePopupOpened;
        var hasAddress = !!(providerData.contact.address_attributes.street ||
            providerData.contact.address_attributes.city ||
            providerData.contact.address_attributes.state ||
            providerData.contact.address_attributes.zip_code);
        var pageTitle = providerData.first_name + " " + providerData.last_name + " ";
        return match && providerData && t && id ? (React.createElement("div", { className: "view-container" },
            React.createElement(page_title_1.default, { pageTitle: pageTitle }),
            React.createElement("div", { className: "view-container_back" },
                React.createElement(react_router_dom_1.Link, { to: "/providers", onClick: function () {
                        return onBackToProvidersHandler(lists_1.LIST_ENTITIES.ALL_PROVIDERS_PAGINATED_LIST);
                    } },
                    React.createElement("i", { className: "fas fa-arrow-left margin-right-10" }),
                    t("provider_view.back"))),
            React.createElement("div", { className: "view-container_edit" },
                React.createElement("div", { className: "edit__column_left" },
                    React.createElement("span", { className: "title" }, t("provider_view.edit")),
                    React.createElement("span", { className: "icon edit" },
                        React.createElement(react_router_dom_1.Link, { to: "/providers/edit/" + id }))),
                React.createElement("p", { className: "edit__column_right title" }, t("provider_view.schedule"))),
            React.createElement("div", { className: "view" },
                React.createElement("div", { className: "view__column_left" },
                    React.createElement("div", { className: "view__title" },
                        React.createElement("div", { className: classNames({
                                view__avatar: true,
                                view__avatar_default: !providerData.image
                            }) }, providerData.image && React.createElement("img", { src: providerData.image })),
                        React.createElement("div", { className: "titles" },
                            React.createElement("h2", { className: "action-panel-title" },
                                providerData.first_name,
                                " ",
                                providerData.last_name),
                            React.createElement("p", null, providerData.educations_attributes &&
                                providerData.educations_attributes.length > 0 &&
                                providerData.educations_attributes[0].degree
                                ? providerData.educations_attributes[0].degree.title
                                : null)),
                        React.createElement("div", null,
                            React.createElement("h3", { className: "view__heading" }, t("provider_view.fee_range", {
                                range: providerData.preference_attributes.fee_range &&
                                    providerData.preference_attributes.fee_range.name
                                    ? providerData.preference_attributes.fee_range.name
                                    : t("fee_range.unspecified")
                            })),
                            React.createElement("p", null, t("provider_view.preferred_fee", {
                                fee: providerData.preference_attributes.preferred_fee
                            })))),
                    React.createElement("div", { className: "center" },
                        React.createElement("div", { className: "left" },
                            providerData.work_experience && (React.createElement("section", null,
                                React.createElement("p", null, t("provider_view.experience_see", {
                                    year: moment().diff(moment("01/01/" + providerData.work_experience), "year")
                                })))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.preferred_location")),
                                React.createElement("p", null, prefered_location_1.getPreferedLocation(providerData.preference_attributes, t))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.age_range")),
                                providerData.preference_attributes.age_ranges.length > 0
                                    ? providerData.preference_attributes.age_ranges.map(function (item, key) {
                                        return React.createElement("p", { key: key }, item.name);
                                    })
                                    : t("provider_view.any")),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.contacts")),
                                providerData.contact.phones.map(function (item, key) {
                                    return React.createElement("p", { key: key }, item);
                                }),
                                providerData.contact.emails.map(function (item, key) {
                                    return React.createElement("p", { key: key }, item);
                                })),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.address")),
                                hasAddress ? (React.createElement("div", null,
                                    React.createElement("p", null, providerData.contact.address_attributes.street),
                                    React.createElement("p", null,
                                        providerData.contact.address_attributes.city,
                                        " ",
                                        providerData.contact.address_attributes.state,
                                        " ",
                                        providerData.contact.address_attributes.zip_code))) : (t("provider_view.not_specified")))),
                        React.createElement("div", { className: "right" },
                            providerData.about ? (React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.about")),
                                React.createElement("p", null, providerData.about))) : null,
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.education")),
                                !providerData.educations_attributes ||
                                    (providerData.educations_attributes.length === 0 &&
                                        t("provider_view.not_specified")),
                                React.createElement("ul", null, providerData.educations_attributes.map(function (item, key) {
                                    return item.graduate_year ||
                                        item.degree ||
                                        item.school ? (React.createElement("li", { key: key },
                                        item.graduate_year,
                                        " ",
                                        item.degree ? "- " + item.degree.title : "",
                                        " ",
                                        item.school && (item.graduate_year || item.degree)
                                            ? ","
                                            : "",
                                        " ",
                                        item.school)) : null;
                                }))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.expertise")),
                                React.createElement("ul", { className: "tags" }, providerData.expertises.length > 0
                                    ? providerData.expertises.map(function (item, key) {
                                        return React.createElement("li", { key: key }, item.title);
                                    })
                                    : t("provider_view.not_specified"))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.techniques")),
                                React.createElement("ul", { className: "tags" }, providerData.techniques.length > 0
                                    ? providerData.techniques.map(function (item, key) {
                                        return React.createElement("li", { key: key }, item.title);
                                    })
                                    : t("provider_view.not_specified"))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.personality")),
                                React.createElement("ul", { className: "tags" }, providerData.personalities.length > 0
                                    ? providerData.personalities.map(function (item, key) {
                                        return React.createElement("li", { key: key }, item.title);
                                    })
                                    : t("provider_view.not_specified"))),
                            React.createElement("h3", { className: "view__heading" }, t("provider_view.schedule_preference")),
                            React.createElement(component_1.default, { daysSelected: providerData.preference_attributes
                                    .available_time_slots_attributes.days, changeDayValue: function () { return null; }, editable: false })))),
                React.createElement("div", { className: "view__column_right" },
                    React.createElement(container_2.default, { providerData: providerData, schedule_preference_text: providerData.preference_attributes.schedule_preference }))),
            React.createElement(container_1.default, { providerData: providerData }),
            React.createElement("div", { className: "view-container_back admin-panel" },
                this.props.currentUserRole === "admin" && (React.createElement("button", { onClick: active ? this.handleOpenArchivePopup : this.handleRestore, className: "button button__transparent" }, active ? (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "fas fa-user-times margin-right-10" }),
                    t("provider_view.archive"))) : (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "fas fa-user-plus margin-right-10" }),
                    t("provider_view.restore"))))),
                active && (React.createElement("label", { className: "view-container_switch-label" },
                    React.createElement("span", null, t(activeBilling
                        ? "provider_view.billing_off"
                        : "provider_view.billing_on")),
                    React.createElement(react_switch_1.default, { onChange: active
                            ? this.handleOpenBillingPopup
                            : function () { return console.log("activation"); }, checked: activeBilling, onColor: "#d43867", offColor: "#5d5458" }))),
                React.createElement(archivePopup_1.ArchivePopup, { isArchivePopupOpened: isArchivePopupOpened, handleCloseArchivePopup: this.handleCloseArchivePopup, handleArchive: this.handleArchive, t: t }),
                React.createElement(billingPopup_1.BillingPopup, { isBillingPopupOpened: isBillingPopupOpened, handleCloseBillingPopup: this.handleCloseBillingPopup, handleSwitchBilling: this.handleSwitchBilling, activeBilling: activeBilling, t: t })))) : null;
    };
    return ProviderView;
}(React.Component));
exports.default = react_i18next_1.translate()(react_router_1.withRouter(ProviderView));
