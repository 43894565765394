"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var invoice_1 = require("../models/invoice");
exports.isInvoicePaid = function (invoice) {
    return invoice &&
        (invoice.status === invoice_1.INVOICE_STATUSES.PAID ||
            invoice.status === invoice_1.INVOICE_STATUSES.SPLIT ||
            invoice.status === invoice_1.INVOICE_STATUSES.MANUALLY_PAID);
};
exports.isInvoicePaidBySystem = function (invoice) {
    return invoice &&
        (invoice.status === invoice_1.INVOICE_STATUSES.PAID ||
            invoice.status === invoice_1.INVOICE_STATUSES.SPLIT);
};
exports.isInvoicePaidManually = function (invoice) {
    return invoice && invoice.status === invoice_1.INVOICE_STATUSES.MANUALLY_PAID;
};
