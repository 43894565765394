"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ClickOutside = /** @class */ (function (_super) {
    __extends(ClickOutside, _super);
    function ClickOutside() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClickOutside = function (event) {
            if (_this.wrapperRef && !_this.wrapperRef.contains(event.target)) {
                _this.props.onClickOutside();
            }
        };
        _this.setWrapperRef = function (node) {
            _this.wrapperRef = node;
        };
        return _this;
    }
    ClickOutside.prototype.render = function () {
        return React.createElement("div", { ref: this.setWrapperRef }, this.props.children);
    };
    ClickOutside.prototype.componentDidMount = function () {
        document.addEventListener("mousedown", this.handleClickOutside);
    };
    ClickOutside.prototype.componentWillUnmount = function () {
        document.removeEventListener("mousedown", this.handleClickOutside);
    };
    return ClickOutside;
}(React.Component));
exports.default = ClickOutside;
