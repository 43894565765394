"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var moment = require("moment");
var TimePattern;
(function (TimePattern) {
    TimePattern["hh_A"] = "hh A";
    TimePattern["hh_mm"] = "HH:mm";
})(TimePattern || (TimePattern = {}));
var SYMBOLS_IN_HOUR_OPTION_PART = 5;
var formatHour = function (hour, timePattern) {
    return moment()
        .hour(hour)
        .minute(0)
        .format(timePattern);
};
exports.daysOfWeek = moment.weekdays().map(lodash_1.toLower);
exports.createHourOptions = function (hours, timePattern) {
    if (timePattern === void 0) { timePattern = TimePattern.hh_A; }
    return hours.map(function (hour) { return ({
        value: hour,
        label: formatHour(hour, timePattern) + " - " + formatHour(hour + 1, timePattern)
    }); });
};
exports.hourOptions = exports.createHourOptions(lodash_1.range(0, 24));
exports.hoursToDurations = function (hourOptions) {
    var sortedValues = hourOptions.sort(function (a, b) { return a.value - b.value; });
    return sortedValues.reduce(function (durations, currentValue, index, hourOptions) {
        if (index === 0 ||
            currentValue.value - hourOptions[index - 1].value !== 1) {
            // TODO refactoring it. Choose better structure\algorythm instead of make slices all the time
            durations.push({
                start_at: currentValue.label.slice(0, SYMBOLS_IN_HOUR_OPTION_PART),
                end_at: currentValue.label.slice(-SYMBOLS_IN_HOUR_OPTION_PART)
            });
        }
        else {
            var lastDuration = durations[durations.length - 1];
            lastDuration.end_at = currentValue.label.slice(-SYMBOLS_IN_HOUR_OPTION_PART);
        }
        return durations;
    }, []);
};
exports.durationsToHours = function (durations) {
    return durations.reduce(function (hours, _a) {
        var start_at = _a.start_at, end_at = _a.end_at;
        var start = +start_at.slice(0, 2);
        var end = +end_at.slice(0, 2);
        if (end === 0) {
            end = 24;
        }
        return hours.concat(lodash_1.range(start, end));
    }, []);
};
exports.defaultScheduleTime = exports.durationsToHours([
    {
        start_at: "08:00",
        end_at: "23:00"
    }
]);
exports.prepareScheduleValuesToPut = function (days) {
    var preparedDays = [];
    Object.keys(days).forEach(function (dayKey) {
        var dayDurations = exports.hoursToDurations(exports.createHourOptions(days[dayKey], TimePattern.hh_mm));
        dayDurations.forEach(function (duration) {
            return preparedDays.push(__assign({}, duration, { day: dayKey }));
        });
    });
    return preparedDays;
};
exports.prepareScheduleValuesFromGet = function (days) {
    var preparedDays = {};
    Object.keys(days).forEach(function (dayKey) {
        preparedDays[dayKey] = exports.durationsToHours(days[dayKey]);
    });
    return preparedDays;
};
exports.prepareFilterValuesToPut = function (days) {
    var preparedDays = [];
    Object.keys(days).forEach(function (dayKey) {
        var dayDurations = exports.hoursToDurations(exports.createHourOptions(days[dayKey], TimePattern.hh_mm));
        dayDurations.forEach(function (duration) {
            var start_at = duration.start_at, end_at = duration.end_at;
            preparedDays.push(dayKey + "-" + start_at + "-" + end_at);
        });
    });
    return preparedDays;
};
exports.minutesOfDay = function (date) {
    return date.minutes() + date.hours() * 60;
};
