"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var react_router_1 = require("react-router");
var popup_1 = require("components/popup");
var assign_client_to_sp_1 = require("components/assign_client_to_sp");
var container_1 = require("../../../clients/table/container");
var react_toastify_1 = require("react-toastify");
var client_1 = require("constants/client");
var row_actions_1 = require("./row_actions");
var lists_1 = require("constants/lists");
var ProviderViewClientsList = /** @class */ (function (_super) {
    __extends(ProviderViewClientsList, _super);
    function ProviderViewClientsList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            onlyMy: false,
            isAddClientPopupOpened: false
        };
        _this.chooseEntityName = function () {
            return _this.state.onlyMy
                ? lists_1.LIST_ENTITIES.MANAGER_PROVIDER_CLIENTS_PAGINATED_LIST
                : lists_1.LIST_ENTITIES.ALL_PROVIDER_CLIENTS_PAGINATED_LIST;
        };
        _this.onClientAttached = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var _a, t, assignClientToProvider, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, t = _a.t, assignClientToProvider = _a.assignClientToProvider;
                        if (!(assignClientToProvider && t)) return [3 /*break*/, 2];
                        return [4 /*yield*/, assignClientToProvider(values, this.chooseEntityName())];
                    case 1:
                        result = _b.sent();
                        if (result.type === client_1.CLIENT_CONST.ASSIGN_CLIENT_TO_PROVIDER_SUCCESS) {
                            this.handleClosePopup();
                            react_toastify_1.toast.success(t("provider_assign_client_form.from_sp_view.on_success_notification"), {
                                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                hideProgressBar: true
                            });
                        }
                        else {
                            // TODO
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.unassignClientFromProvider = function (clientId) { return __awaiter(_this, void 0, void 0, function () {
            var _a, unassignClientFromProvider, t, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, unassignClientFromProvider = _a.unassignClientFromProvider, t = _a.t;
                        if (!(unassignClientFromProvider && t)) return [3 /*break*/, 2];
                        return [4 /*yield*/, unassignClientFromProvider(clientId, this.chooseEntityName())];
                    case 1:
                        result = _b.sent();
                        if (result.type === client_1.CLIENT_CONST.UNASSIGN_CLIENT_FROM_PROVIDER_SUCCESS) {
                            react_toastify_1.toast.success(t("provider_clients_list.unasign.on_success_notification"), {
                                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                hideProgressBar: true
                            });
                        }
                        else {
                            // TODO
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.handleOpenAllClientsTable = function () {
            _this.setState({
                onlyMy: false
            });
        };
        _this.handleOpenMyClientsTable = function () {
            _this.setState({
                onlyMy: true
            });
        };
        _this.handleClosePopup = function () {
            _this.setState({
                isAddClientPopupOpened: false
            });
        };
        /**
         * @method handleShowPopup
         * we need update clients list after assign in popup
         */
        _this.handleShowPopup = function () {
            _this.setState({
                isAddClientPopupOpened: true
            });
            if (_this.props.fetchUnassignedClientsList) {
                _this.props.fetchUnassignedClientsList();
            }
        };
        return _this;
    }
    ProviderViewClientsList.prototype.render = function () {
        var _a = this.props, t = _a.t, allUnassignedClientsList = _a.allUnassignedClientsList, currentUserRole = _a.currentUserRole, providerData = _a.providerData;
        var _b = this.state, onlyMy = _b.onlyMy, isAddClientPopupOpened = _b.isAddClientPopupOpened;
        return t ? (React.createElement("div", { className: "provider-clients-list" },
            React.createElement("div", { className: "head" },
                React.createElement("nav", null,
                    currentUserRole === "admin" && (React.createElement("a", { className: onlyMy ? "" : "current", onClick: this.handleOpenAllClientsTable }, t("provider_clients_list.nav.all_clients"))),
                    React.createElement("a", { className: !onlyMy || currentUserRole === "manager" ? "" : "current", onClick: this.handleOpenMyClientsTable }, t("provider_clients_list.nav.my_clients"))),
                providerData.status === "active" && (React.createElement(React.Fragment, null,
                    React.createElement("a", { onClick: this.handleShowPopup, className: "assing-button button__red button" }, t("provider_clients_list.assign")),
                    React.createElement(popup_1.default, { openned: isAddClientPopupOpened, onClose: this.handleClosePopup },
                        React.createElement(assign_client_to_sp_1.default, { list: allUnassignedClientsList.items, onSubmit: this.onClientAttached, assignToName: providerData.first_name + " " + providerData.last_name, isClientView: false }))))),
            React.createElement("div", { className: "body" },
                onlyMy && (React.createElement(container_1.default, { providerId: this.props.providerData.id, entity: lists_1.LIST_ENTITIES.MANAGER_PROVIDER_CLIENTS_PAGINATED_LIST, isNeedUpdateOnMount: true, managerId: this.props.currentUserId },
                    React.createElement(row_actions_1.default, { unassignClientFromProvider: this.unassignClientFromProvider }))),
                !onlyMy && (React.createElement(container_1.default, { providerId: this.props.providerData.id, isNeedUpdateOnMount: true, entity: lists_1.LIST_ENTITIES.ALL_PROVIDER_CLIENTS_PAGINATED_LIST },
                    React.createElement(row_actions_1.default, { unassignClientFromProvider: this.unassignClientFromProvider })))))) : null;
    };
    ProviderViewClientsList = __decorate([
        react_router_1.withRouter,
        react_i18next_1.translate()
    ], ProviderViewClientsList);
    return ProviderViewClientsList;
}(React.Component));
exports.default = ProviderViewClientsList;
