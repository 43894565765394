"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./style.less");
var simple_1 = require("layouts/simple");
var container_1 = require("./components/login/container");
var auth_1 = require("components/helpers/auth");
var react_router_1 = require("react-router");
var react_i18next_1 = require("react-i18next");
var LoginPage = /** @class */ (function (_super) {
    __extends(LoginPage, _super);
    function LoginPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoginPage.prototype.render = function () {
        var t = this.props.t;
        var redirectProps = {
            to: {
                pathname: "/",
                state: {}
            }
        };
        return (React.createElement(simple_1.default, { logoToLanding: true }, !auth_1.default.isAuthorized() ? (React.createElement("div", { className: "auth-page-content" },
            React.createElement("h1", null, t("login.title")),
            React.createElement(container_1.default, null))) : (React.createElement(react_router_1.Redirect, __assign({}, redirectProps)))));
    };
    LoginPage = __decorate([
        react_i18next_1.translate()
    ], LoginPage);
    return LoginPage;
}(React.Component));
exports.default = LoginPage;
