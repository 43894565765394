"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var redux_form_1 = require("redux-form");
var lodash_1 = require("lodash");
var service_provider_1 = require("actions/service_provider");
var _1 = require(".");
var service_provider_2 = require("constants/service_provider");
var spinner_1 = require("components/spinner");
var fee_range_1 = require("helpers/fee_range");
var api_adapter_1 = require("../provider_add/components/edit_form/api_adapter");
var prefered_time_1 = require("helpers/prefered_time");
var private_1 = require("components/helpers/user/private");
var main_1 = require("layouts/main");
var initialValues = {
    preference_attributes: {
        available_time_slots_attributes: {
            days: {
                monday: prefered_time_1.defaultScheduleTime,
                tuesday: prefered_time_1.defaultScheduleTime,
                wednesday: prefered_time_1.defaultScheduleTime,
                thursday: prefered_time_1.defaultScheduleTime,
                friday: prefered_time_1.defaultScheduleTime,
                saturday: [],
                sunday: []
            }
        }
    }
};
var dayPrefix = "preference_attributes.available_time_slots_attributes.days";
var emptyDays = {};
var ProviderAddEditFormContainer = /** @class */ (function (_super) {
    __extends(ProviderAddEditFormContainer, _super);
    function ProviderAddEditFormContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            provider: null
        };
        _this.componentDidMountAsCreateForm = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setState({
                    provider: initialValues
                });
                return [2 /*return*/];
            });
        }); };
        _this.componentDidMountAsEditForm = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, getServiceProviderAction, user, id, result, adapter, provider;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, getServiceProviderAction = _a.getServiceProviderAction, user = _a.user;
                        id = +lodash_1.get(user, 'kind_id', 0);
                        return [4 /*yield*/, getServiceProviderAction(id)];
                    case 1:
                        result = _b.sent();
                        if (result.type === service_provider_2.SERVICE_PROVIDER_CONST.SERVICE_PROVIDER_GET_SUCCESS) {
                            adapter = new api_adapter_1.ServiceProviderApiAdapter();
                            provider = adapter.convertResponse(result.payload);
                            this.setState({
                                provider: provider
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleUpdateProvider = function (provider) {
            var preferences = provider.preference_attributes;
            if (preferences && !preferences.fee_range) {
                preferences.fee_range = fee_range_1.default.unspecifiedOption;
            }
            _this.setState({
                provider: provider
            });
        };
        _this.onCreate = function (provider) { return __awaiter(_this, void 0, void 0, function () {
            var createServiceProviderAction, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        createServiceProviderAction = this.props.createServiceProviderAction;
                        return [4 /*yield*/, createServiceProviderAction(provider)];
                    case 1:
                        result = _a.sent();
                        if (result.type === service_provider_2.SERVICE_PROVIDER_CONST.SERVICE_PROVIDER_CREATE_SUCCESS) {
                            return [2 /*return*/, result.payload];
                        }
                        else {
                            throw result.payload;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onUpdate = function (id, provider) { return __awaiter(_this, void 0, void 0, function () {
            var updateServiceProviderAction, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updateServiceProviderAction = this.props.updateServiceProviderAction;
                        return [4 /*yield*/, updateServiceProviderAction(id, provider)];
                    case 1:
                        result = _a.sent();
                        if (result.type === service_provider_2.SERVICE_PROVIDER_CONST.SERVICE_PROVIDER_UPDATE_SUCCESS) {
                            return [2 /*return*/, result.payload];
                        }
                        else {
                            throw result.payload;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ProviderAddEditFormContainer.prototype.render = function () {
        var _a = this.props, workspace = _a.workspace, changeFormAction = _a.changeFormAction, daysSelected = _a.daysSelected, changeDayValue = _a.changeDayValue, user = _a.user;
        var provider = this.state.provider;
        var id = +lodash_1.get(user, 'kind_id', 0);
        return (workspace && provider && !!id) ? (React.createElement(main_1.default, null,
            React.createElement(_1.default, { onCreate: this.onCreate, onUpdate: this.onUpdate, onUpdateProvider: this.handleUpdateProvider, workspace: workspace, initialValues: provider, changeFormAction: changeFormAction, id: id, daysSelected: daysSelected, changeDayValue: changeDayValue, dayPrefix: dayPrefix }))) : (React.createElement(spinner_1.default, null));
    };
    ProviderAddEditFormContainer.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, getServiceProviderWorkspaceAction, user, workspace, id;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, getServiceProviderWorkspaceAction = _a.getServiceProviderWorkspaceAction, user = _a.user, workspace = _a.workspace;
                        id = +lodash_1.get(user, 'kind_id', 0);
                        // If always call getClientWorkspaceAction there then validation in form would be broken.
                        if (!workspace) {
                            getServiceProviderWorkspaceAction();
                        }
                        if (!id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.componentDidMountAsEditForm()];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.componentDidMountAsCreateForm()];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProviderAddEditFormContainer = __decorate([
        private_1.default,
        react_redux_1.connect(function (state) {
            var formState = redux_form_1.getFormValues("provider_edit")(state);
            return {
                user: state.user.my,
                workspace: state.serviceProvider.workspace,
                daysSelected: lodash_1.get(formState, dayPrefix, emptyDays)
            };
        }, function (dispatch) {
            return {
                changeFormAction: redux_1.bindActionCreators(redux_form_1.change, dispatch),
                createServiceProviderAction: redux_1.bindActionCreators(service_provider_1.createServiceProvider, dispatch),
                getServiceProviderWorkspaceAction: redux_1.bindActionCreators(service_provider_1.getServiceProviderWorkspace, dispatch),
                getServiceProviderAction: redux_1.bindActionCreators(service_provider_1.getServiceProvider, dispatch),
                updateServiceProviderAction: redux_1.bindActionCreators(service_provider_1.updateServiceProvider, dispatch),
                changeDayValue: function (day, value) {
                    return dispatch(redux_form_1.change("provider_edit", dayPrefix + "[" + day + "]", value));
                }
            };
        })
    ], ProviderAddEditFormContainer);
    return ProviderAddEditFormContainer;
}(React.Component));
exports.default = ProviderAddEditFormContainer;
