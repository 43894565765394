"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var redux_form_1 = require("redux-form");
var select_1 = require("../form/fields/select");
var validators_1 = require("../form/wrapper/validators");
var AssignClientToProvider = /** @class */ (function (_super) {
    __extends(AssignClientToProvider, _super);
    function AssignClientToProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            hasErrors: false
        };
        _this.getList = function () {
            var list = _this.props.list;
            if (list) {
                return list.map(function (client) {
                    return {
                        value: client.id,
                        label: client.name
                    };
                });
            }
            return [];
        };
        _this.handleSelect = function () {
            _this.setState({ hasErrors: false });
        };
        return _this;
    }
    AssignClientToProvider.prototype.render = function () {
        var _a = this.props, t = _a.t, handleSubmit = _a.handleSubmit, submitting = _a.submitting, error = _a.error, validators = _a.validators, assignToName = _a.assignToName, isClientView = _a.isClientView;
        var translation = isClientView ? "from_client_view" : "from_sp_view";
        return t && handleSubmit && validators ? (React.createElement("div", { className: "provider-clients-list-add" },
            React.createElement("form", { className: "assign-client-form", onSubmit: handleSubmit(this.handleSubmit.bind(this)) },
                React.createElement("h3", { className: "modal-title" },
                    t("provider_assign_client_form." + translation + ".title_1"),
                    React.createElement("br", null),
                    t("provider_assign_client_form." + translation + ".title_2"),
                    " ",
                    assignToName),
                !isClientView && (React.createElement("div", { className: "below-header-hint" }, t("provider_assign_client_form." + translation + ".hint"))),
                React.createElement("div", { className: "form-body" },
                    React.createElement(redux_form_1.Field, { label: t("provider_assign_client_form." + translation + ".fields.label"), component: select_1.default, options: this.getList(), validate: validators.required, require: "true", menuStyle: { maxHeight: "125px" }, name: "clientId", type: "text", placeholder: t("provider_assign_client_form." + translation + ".fields.placeholder"), onChange: this.handleSelect }),
                    error || this.state.hasErrors ? (React.createElement("span", { className: "error" }, t("provider_assign_client_form.required"))) : null,
                    React.createElement("button", { className: "button button__red full-width", disabled: submitting, type: "submit" }, t("provider_assign_client_form.submit")))))) : null;
    };
    AssignClientToProvider.prototype.handleSubmit = function (values) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, pristine, onSubmit;
            return __generator(this, function (_b) {
                _a = this.props, pristine = _a.pristine, onSubmit = _a.onSubmit;
                if (!pristine && onSubmit) {
                    onSubmit(values);
                }
                else {
                    this.setState({ hasErrors: true });
                }
                return [2 /*return*/];
            });
        });
    };
    AssignClientToProvider = __decorate([
        validators_1.default,
        redux_form_1.reduxForm({
            form: "assignClientToProvider"
        }),
        react_i18next_1.translate()
    ], AssignClientToProvider);
    return AssignClientToProvider;
}(React.Component));
exports.default = AssignClientToProvider;
