"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("config/api");
var params_1 = require("./helpers/params");
var queryString = require("query-string");
var errors_herlper_actions_1 = require("actions/errors_herlper_actions");
// TODO refactoring methods, a lot of copypast here
var ClientApi = /** @class */ (function () {
    function ClientApi() {
    }
    ClientApi.create = function (data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients", Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify({ client: data }),
            method: "POST"
        }));
    };
    ClientApi.getWorkspace = function () {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients/new", Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    ClientApi.getList = function (query) {
        query =
            "?" + queryString.stringify(query, {
                arrayFormat: "bracket"
            }) || "";
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients/" + query, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    ClientApi.getClient = function (id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients/" + id, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    ClientApi.update = function (id, data) {
        delete data.id;
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients/" + id, Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify({ client: data }),
            method: "PUT"
        }));
    };
    ClientApi.archive = function (id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients/" + id + "/archive/", Object.assign({}, params_1.getDefaultParams(), {
            method: "POST"
        }));
    };
    ClientApi.restore = function (id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients/" + id + "/restore/", Object.assign({}, params_1.getDefaultParams(), {
            method: "POST"
        }));
    };
    ClientApi.reasign = function (id, manager_id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients/" + id + "/assign_manager/" + manager_id, Object.assign({}, params_1.getDefaultParams(), {
            method: "POST"
        }));
    };
    ClientApi.assignClientToProvider = function (clientId, providerId) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients/" + clientId + "/assign/" + providerId, Object.assign({}, params_1.getDefaultParams(), {
            method: "POST"
        }));
    };
    ClientApi.unassignClientFromProvider = function (clientId) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "clients/" + clientId + "/unassign", Object.assign({}, params_1.getDefaultParams(), {
            method: "POST"
        }));
    };
    return ClientApi;
}());
exports.ClientApi = ClientApi;
