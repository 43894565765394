"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var popup_1 = require("components/popup");
exports.ArchivePopup = function (_a) {
    var isArchivePopupOpened = _a.isArchivePopupOpened, handleCloseArchivePopup = _a.handleCloseArchivePopup, handleArchive = _a.handleArchive, t = _a.t;
    return (React.createElement(popup_1.default, { openned: isArchivePopupOpened, onClose: handleCloseArchivePopup },
        React.createElement("div", null,
            React.createElement("h2", { className: "modal-title" }, t("modal.title")),
            React.createElement("p", { className: "modal-text" }, t("provider_view.archive_popup_text")),
            React.createElement("div", { className: "modal-actions" },
                React.createElement("button", { onClick: handleCloseArchivePopup, className: "button button__cancel half-width" }, t("modal.cancel")),
                React.createElement("button", { onClick: handleArchive, className: "button button__red" }, t("provider_view.archive"))))));
};
