"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SESSION_DURATION_RANGE = [75, 60];
var SessionDurationRangeHelper = /** @class */ (function () {
    function SessionDurationRangeHelper() {
    }
    SessionDurationRangeHelper.getOptions = function (pattern) {
        return (exports.SESSION_DURATION_RANGE || []).map(function (item) { return ({
            label: pattern(item),
            value: item
        }); });
    };
    return SessionDurationRangeHelper;
}());
exports.SessionDurationRangeHelper = SessionDurationRangeHelper;
