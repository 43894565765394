"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var lodash_1 = require("lodash");
var invoice_1 = require("models/invoice");
var spinner_1 = require("components/spinner");
var sort_title_1 = require("components/sort_title");
var is_admin_or_manager_1 = require("../../../components/helpers/user/is_admin_or_manager");
require("./style.less");
var invoice_service_1 = require("../../../services/invoice.service");
var InvoicesTable = /** @class */ (function (_super) {
    __extends(InvoicesTable, _super);
    function InvoicesTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClickRow = function (id) {
            var history = _this.props.history;
            if (history) {
                history.push("/invoices/view/" + id);
            }
        };
        return _this;
    }
    InvoicesTable.prototype.render = function () {
        var _this = this;
        var _a = this.props, t = _a.t, items = _a.items, currentSort = _a.currentSort, onReset = _a.onReset, handleSelectSort = _a.handleSelectSort, isFilterEmpty = _a.isFilterEmpty, currentUserRole = _a.currentUserRole;
        var isAdminOrManager = is_admin_or_manager_1.adminOrManager(currentUserRole);
        if (!items) {
            return React.createElement(spinner_1.default, null);
        }
        if (items.length === 0 && isFilterEmpty) {
            return (React.createElement("div", { className: "empty" },
                React.createElement("h2", null, t("providers_table.empty.no_invoices"))));
        }
        if (items.length === 0) {
            return (React.createElement("div", { className: "empty" },
                React.createElement("h2", null, t("providers_table.empty.title")),
                React.createElement("p", null, t("providers_table.empty.text")),
                React.createElement("a", { onClick: onReset, className: "button button__white" }, t("providers_table.empty.clear"))));
        }
        return (React.createElement("div", { className: "main-page-table content-table" },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort || "guid", sortName: "guid", text: "invoices_table.invoice_id", onSelect: handleSelectSort })),
                        isAdminOrManager && (React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "service_provider", text: "invoices_table.tutor_name", onSelect: handleSelectSort }))),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "client", text: "invoices_table.client_name", onSelect: handleSelectSort })),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "amount", text: "invoices_table.invoice_total", onSelect: handleSelectSort })),
                        React.createElement("th", null, t("invoices_table.service_provider_part")),
                        isAdminOrManager && (React.createElement("th", null, t("invoices_table.manager_part"))),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "status", text: "invoices_table.status", onSelect: handleSelectSort })),
                        React.createElement("th", null,
                            React.createElement(sort_title_1.default, { currentSort: currentSort, sortName: "created_at", text: "invoices_table.created_at", onSelect: handleSelectSort })))),
                React.createElement("tbody", null, items.map(function (item, key) { return (React.createElement("tr", { key: key, onClick: _this.handleClickRow.bind(_this, item.id) },
                    React.createElement("td", null, item.guid),
                    isAdminOrManager && React.createElement("td", null, item.service_provider_name),
                    React.createElement("td", null, item.client_name),
                    React.createElement("td", null,
                        "$",
                        (+item.amount).toFixed(2)),
                    React.createElement("td", null,
                        "$",
                        (+item.service_provider_part).toFixed(2)),
                    isAdminOrManager && React.createElement("td", null,
                        "$",
                        (+item.manager_part).toFixed(2)),
                    React.createElement("td", null, invoice_service_1.isInvoicePaidManually(item)
                        ? t("invoice.statuses.manually_paid")
                        : lodash_1.startCase(invoice_1.INVOICE_STATUSES_NAMES[item.status])),
                    React.createElement("td", { className: "with-child-component" }, item.created_at))); })))));
    };
    return InvoicesTable;
}(React.Component));
exports.default = react_i18next_1.translate()(react_router_dom_1.withRouter(InvoicesTable));
