"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("config/api");
var params_1 = require("./helpers/params");
var queryString = require("query-string");
var errors_herlper_actions_1 = require("actions/errors_herlper_actions");
// TODO refactoring methods, a lot of copypast here
var InvoiceApi = /** @class */ (function () {
    function InvoiceApi() {
    }
    InvoiceApi.getList = function (query) {
        query =
            "?" + queryString.stringify(query, {
                arrayFormat: "bracket"
            }) || "";
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "preinvoices/" + query, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    InvoiceApi.getPreinvoiceStatuses = function () {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "preinvoice_statuses", __assign({}, params_1.getDefaultParams(), { method: "GET" }));
    };
    InvoiceApi.getInvoice = function (id, params) {
        var query = "?" + queryString.stringify(params, {
            arrayFormat: "bracket"
        }) || "";
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "preinvoices/" + id + "/" + query, __assign({}, params_1.getDefaultParams(), { method: "GET" }));
    };
    InvoiceApi.updateInvoice = function (id, data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "preinvoices/" + id, __assign({}, params_1.getDefaultParams(), { body: JSON.stringify({ preinvoice: data }), method: "PUT" }));
    };
    InvoiceApi.getInvoiceByUuid = function (uuid) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "public_preinvoices/" + uuid, __assign({}, params_1.getDefaultParams(true), { method: "GET" }));
    };
    InvoiceApi.invoiceMarkAsPaid = function (id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "preinvoices/" + id + "/mark_as_paid", __assign({}, params_1.getDefaultParams(), { method: "POST" }));
    };
    InvoiceApi.invoiceCancel = function (id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "preinvoices/" + id + "/cancel", __assign({}, params_1.getDefaultParams(), { method: "POST" }));
    };
    InvoiceApi.invoiceSend = function (id, client_email) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "preinvoices/" + id + "/send", __assign({}, params_1.getDefaultParams(), { method: "POST", body: JSON.stringify({ client_email: client_email }) }));
    };
    return InvoiceApi;
}());
exports.InvoiceApi = InvoiceApi;
