"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("redux");
var redux_form_1 = require("redux-form");
var lodash_1 = require("lodash");
var service_provider_1 = require("actions/service_provider");
var wrapper_1 = require("components/table/wrapper");
var _1 = require(".");
var lists_1 = require("constants/lists");
var ProvidersTableContainer = /** @class */ (function (_super) {
    __extends(ProvidersTableContainer, _super);
    function ProvidersTableContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProvidersTableContainer.prototype.render = function () {
        var _a = this.props, getServiceProvidersListAction = _a.getServiceProvidersListAction, isFilterEmpty = _a.isFilterEmpty;
        return (React.createElement(wrapper_1.default, { entity: lists_1.LIST_ENTITIES.ALL_PROVIDERS_PAGINATED_LIST, updateTable: getServiceProvidersListAction, withPagination: true, filtrationFormName: "providerFilter" }, function (tableProps) { return (React.createElement(_1.default, __assign({ isFilterEmpty: isFilterEmpty }, tableProps))); }));
    };
    return ProvidersTableContainer;
}(React.Component));
exports.default = react_redux_1.connect(function (state) { return ({
    isFilterEmpty: lodash_1.isEmpty(redux_form_1.getFormValues("providerFilter")(state))
}); }, function (dispatch) { return ({
    getServiceProvidersListAction: redux_1.bindActionCreators(service_provider_1.getServiceProvidersList, dispatch)
}); })(ProvidersTableContainer);
