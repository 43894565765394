"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var main_1 = require("pages/main");
var login_1 = require("pages/auth/login");
var reset_password_1 = require("pages/auth/reset_password");
var providers_1 = require("pages/providers");
var provider_add_1 = require("pages/provider_add");
var provider_view_1 = require("pages/provider_view");
var clients_1 = require("pages/clients");
var client_add_1 = require("pages/client_add");
var client_view_1 = require("pages/client_view");
var container_1 = require("pages/user_details/container");
var _404_1 = require("pages/error_pages/404");
var _403_1 = require("pages/error_pages/403");
var _500_1 = require("pages/error_pages/500");
var expired_link_1 = require("pages/error_pages/expired_link");
var user_1 = require("constants/user");
var invoices_1 = require("pages/invoices");
var invoice_view_1 = require("pages/invoice_view");
var client_invoice_1 = require("pages/client_invoice");
var invoice_edit_1 = require("pages/invoice_edit");
var container_2 = require("pages/user_schedule_availability/container");
var routes = [
    {
        component: login_1.default,
        path: "/login"
    },
    {
        component: reset_password_1.default,
        path: "/reset_password"
    },
    {
        component: client_invoice_1.default,
        path: "/client_invoice/:uuid"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin],
        component: provider_add_1.default,
        path: "/providers/add"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin],
        component: provider_add_1.default,
        path: "/providers/edit/:id"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin],
        component: provider_view_1.default,
        path: "/providers/view/:id"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin],
        component: providers_1.default,
        path: "/providers"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin],
        component: client_add_1.default,
        path: "/clients/add"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin, user_1.Role.serviceProvider],
        component: client_add_1.default,
        path: "/clients/edit/:id"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin, user_1.Role.serviceProvider],
        component: client_view_1.default,
        path: "/clients/view/:id"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin, user_1.Role.serviceProvider],
        component: clients_1.default,
        path: "/clients"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin, user_1.Role.serviceProvider],
        component: container_1.default,
        path: "/user_details"
    },
    {
        role: [user_1.Role.serviceProvider],
        component: container_2.default,
        path: "/my_schedule_availability"
    },
    {
        role: [user_1.Role.serviceProvider],
        component: invoice_edit_1.default,
        path: "/invoices/edit/:id"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin, user_1.Role.serviceProvider],
        component: invoice_view_1.default,
        path: "/invoices/view/:id"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin, user_1.Role.serviceProvider],
        component: invoices_1.default,
        path: "/invoices"
    },
    {
        role: [user_1.Role.manager, user_1.Role.admin, user_1.Role.serviceProvider],
        component: main_1.default,
        path: "/"
    },
    {
        component: _403_1.default,
        path: "/403"
    },
    {
        component: _500_1.default,
        path: "/500"
    },
    {
        component: expired_link_1.default,
        path: "/expired_link"
    },
    {
        component: _404_1.default
    }
];
exports.default = routes;
