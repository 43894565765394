"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("redux");
var redux_form_1 = require("redux-form");
var user_1 = require("./user");
var client_1 = require("./client");
var billing_1 = require("./billing");
var service_provider_1 = require("./service_provider");
var sessions_1 = require("./sessions");
var reducer_1 = require("./lists/reducer");
var status_1 = require("./invoice/status");
var client_invoice_1 = require("./invoice/client_invoice");
var user_2 = require("constants/user");
var appReducer = redux_1.combineReducers({
    form: redux_form_1.reducer,
    user: user_1.default,
    serviceProvider: service_provider_1.default,
    client: client_1.default,
    sessions: sessions_1.default,
    lists: reducer_1.default,
    billing: billing_1.default,
    invoiceStatus: status_1.default,
    clientInvoice: client_invoice_1.default
});
var rootReducer = function (state, action) {
    if (action.type === user_2.USER_CONST.FORCE_LOGOUT ||
        action.type === user_2.USER_CONST.LOGOUT_SUCCESS ||
        action.type === user_2.USER_CONST.LOGOUT_FAIL) {
        state = undefined;
    }
    return appReducer(state, action);
};
exports.default = rootReducer;
