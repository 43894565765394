"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
// TODO add correct typings for input and output
function prepareErrors(data, status) {
    var result = data.errors ? _.cloneDeep(data.errors) : {};
    result.type = data.error_type;
    if (data.message || data.error) {
        result._error = data.message || data.error;
    }
    if (status === 409) {
        result.reason = "conflict";
    }
    return result;
}
exports.prepareErrors = prepareErrors;
// TODO add correct typings for input and output
function changeEmailErrorToGeneral(data) {
    handleContactError(data, "contact"); // sp
    handleContactError(data, "contacts"); // client
    return data;
}
exports.changeEmailErrorToGeneral = changeEmailErrorToGeneral;
var handleContactError = function (data, key) {
    if (data[key] &&
        data[key].emails &&
        data[key].emails[0].includes("Profile(s) with")) {
        data._error = { "contact.emails": data[key].emails[0] };
        delete data[key].emails;
    }
    return data;
};
