"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var component_1 = require("components/schedule_preferences/component");
require("./style.less");
exports.EditProviderFormFields = function (_a) {
    var t = _a.t, error = _a.error, daysSelected = _a.daysSelected, changeDayValue = _a.changeDayValue, dayPrefix = _a.dayPrefix, otherProps = __rest(_a, ["t", "error", "daysSelected", "changeDayValue", "dayPrefix"]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "static-block" },
            React.createElement("h6", { className: "form-title" }, t("provider_add_form.fields.shedule_preference.label")),
            React.createElement(component_1.default, { daysSelected: daysSelected, changeDayValue: changeDayValue, dayPrefix: dayPrefix }))));
};
