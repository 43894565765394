"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./../style.less");
var description_table_1 = require("../../../../invoice_edit/item/description_table");
var InvoiceDetails = /** @class */ (function (_super) {
    __extends(InvoiceDetails, _super);
    function InvoiceDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onNext = function () {
            return _this.props.onNext();
        };
        return _this;
    }
    InvoiceDetails.prototype.render = function () {
        var _a = this.props, t = _a.t, invoice = _a.invoice, onNext = _a.onNext;
        return invoice ? (React.createElement("div", { className: "invoice_details " },
            invoice.note && (React.createElement("div", { className: "invoice-wizard-step description-wrapper" },
                React.createElement("p", null, invoice.note))),
            React.createElement("div", { className: "invoice-table-wrapper" },
                React.createElement(description_table_1.default, { isEditable: false, itemsCurrentValues: invoice.items, summ: parseFloat(String(invoice.amount)) })),
            onNext && (React.createElement("div", { className: "wizard-buttons-panel" },
                React.createElement("div", { className: "buttons-container buttons-container__right" },
                    React.createElement("button", { onClick: this.onNext, className: "button button__red button__next" }, t("wizard_default.buttons.next"))))))) : null;
    };
    InvoiceDetails = __decorate([
        react_i18next_1.translate()
    ], InvoiceDetails);
    return InvoiceDetails;
}(React.Component));
exports.default = InvoiceDetails;
