"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var ServerErrorPage = /** @class */ (function (_super) {
    __extends(ServerErrorPage, _super);
    function ServerErrorPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ServerErrorPage.prototype.render = function () {
        var t = this.props.t;
        return (React.createElement("div", { className: "empty empty__error-page" },
            React.createElement("h2", null, "500"),
            React.createElement("p", null, t("error_page.server_error")),
            React.createElement(react_router_dom_1.Link, { to: "/", className: "button button__white" }, t("error_page.go_home"))));
    };
    ServerErrorPage = __decorate([
        react_i18next_1.translate()
    ], ServerErrorPage);
    return ServerErrorPage;
}(React.Component));
exports.default = ServerErrorPage;
