"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _1 = require(".");
var react_datepicker_1 = require("react-datepicker");
var moment = require("moment");
var classnames = require("classnames");
var redux_form_1 = require("redux-form");
var FieldDatePickerRender = /** @class */ (function (_super) {
    __extends(FieldDatePickerRender, _super);
    function FieldDatePickerRender() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.updateState = function (date) {
            var momentValue = moment(date);
            _this.props.input.onChange(momentValue.isValid() ? momentValue : date);
            if (_this.props.onChangeCallback) {
                _this.props.onChangeCallback(moment(date));
            }
        };
        _this.handleChange = function (date) {
            _this.updateState(date);
        };
        _this.handleChangeRaw = function (e) {
            if (_this.props.disableTyping) {
                e.preventDefault();
                return;
            }
            var value = e.target.value;
            if (value === "tomorrow") {
                var tomorrow = moment().add(1, "day");
                _this.updateState(tomorrow);
            }
            else if (value === "yesterday") {
                var yesterday = moment().add(-1, "day");
                _this.updateState(yesterday);
            }
            else {
                _this.updateState(value);
                if (_this.props.meta.touched === false) {
                    _this.props.meta.dispatch(redux_form_1.touch(_this.props.meta.form, _this.props.input.name));
                    _this.props.meta.dispatch(redux_form_1.focus(_this.props.meta.form, _this.props.input.name));
                }
            }
        };
        _this.onSelect = function () {
            _this.props.meta.dispatch(redux_form_1.touch(_this.props.meta.form, _this.props.input.name));
            _this.props.meta.dispatch(redux_form_1.focus(_this.props.meta.form, _this.props.input.name));
        };
        _this.renderInput = function (props) {
            var value = props.input.value;
            var isErrors = props.meta.touched && props.meta.error;
            var momentValue = moment.isMoment(value) ? value : moment(value);
            return (React.createElement(react_datepicker_1.default, { name: props.input.name, selected: momentValue.isValid && momentValue.isValid() ? momentValue : moment(), value: value, onChangeRaw: _this.handleChangeRaw, onSelect: function (event) {
                    _this.onSelect();
                }, onChange: _this.handleChange, showYearDropdown: props.showYearDropdown, className: classnames({
                    "input-error": isErrors
                }), minDate: props.minDate, maxDate: props.maxDate, minTime: props.minTime, maxTime: props.maxTime, placeholderText: props.placeholder, 
                // Please do not change these props, because in other configuration TIME picker are broken
                autoFocus: false, preventOpenOnFocus: true, shouldCloseOnSelect: !props.showTimeSelect, disabled: props.disabled, showTimeSelect: props.showTimeSelect, showTimeSelectOnly: props.showTimeSelectOnly, timeFormat: props.timeFormat, timeIntervals: props.timeIntervals, dateFormat: props.dateFormat || "MM/DD/YYYY", selectsStart: props.selectsStart, selectsEnd: props.selectsEnd, popperPlacement: props.popperPlacement }));
        };
        return _this;
    }
    FieldDatePickerRender.prototype.componentDidMount = function () {
        this.setState({ value: this.props.input.value });
    };
    /**
     * @method render
     */
    FieldDatePickerRender.prototype.render = function () {
        var _this = this;
        var props = this.props;
        var wrapper = function (props) {
            return props.showTimeSelect ? (React.createElement("div", { className: "datepicker-with-time" }, _this.renderInput(props))) : (React.createElement(React.Fragment, null, _this.renderInput(props)));
        };
        return React.createElement(_1.default, __assign({}, props, { renderInput: wrapper }));
    };
    return FieldDatePickerRender;
}(React.Component));
exports.default = FieldDatePickerRender;
