"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GenderHelper = /** @class */ (function () {
    function GenderHelper() {
    }
    /**
     * Currently this options doesn't implemendeted on backend
     */
    GenderHelper.getGendersWithNoPreference = function (ranges) {
        return (ranges || [])
            .map(function (item) {
            return {
                label: item.title,
                value: item.name,
                id: item.name
            };
        })
            .filter(function (item) { return item.label !== "No preference"; });
    };
    return GenderHelper;
}());
exports.default = GenderHelper;
