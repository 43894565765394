"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_select_1 = require("react-select");
var react_toastify_1 = require("react-toastify");
var popup_1 = require("components/popup");
require("./style.less");
var ReassignManager = /** @class */ (function (_super) {
    __extends(ReassignManager, _super);
    function ReassignManager() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            openModal: false,
            managers: [],
            manager: undefined
        };
        _this.handleOpenModal = function () {
            _this.setState({
                openModal: true
            });
        };
        _this.handleCloseModal = function () {
            _this.setState({
                openModal: false
            });
        };
        _this.handleSelect = function (selected) {
            _this.setState({ manager: selected });
        };
        _this.handleReassign = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, handleReassign, t, id, changeManager, managerId, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, handleReassign = _a.handleReassign, t = _a.t, id = _a.id, changeManager = _a.changeManager;
                        managerId = this.state.manager.value;
                        if (!(handleReassign && id && managerId && t)) return [3 /*break*/, 5];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, handleReassign(id, managerId)];
                    case 2:
                        _b.sent();
                        react_toastify_1.toast.success(t("client_view.reasign_success") + this.state.manager.label, {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        changeManager(this.state.manager.label);
                        this.setState({ manager: null });
                        this.getManagersList();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        react_toastify_1.toast.error(error_1, {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 4:
                        this.handleCloseModal();
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.getManagersList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, getManagersList, id, managers;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, getManagersList = _a.getManagersList, id = _a.id;
                        if (!(getManagersList && id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getManagersList(id)];
                    case 1:
                        managers = (_b.sent()).managers.map(function (manager) { return ({
                            value: manager.id,
                            label: manager.first_name + " " + manager.last_name
                        }); });
                        this.setState({ managers: managers });
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ReassignManager.prototype.render = function () {
        var t = this.props.t;
        var _a = this.state, openModal = _a.openModal, managers = _a.managers, manager = _a.manager;
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { className: "button button__transparent", onClick: this.handleOpenModal },
                React.createElement("i", { className: "fas fa-user-cog margin-right-10" }),
                t("provider_view.reasign")),
            React.createElement(popup_1.default, { openned: openModal, onClose: this.handleCloseModal },
                React.createElement("div", { className: "reasign-modal" },
                    React.createElement("h2", { className: "modal-title" }, t("reasign_manager.title")),
                    React.createElement("div", { className: "field" },
                        React.createElement("label", null, t("reasign_manager.label")),
                        React.createElement(react_select_1.default, { options: managers, value: manager, onChange: this.handleSelect })),
                    React.createElement("button", { className: "button button__red full-width", onClick: this.handleReassign }, "Save")))));
    };
    ReassignManager.prototype.componentDidMount = function () {
        this.getManagersList();
    };
    ReassignManager = __decorate([
        react_i18next_1.translate()
    ], ReassignManager);
    return ReassignManager;
}(React.Component));
exports.default = ReassignManager;
