"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var moment = require("moment");
var prefered_time_1 = require("../../../helpers/prefered_time");
var currentYear = new Date().getFullYear();
var getValidators = function (t) {
    var validators = {
        email: function (value) {
            return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
                ? t("validate.email")
                : undefined;
        },
        phone: function (value) {
            return value && !/^(0|[1-9][0-9]{9})$/i.test(value)
                ? t("validate.phone")
                : undefined;
        },
        maxMin: function (value, max, min, message) {
            if (!value)
                return undefined;
            var stringValue = value.toString();
            return stringValue.length > max || stringValue.length < min
                ? message : undefined;
        },
        maxMin_4_4: function (value) {
            return validators.maxMin(value, 4, 4, t("validate.experience_length", { num: 4 }));
        },
        maxMin_3_0: function (value) {
            return validators.maxMin(value, 3, 0, t("validate.age_length", { num: 3 }));
        },
        maxNumber50: function (value) {
            return _.parseInt(value) <= 50
                ? undefined
                : "" + t("validate.max_value_50");
        },
        minYear2010: function (value) {
            return _.parseInt(moment(value).format("YYYY")) >= 2010
                ? undefined
                : "" + t("validate.min_year_2010");
        },
        maxNumber99: function (value) {
            return _.parseInt(value) <= 99
                ? undefined
                : "" + t("validate.max_value_99");
        },
        minNumber1: function (value) {
            return _.parseInt(value) >= 1
                ? undefined
                : "" + t("validate.min_value_1");
        },
        minNumber0: function (value) {
            return _.parseInt(value) >= 0 ? undefined : t("validate.min_value_0");
        },
        minNumberMore0: function (value) {
            if (!value || value === "")
                return undefined;
            return parseFloat(value) > 0 ? undefined : t("validate.min_value_0");
        },
        maxLength10: function (value) {
            return !value || value.length < 11
                ? undefined
                : "" + t("validate.max_length_10");
        },
        twoSymbolsAfterComa: function (value) {
            return value && /^\d*(\.\d{0,2})?$/.test(value)
                ? undefined
                : t("validate.two_symbols");
        },
        fromYearToNow: function (value, minimalYear) {
            var intValue = parseInt(value);
            if (intValue > currentYear)
                return t("validate.should_be_less_then") + " " + currentYear;
            if (intValue < minimalYear)
                return t("validate.should_be_more_then") + " " + minimalYear;
            return undefined;
        },
        from1930ToNow: function (value) {
            return validators.fromYearToNow(value, 1930);
        },
        passwordMaxMin: function (value) {
            var max = 28;
            var min = 6;
            return validators.maxMin(value, max, min, t("validate.password_length", { max: max, min: min }));
        },
        required: function (value) {
            return value ? undefined : t("validate.required");
        },
        password: function (value, values) {
            return values.password_confirmation === values.password
                ? undefined
                : t("validate.password_not_match");
        },
        number: function (value) {
            return !isNaN(parseFloat(value)) && isFinite(value)
                ? undefined
                : t("validate.number");
        },
        integer: function (value) {
            if (!value || value === "")
                return undefined;
            return !isNaN(parseFloat(value)) && isFinite(value) && /^\d+$/.test(value)
                ? undefined
                : t("validate.integer");
        },
        oneTimeAfterBeginAt: function (value, otherValues) {
            if (!value ||
                !moment.isMoment(value) ||
                !otherValues.oneTime.begin_at ||
                !moment.isMoment(otherValues.oneTime.begin_at)) {
                return undefined;
            }
            return value.isAfter(otherValues.oneTime.begin_at)
                ? undefined
                : t("validate.after_begin_at");
        },
        recurringAfterBeginAt: function (value, otherValues) {
            if (!value ||
                !moment.isMoment(value) ||
                !otherValues.recurring.begin_at ||
                !moment.isMoment(otherValues.recurring.begin_at)) {
                return undefined;
            }
            return value.isAfter(otherValues.recurring.begin_at)
                ? undefined
                : t("validate.after_starts_on");
        },
        diffWithBeginAtLessThenYear: function (value, otherValues) {
            if (!value ||
                !moment.isMoment(value) ||
                !otherValues.recurring.begin_at ||
                !moment.isMoment(otherValues.recurring.begin_at)) {
                return undefined;
            }
            return value.diff(otherValues.recurring.begin_at, "years") === 0
                ? undefined
                : t("validate.diff_more_then_year");
        },
        recurringAfterBeginTime: function (value, otherValues) {
            if (!value ||
                !moment.isMoment(value) ||
                !otherValues.recurring.begin_time ||
                !moment.isMoment(otherValues.recurring.begin_time)) {
                return undefined;
            }
            return prefered_time_1.minutesOfDay(value) >
                prefered_time_1.minutesOfDay(otherValues.recurring.begin_time)
                ? undefined
                : t("validate.after_start_time");
        },
        afterNow: function (value) {
            if (!value || !moment.isMoment(value)) {
                return undefined;
            }
            return value.isAfter(moment()) ? undefined : t("validate.after_now");
        },
        moment: function (value) {
            if (!value || value === "")
                return undefined;
            return moment.isMoment(value) && value.isValid()
                ? undefined
                : t("validate.invalid_date");
        },
        checkbox: function (value, otherValues) {
            return value && value.length ? undefined : t("validate.choose_one_day");
        },
        evenOneRecurringSessionDay: function (value, otherValues) {
            var er = otherValues.recurring.days.find(function (day) { return day; })
                ? undefined
                : t("validate.choose_one_day");
            return er;
        }
    };
    return validators;
};
exports.getValidators = getValidators;
