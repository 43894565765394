"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_form_1 = require("redux-form");
var fields_1 = require("components/form/fields");
var select_1 = require("components/form/fields/select");
var handle_1 = require("./handle");
var react_sortable_hoc_1 = require("react-sortable-hoc");
var input_1 = require("config/input");
exports.default = react_sortable_hoc_1.SortableElement(function (props) {
    var itemIndex = props.itemIndex, validators = props.validators, member = props.member, degrees = props.degrees, t = props.t, onHandleRemoveEducation = props.onHandleRemoveEducation, fieldsLength = props.fieldsLength;
    function getDegreeOptions() {
        return degrees.map(function (item) {
            return Object.assign(item, {
                value: item.id,
                label: item.title
            });
        });
    }
    return (React.createElement("div", { className: "education" },
        fieldsLength !== 1 ? React.createElement(handle_1.default, null) : null,
        React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.education.first_name.label"), component: select_1.default, options: getDegreeOptions(), name: member + ".degree", clearable: true, type: "text", placeholder: t("provider_add_form.fields.education.first_name.placeholder") }),
        React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.education.school.label"), component: fields_1.default, name: member + ".school", type: "text", maxLength: input_1.default.school.maxLength, placeholder: t("provider_add_form.fields.education.school.placeholder") }),
        React.createElement(redux_form_1.Field, { label: t("provider_add_form.fields.education.graduate_year.label"), component: fields_1.default, name: member + ".graduate_year", validate: [validators.maxMin_4_4, validators.from1930ToNow], type: "number", className: "year", maxlength: "4", placeholder: t("provider_add_form.fields.education.graduate_year.placeholder") }),
        fieldsLength !== 1 ? (React.createElement("button", { type: "button", className: "button button__transparent", onClick: function () { return onHandleRemoveEducation(itemIndex); } },
            React.createElement("i", { className: "fas fa-trash" }))) : null));
});
