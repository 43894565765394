"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var invoice_1 = require("constants/invoice");
var initialState = [];
function invoiceStatus(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case invoice_1.INVOICE_CONST.INVOICE_STATUS_GET_SUCCESS:
            return action.payload;
        case invoice_1.INVOICE_CONST.INVOICE_STATUS_GET_FAIL:
            return [];
        default:
            return state;
    }
}
exports.default = invoiceStatus;
