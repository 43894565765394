"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var FieldRender = /** @class */ (function (_super) {
    __extends(FieldRender, _super);
    function FieldRender() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FieldRender.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var props = this.props;
        var meta = props.meta;
        var isErrors = meta.touched && meta.error;
        var label = props.label;
        var require = props.require;
        var disabled = props.disabled;
        var withSign = props.withSign;
        var value, isCheckbox = props.type === "checkbox";
        if (isCheckbox) {
            value = props.input.value;
        }
        else {
            value =
                typeof props.input.value === "string" ||
                    typeof props.input.value === "number"
                    ? props.input.value
                    : "";
        }
        return (React.createElement("div", { className: classnames_1.default((_a = {
                    field: true
                },
                _a[props.extendedClass] = !!props.extendedClass,
                _a["field-with-sign percent"] = withSign === "%" && (meta.active || !!value.trim()),
                _a["field-with-sign dollar"] = withSign === "$",
                _a)) },
            !isCheckbox && label ? (React.createElement("label", null,
                label,
                " ",
                require ? React.createElement("span", { className: "require" }, "*") : null)) : null,
            !props.renderInput ? (React.createElement("input", __assign({ ref: function (item) { return (_this.input = item); }, className: classnames_1.default((_b = {
                        "input-error": isErrors
                    },
                    _b[props.className] = !!props.className,
                    _b)), autoFocus: props.autoFocus }, props.input, { maxLength: props.maxLength, value: value, disabled: disabled, placeholder: props.placeholder, type: props.type, min: props.min, max: props.max, step: props.step, id: props.id, checked: props.checked }))) : (props.renderInput(props)),
            isCheckbox && label && (React.createElement("label", { className: "inline", htmlFor: this.props.id }, label)),
            React.createElement("span", { className: "error" }, isErrors && meta.error),
            props.renderInner ? props.renderInner(props) : null,
            props.underFieldActionButton ? (React.createElement("div", { className: classnames_1.default({
                    "underfield-button": true,
                    "with-error": isErrors
                }) }, props.underFieldActionButton)) : null));
    };
    return FieldRender;
}(React.Component));
exports.default = FieldRender;
