"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames = require("classnames");
var SettingsIcon = /** @class */ (function (_super) {
    __extends(SettingsIcon, _super);
    function SettingsIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SettingsIcon.prototype.render = function () {
        var extendedClass = this.props.extendedClass;
        return (React.createElement("svg", { version: "1.1", className: classnames("icons-svg", extendedClass), xmlns: "http://www.w3.org/2000/svg", x: "0px", y: "0px", width: "36px", height: "36px", viewBox: "0 0 36 36", enableBackground: "new 0 0 36 36" },
            React.createElement("g", null,
                React.createElement("path", { d: "M18,0C8.059,0,0,8.059,0,18s8.059,18,18,18s18-8.059,18-18S27.941,0,18,0z M18,34C9.178,34,2,26.822,2,18\n                    S9.178,2,18,2s16,7.178,16,16S26.822,34,18,34z" }),
                React.createElement("path", { d: "M7,13h3.551c0.232,1.14,1.242,2,2.449,2s2.217-0.86,2.449-2H29c0.276,0,0.5-0.224,0.5-0.5S29.276,12,29,12\n                    H15.449c-0.232-1.14-1.242-2-2.449-2s-2.217,0.86-2.449,2H7c-0.276,0-0.5,0.224-0.5,0.5S6.724,13,7,13z" }),
                React.createElement("path", { d: "M29,18h-2.551c-0.232-1.14-1.242-2-2.449-2s-2.217,0.86-2.449,2H7c-0.276,0-0.5,0.224-0.5,0.5\n                    S6.724,19,7,19h14.551c0.232,1.14,1.242,2,2.449,2s2.217-0.86,2.449-2H29c0.276,0,0.5-0.224,0.5-0.5S29.276,18,29,18z" }),
                React.createElement("path", { d: "M29,24H15.449c-0.232-1.14-1.242-2-2.449-2s-2.217,0.86-2.449,2H7c-0.276,0-0.5,0.224-0.5,0.5\n                    S6.724,25,7,25h3.551c0.232,1.14,1.242,2,2.449,2s2.217-0.86,2.449-2H29c0.276,0,0.5-0.224,0.5-0.5S29.276,24,29,24z" }))));
    };
    return SettingsIcon;
}(React.Component));
exports.default = SettingsIcon;
