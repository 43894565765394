"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var errors_1 = require("api/helpers/errors");
var client_1 = require("api/client");
var client_2 = require("constants/client");
var lists_1 = require("constants/lists");
var errors_herlper_actions_1 = require("./errors_herlper_actions");
function createClient(data) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client_1.ClientApi.create(data)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                payload: resultData,
                                type: client_2.CLIENT_CONST.CLIENT_CREATE_SUCCESS
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                payload: errors_1.changeEmailErrorToGeneral(errors_1.prepareErrors(resultData)),
                                type: client_2.CLIENT_CONST.CLIENT_CREATE_FAIL
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.createClient = createClient;
function updateClient(id, data) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client_1.ClientApi.update(id, data)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                payload: resultData.client,
                                type: client_2.CLIENT_CONST.CLIENT_UPDATE_SUCCESS
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                payload: errors_1.changeEmailErrorToGeneral(errors_1.prepareErrors(resultData)),
                                type: client_2.CLIENT_CONST.CLIENT_UPDATE_FAIL
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.updateClient = updateClient;
function getClientWorkspace() {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client_1.ClientApi.getWorkspace()];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_GET_WORKSPACE_SUCCESS,
                                payload: resultData
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_GET_WORKSPACE_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.getClientWorkspace = getClientWorkspace;
function getClientList(query, entity) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var newQuery, result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newQuery = Object.assign({}, query);
                    dispatch({
                        type: lists_1.LIST_ACTIONS.GET_LIST,
                        payload: { entity: entity }
                    });
                    return [4 /*yield*/, client_1.ClientApi.getList(query)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: lists_1.LIST_ACTIONS.GET_LIST_SUCCESS,
                                payload: {
                                    result: resultData,
                                    query: newQuery,
                                    entity: entity
                                }
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: lists_1.LIST_ACTIONS.GET_LIST_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.getClientList = getClientList;
function getClient(id) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client_1.ClientApi.getClient(id)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_GET_SUCCESS,
                                payload: resultData.client
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_GET_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.getClient = getClient;
function archive(id) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client_1.ClientApi.archive(id)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_ARCHIVE_SUCCESS,
                                payload: resultData.client
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_ARCHIVE_FAIL,
                                payload: resultData.error
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.archive = archive;
function restore(id) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client_1.ClientApi.restore(id)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_RESTORE_SUCCESS,
                                payload: resultData.client
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_RESTORE_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.restore = restore;
function reasign(id, manager_id) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client_1.ClientApi.reasign(id, manager_id)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_REASIGN_SUCCESS,
                                payload: resultData.client
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.CLIENT_REASIGN_FAIL,
                                payload: resultData.error
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.reasign = reasign;
function assignClientToProvider(clientId, providerId) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client_1.ClientApi.assignClientToProvider(clientId, providerId)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.ASSIGN_CLIENT_TO_PROVIDER_SUCCESS,
                                payload: resultData.client // todo
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.ASSIGN_CLIENT_TO_PROVIDER_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.assignClientToProvider = assignClientToProvider;
function unassignClientFromProvider(clientId) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var result, resultData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, client_1.ClientApi.unassignClientFromProvider(clientId)];
                case 1:
                    result = _a.sent();
                    return [4 /*yield*/, result.json()];
                case 2:
                    resultData = _a.sent();
                    if (result.ok) {
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.UNASSIGN_CLIENT_FROM_PROVIDER_SUCCESS,
                                payload: resultData.client // todo
                            })];
                    }
                    else {
                        errors_herlper_actions_1.handleUnauthorised(dispatch, result);
                        return [2 /*return*/, dispatch({
                                type: client_2.CLIENT_CONST.UNASSIGN_CLIENT_FROM_PROVIDER_FAIL,
                                payload: errors_1.prepareErrors(resultData)
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
exports.unassignClientFromProvider = unassignClientFromProvider;
