"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var ClientAddActionPanel = /** @class */ (function (_super) {
    __extends(ClientAddActionPanel, _super);
    function ClientAddActionPanel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientAddActionPanel.prototype.render = function () {
        var _a = this.props, title = _a.title, subTitle = _a.subTitle, t = _a.t, onSubmit = _a.onSubmit, onCancel = _a.onCancel, isSubmit = _a.isSubmit, submitButtonText = _a.submitButtonText;
        return t ? (React.createElement("div", { className: "action-panel" },
            React.createElement("div", { className: "center-content" },
                React.createElement("div", { className: "description" },
                    title ? React.createElement("h2", { className: "action-panel-title" }, title) : null,
                    subTitle ? (React.createElement("p", { className: "action-panel-subtitle" }, subTitle)) : null),
                React.createElement("div", { className: "actions" },
                    onSubmit && submitButtonText ? (React.createElement("a", { className: "button button__red " + (isSubmit ? "" : "disabled"), onClick: onSubmit }, submitButtonText)) : null,
                    onCancel ? (React.createElement("a", { className: "button button__white", onClick: onCancel }, t("action_panel.buttons.cancel"))) : null)))) : null;
    };
    ClientAddActionPanel = __decorate([
        react_i18next_1.translate()
    ], ClientAddActionPanel);
    return ClientAddActionPanel;
}(React.Component));
exports.default = ClientAddActionPanel;
