"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var redux_form_1 = require("redux-form");
var React = require("react");
var react_i18next_1 = require("react-i18next");
var fields_1 = require("components/form/fields");
var masks_1 = require("components/form/masks");
var classnames = require("classnames");
var RenderPhone = /** @class */ (function (_super) {
    __extends(RenderPhone, _super);
    function RenderPhone() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleAdd = function () {
            var fields = _this.props.fields;
            if (fields) {
                fields.push("");
            }
        };
        _this.handleRemove = function (index) {
            var fields = _this.props.fields;
            if (fields) {
                fields.remove(index);
            }
        };
        return _this;
    }
    RenderPhone.prototype.render = function () {
        var _this = this;
        var _a = this.props, fields = _a.fields, t = _a.t, validators = _a.validators;
        return t ? (React.createElement("div", { className: "phones" },
            fields.map(function (member, index) { return (React.createElement("div", { className: "phone-item", key: index },
                React.createElement(redux_form_1.Field, __assign({ label: t("provider_add_form.fields.phone.label"), component: fields_1.default, require: "true", name: member, validate: [validators.required, validators.phone], type: "text", placeholder: t("provider_add_form.fields.phone.placeholder") }, masks_1.phoneMask)),
                React.createElement("button", { type: "button", className: classnames({
                        "button button__transparent": true,
                        "hidden-visible": index === 0
                    }), onClick: _this.handleRemove.bind(_this, index) },
                    React.createElement("i", { className: "fas fa-trash" })))); }),
            React.createElement("button", { className: "button button__transparent", type: "button", onClick: this.handleAdd }, t("provider_add_form.add_more")))) : null;
    };
    RenderPhone.prototype.componentDidMount = function () {
        var fields = this.props.fields;
        if (fields && fields.length === 0) {
            fields.push("");
        }
    };
    RenderPhone = __decorate([
        react_i18next_1.translate()
    ], RenderPhone);
    return RenderPhone;
}(React.Component));
exports.default = RenderPhone;
