"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("config/api");
var params_1 = require("./helpers/params");
var queryString = require("query-string");
var errors_herlper_actions_1 = require("actions/errors_herlper_actions");
// TODO refactoring methods, a lot of copypast here
var SessionApi = /** @class */ (function () {
    function SessionApi() {
    }
    SessionApi.create = function (data, isRecurring) {
        return errors_herlper_actions_1.wrappedFetch("" + api_1.default.HOST + (isRecurring ? "recurring_" : "") + "sessions", Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify({ session: data }),
            method: "POST"
        }));
    };
    SessionApi.edit = function (data, sessionId) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "sessions/" + sessionId, Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify({ session: data }),
            method: "PUT"
        }));
    };
    SessionApi.delete = function (sessionId) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "sessions/" + sessionId, Object.assign({}, params_1.getDefaultParams(), {
            method: "DELETE"
        }));
    };
    SessionApi.checkOverlaps = function (query, isRecurring) {
        query =
            "?" + queryString.stringify(query, {
                arrayFormat: "bracket"
            }) || "";
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "check_" + (isRecurring ? "recurring_" : "") + "session_overlaps/" + query, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    SessionApi.getFirstSession = function (query) {
        query =
            "?" + queryString.stringify(query, {
                arrayFormat: "bracket"
            }) || "";
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "first_session/" + query, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    SessionApi.getSessionsByDate = function (query) {
        query =
            "?" + queryString.stringify(query, {
                arrayFormat: "bracket"
            }) || "";
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "sessions/" + query, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    SessionApi.getSessions = function (query) {
        query =
            "?" + queryString.stringify(query, {
                arrayFormat: "bracket"
            }) || "";
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "sessions_per_date/" + query, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    return SessionApi;
}());
exports.SessionApi = SessionApi;
