"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var moment = require("moment");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var invoice_calculation_1 = require("helpers/invoice_calculation");
var invoice_1 = require("../../models/invoice");
var invoice_service_1 = require("../../services/invoice.service");
var Invoice = /** @class */ (function (_super) {
    __extends(Invoice, _super);
    function Invoice() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Invoice.prototype.render = function () {
        var _a = this.props, t = _a.t, invoice = _a.invoice, showCardFee = _a.showCardFee, isInternationalPayment = _a.isInternationalPayment;
        var dueDate = moment(invoice.due_date).format("DD MMMM YYYY");
        return invoice ? (React.createElement("div", { className: "invoice-wrapper" },
            React.createElement("h1", null,
                React.createElement("span", null, t("invoice.title.from")),
                React.createElement("br", null),
                invoice.provider_name),
            invoice_service_1.isInvoicePaid(invoice) && (React.createElement("div", { className: "paid-block" },
                React.createElement("img", { src: require("images/invoices/invoice_paid.png"), alt: "invoice_paid" }),
                invoice_service_1.isInvoicePaidBySystem(invoice) && (React.createElement("span", null, t("invoice.statuses.paid"))),
                invoice_service_1.isInvoicePaidManually(invoice) && (React.createElement("span", null, t("invoice.statuses.manually_paid"))),
                "$",
                parseFloat(invoice.amount).toFixed(2),
                " Paid",
                " ",
                invoice.paid_date &&
                    moment(invoice.paid_date).format("MMM DD, YYYY"))),
            invoice.status === invoice_1.INVOICE_STATUSES.IN_PROGRESS &&
                !!invoice.payment_id &&
                !!invoice.bank_account_id && (React.createElement("h2", null, t("invoice.statuses.expect_money_transfer"))),
            invoice.status === invoice_1.INVOICE_STATUSES.REJECTED && (React.createElement("h2", { className: "rejected" },
                t("invoice.title.rejected"),
                React.createElement("br", null),
                invoice.reject_reason ? (React.createElement("span", null,
                    t("invoice.statuses.reject_reason"),
                    invoice.reject_reason)) : null)),
            invoice.status === invoice_1.INVOICE_STATUSES.CANCELED && (React.createElement("h2", { className: "rejected" }, t("invoice.title.canceled"))),
            React.createElement("div", { className: "invoice-title-separator" }),
            React.createElement("div", { className: "white-table content-table" },
                React.createElement("table", { className: "invoice-main-table" },
                    React.createElement("tbody", null,
                        React.createElement("tr", { className: "not-selectable" },
                            React.createElement("td", { className: "main-table_title" }, t("invoice.table.bill_to")),
                            React.createElement("td", { className: "not-selectable" }, invoice.client_name)),
                        React.createElement("tr", { className: "not-selectable" },
                            React.createElement("td", { className: "main-table_title" }, t("invoice.table.number")),
                            React.createElement("td", { className: "not-selectable" }, invoice.guid)),
                        React.createElement("tr", { className: "not-selectable" },
                            React.createElement("td", { className: "main-table_title" }, t("invoice.table.amount")),
                            React.createElement("td", { className: "not-selectable" }, "$ " + invoice_calculation_1.default.amountToFixed(invoice.amount))),
                        showCardFee && invoice.card_fee_item && (React.createElement(React.Fragment, null,
                            React.createElement("tr", { className: "not-selectable" },
                                React.createElement("td", { className: "main-table_title" }, t("invoice.table.stripe_fee")),
                                React.createElement("td", { className: "not-selectable" }, "$ " + invoice_calculation_1.default.amountToFixed(isInternationalPayment ? invoice.international_card_fee_item.amount : invoice.card_fee_item.amount))),
                            React.createElement("tr", { className: "not-selectable" },
                                React.createElement("td", { className: "main-table_title" }, t("invoice.table.total_amount")),
                                React.createElement("td", { className: "not-selectable" }, "$ " + invoice_calculation_1.default.countTotalAmountForCard(invoice, isInternationalPayment))))),
                        React.createElement("tr", { className: "not-selectable" },
                            React.createElement("td", { className: "main-table_title" }, t("invoice.table.due_by")),
                            React.createElement("td", null, dueDate))))))) : null;
    };
    Invoice = __decorate([
        react_i18next_1.translate()
    ], Invoice);
    return Invoice;
}(React.Component));
exports.default = Invoice;
