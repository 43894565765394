"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SupervisorRatesHelper = /** @class */ (function () {
    function SupervisorRatesHelper() {
    }
    SupervisorRatesHelper.getOptions = function (values, pattern) {
        return (values || []).map(function (item) { return ({
            label: pattern(item),
            value: item
        }); });
    };
    return SupervisorRatesHelper;
}());
exports.SupervisorRatesHelper = SupervisorRatesHelper;
var TutorRatesHelper = /** @class */ (function () {
    function TutorRatesHelper() {
    }
    TutorRatesHelper.getOptions = function (values, pattern) {
        return (values || []).map(function (item) { return ({
            label: pattern(item),
            value: item
        }); });
    };
    return TutorRatesHelper;
}());
exports.TutorRatesHelper = TutorRatesHelper;
