"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var main_1 = require("layouts/main");
var private_1 = require("components/helpers/user/private");
var container_1 = require("./table/container");
var container_2 = require("./filter/container");
var lists_1 = require("constants/lists");
var page_title_1 = require("components/page_title");
var ClientsPage = /** @class */ (function (_super) {
    __extends(ClientsPage, _super);
    function ClientsPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientsPage.prototype.render = function () {
        var t = this.props.t;
        return (React.createElement(main_1.default, null,
            React.createElement("div", { className: "main-page" },
                React.createElement(page_title_1.default, { pageTitle: t("page_titles.clients") }),
                React.createElement("div", { className: "main-page-header" },
                    React.createElement("div", { className: "center-content" },
                        React.createElement(container_2.default, { entityName: lists_1.LIST_ENTITIES.ALL_CLIENTS_PAGINATED_LIST }))),
                React.createElement("div", { className: "main-page-data" },
                    React.createElement(container_1.default, { entity: lists_1.LIST_ENTITIES.ALL_CLIENTS_PAGINATED_LIST })))));
    };
    return ClientsPage;
}(React.Component));
exports.default = private_1.default(react_i18next_1.translate()(ClientsPage));
