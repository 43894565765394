"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var popup_1 = require("components/popup");
exports.BillingPopup = function (_a) {
    var isBillingPopupOpened = _a.isBillingPopupOpened, handleCloseBillingPopup = _a.handleCloseBillingPopup, handleSwitchBilling = _a.handleSwitchBilling, activeBilling = _a.activeBilling, t = _a.t;
    return (React.createElement(popup_1.default, { openned: isBillingPopupOpened, onClose: handleCloseBillingPopup },
        React.createElement("div", null,
            React.createElement("h2", { className: "modal-title" }, t("modal.title")),
            React.createElement("p", { className: "modal-text" }, t(activeBilling
                ? "provider_view.billing_popup_text_deactivate"
                : "provider_view.billing_popup_text_activate")),
            React.createElement("div", { className: "modal-actions" },
                React.createElement("button", { onClick: handleCloseBillingPopup, className: "button button__cancel half-width" }, t("modal.cancel")),
                React.createElement("button", { onClick: handleSwitchBilling, className: "button button__red" }, t(activeBilling
                    ? "provider_view.deactivate_billing"
                    : "provider_view.activate_billing"))))));
};
