"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InvoiceCalculation = /** @class */ (function () {
    function InvoiceCalculation() {
    }
    InvoiceCalculation.countTotalAmountForCard = function (invoice, isInternationalPayment) {
        var feeAmount = isInternationalPayment ? invoice.international_card_fee_item.amount : invoice.card_fee_item.amount;
        return (parseFloat(invoice.amount) + parseFloat(feeAmount)).toFixed(2);
    };
    InvoiceCalculation.amountToFixed = function (amount) {
        return parseFloat(amount).toFixed(2);
    };
    return InvoiceCalculation;
}());
exports.default = InvoiceCalculation;
