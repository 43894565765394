"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var react_i18next_1 = require("react-i18next");
var react_toastify_1 = require("react-toastify");
var react_router_1 = require("react-router");
var react_router_dom_1 = require("react-router-dom");
require("./style.less");
var container_1 = require("./reasign_manager/container");
var container_2 = require("./reasign_sp/container");
var container_3 = require("components/schedule/container");
var lists_1 = require("constants/lists");
var prefered_location_1 = require("helpers/prefered_location");
var page_title_1 = require("components/page_title");
var lodash_1 = require("lodash");
var ClientView = /** @class */ (function (_super) {
    __extends(ClientView, _super);
    function ClientView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleArchive = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, onArchive, id, t, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onArchive = _a.onArchive, id = _a.id, t = _a.t;
                        if (!(onArchive && id && t)) return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, onArchive(id)];
                    case 2:
                        _b.sent();
                        react_toastify_1.toast.success(t("client_view.arhive_success"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        react_toastify_1.toast.error(error_1, {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleRestore = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, onRestore, id, t, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onRestore = _a.onRestore, id = _a.id, t = _a.t;
                        if (!(onRestore && id && t)) return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, onRestore(id)];
                    case 2:
                        _b.sent();
                        react_toastify_1.toast.success(t("client_view.restore_success"), {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _b.sent();
                        react_toastify_1.toast.error(error_2, {
                            position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                            hideProgressBar: true
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ClientView.prototype.render = function () {
        var _this = this;
        var _a = this.props, match = _a.match, client = _a.client, t = _a.t, id = _a.id, userRole = _a.userRole, managerName = _a.managerName, changeManager = _a.changeManager, onBackToClientsHandler = _a.onBackToClientsHandler, updateClient = _a.updateClient;
        var isNotServiceProvider = userRole !== 'service_provider';
        var contact = client.contacts.find(function (contact) { return !contact.dependent; });
        var parents = client.contacts.filter(function (contact) { return contact.dependent; });
        var hasAddress = contact &&
            contact.address_attributes &&
            !!(contact.address_attributes.street ||
                contact.address_attributes.city ||
                contact.address_attributes.state ||
                contact.address_attributes.zip_code);
        var pageTitle = client.first_name + " " + client.last_name + " ";
        return match && client && t && id ? (React.createElement("div", { className: "view-container" },
            React.createElement(page_title_1.default, { pageTitle: pageTitle }),
            React.createElement("div", { className: "view-container_back" },
                React.createElement(react_router_dom_1.Link, { to: "/clients", onClick: function () {
                        return onBackToClientsHandler(lists_1.LIST_ENTITIES.ALL_CLIENTS_PAGINATED_LIST);
                    } },
                    React.createElement("i", { className: "fas fa-arrow-left margin-right-10" }),
                    t("client_view.back"))),
            React.createElement("div", { className: "view-container_edit" },
                React.createElement("div", { className: "edit__column_left" },
                    React.createElement("span", { className: "title" }, t("client_view.edit")),
                    React.createElement("span", { className: "icon edit" },
                        React.createElement(react_router_dom_1.Link, { to: "/clients/edit/" + id }))),
                React.createElement("p", { className: "edit__column_right title" }, t("client_view.schedule"))),
            React.createElement("div", { className: "view" },
                React.createElement("div", { className: "view__column_left" },
                    React.createElement("div", { className: "view__title title-clients-view" },
                        React.createElement("div", { className: classNames({
                                view__avatar: true,
                                view__avatar_default: !client.image
                            }) }, client.image && React.createElement("img", { src: client.image })),
                        React.createElement("div", { className: "titles" },
                            React.createElement("h2", { className: "action-panel-title" },
                                client.first_name,
                                " ",
                                client.last_name),
                            client.service_provider ? (React.createElement("p", null, t("client_view.assigned_to") + " ",
                                React.createElement(react_router_dom_1.Link, { to: "/providers/view/" + client.service_provider.id }, client.service_provider.first_name + " " + client.service_provider.last_name))) : (React.createElement("p", null, t("client_view.unassigned"))),
                            React.createElement("p", null, t("client_view.managed_by") + " ",
                                React.createElement("span", null, "" + managerName))),
                        React.createElement("div", null,
                            React.createElement("h3", { className: "view__heading" }, t("client_view.fee_range", {
                                range: client.preference_attributes.fee_range
                                    ? client.preference_attributes.fee_range.name
                                    : t("fee_range.unspecified")
                            })),
                            React.createElement("p", null, t("client_view.preferred_fee", {
                                fee: client.preference_attributes.preferred_fee
                            })))),
                    React.createElement("div", { className: "center" },
                        React.createElement("div", { className: "left" },
                            client.age && (React.createElement("section", null,
                                React.createElement("p", null, t("provider_view.old", {
                                    year: client.age
                                })))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.school")),
                                React.createElement("p", null, client.school)),
                            !lodash_1.isEmpty(contact) && (React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.contacts")),
                                contact.phones.map(function (item, key) {
                                    return React.createElement("p", { key: key }, item);
                                }),
                                contact.emails &&
                                    contact.emails.map(function (item, key) {
                                        return React.createElement("p", { key: key }, item);
                                    }))),
                            !!parents.length && (React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.dependants")),
                                parents.map(function (parent, key) { return (React.createElement("div", { key: key, className: "dependants-data" },
                                    React.createElement("p", null, parent.first_name + " " + parent.last_name),
                                    parent.phones.map(function (phone, key) { return (React.createElement("p", { key: key }, phone)); }),
                                    parent.emails.map(function (email, key) { return (React.createElement("p", { key: key }, email)); }),
                                    parent.address_attributes && (React.createElement(React.Fragment, null,
                                        parent.address_attributes.street && (React.createElement("p", null,
                                            parent.address_attributes.street,
                                            " ")),
                                        React.createElement("p", null, _this.getParentAddress(parent.address_attributes)))))); }))),
                            !parents.length && (React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("provider_view.address")),
                                hasAddress ? (React.createElement("div", null,
                                    React.createElement("p", null, contact.address_attributes.street),
                                    React.createElement("p", null,
                                        contact.address_attributes.city,
                                        " ",
                                        contact.address_attributes.state,
                                        " ",
                                        contact.address_attributes.zip_code))) : (t("provider_view.not_specified"))))),
                        React.createElement("div", { className: "right" },
                            client.problem && (React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("client_view.problem")),
                                React.createElement("p", null, client.problem))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("client_view.expertise")),
                                React.createElement("ul", { className: "tags" }, client.expertises.length > 0
                                    ? client.expertises.map(function (item, key) {
                                        return React.createElement("li", { key: key }, item.title);
                                    })
                                    : t("provider_view.not_specified"))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("client_view.setting_preferences")),
                                React.createElement("p", null, client.preference_attributes.side.title ||
                                    t("provider_view.any"))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("client_view.preferred_location")),
                                React.createElement("p", null, prefered_location_1.getPreferedLocation(client.preference_attributes, t))),
                            React.createElement("section", null,
                                React.createElement("h3", { className: "view__heading" }, t("client_view.gender_preference")),
                                client.preference_attributes.gender ? (React.createElement("p", null, client.preference_attributes.gender.title ||
                                    t("provider_view.not_specified"))) : (t("provider_view.not_specified")))))),
                React.createElement("div", { className: "view__column_right" },
                    React.createElement(container_3.default, { providerData: client.service_provider, clientData: client, schedule_preference_text: client.preference_attributes.schedule_preference }))),
            isNotServiceProvider && React.createElement("div", { className: "view-container_back admin-panel" },
                React.createElement("div", { className: "admin-panel__inner" }, userRole === "admin" && (React.createElement(React.Fragment, null,
                    client.status === "archived" ? (React.createElement("button", { onClick: this.handleRestore, className: "button button__transparent" },
                        React.createElement("i", { className: "fas fa-user-plus margin-right-10" }),
                        t("client_view.restore"))) : (React.createElement("button", { onClick: this.handleArchive, className: "button button__transparent" },
                        React.createElement("i", { className: "fas fa-user-times margin-right-10" }),
                        t("client_view.archive"))),
                    React.createElement(container_1.default, { id: id, changeManager: changeManager })))),
                React.createElement(container_2.default, { clientId: id, clientName: client.first_name + " " + client.last_name, updateClient: updateClient, assigned: !!client.service_provider })))) : null;
    };
    ClientView.prototype.getParentAddress = function (addressAttributes) {
        var result = "";
        if (addressAttributes.city) {
            result += addressAttributes.city + " ";
        }
        if (addressAttributes.state) {
            result += addressAttributes.state + " ";
        }
        if (addressAttributes.zip_code) {
            result += addressAttributes.zip_code + " ";
        }
        return result;
    };
    ClientView = __decorate([
        react_router_1.withRouter,
        react_i18next_1.translate()
    ], ClientView);
    return ClientView;
}(React.Component));
exports.default = ClientView;
