"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("config/api");
var params_1 = require("./helpers/params");
var queryString = require("query-string");
var errors_herlper_actions_1 = require("actions/errors_herlper_actions");
// TODO refactoring methods, a lot of copypast here
var ServiceProviderApi = /** @class */ (function () {
    function ServiceProviderApi() {
    }
    ServiceProviderApi.create = function (data) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "service_providers", Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify({ service_provider: data }),
            method: "POST"
        }));
    };
    ServiceProviderApi.getWorkspace = function () {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "service_providers/new", Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    ServiceProviderApi.getList = function (query) {
        query =
            "?" + queryString.stringify(query, {
                arrayFormat: "bracket"
            }) || "";
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "service_providers/" + query, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    ServiceProviderApi.getServiceProvider = function (id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "service_providers/" + id, Object.assign({}, params_1.getDefaultParams(), {
            method: "GET"
        }));
    };
    ServiceProviderApi.update = function (id, data) {
        delete data.id;
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "service_providers/" + id, Object.assign({}, params_1.getDefaultParams(), {
            body: JSON.stringify({ service_provider: data }),
            method: "PUT"
        }));
    };
    ServiceProviderApi.archiveServiceProvider = function (id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "service_providers/" + id + "/archive", Object.assign({}, params_1.getDefaultParams(), {
            method: "POST"
        }));
    };
    ServiceProviderApi.restoreServiceProvider = function (id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "service_providers/" + id + "/restore", Object.assign({}, params_1.getDefaultParams(), {
            method: "POST"
        }));
    };
    ServiceProviderApi.switchBilling = function (id) {
        return errors_herlper_actions_1.wrappedFetch(api_1.default.HOST + "service_providers/" + id + "/switch_billing", __assign({}, params_1.getDefaultParams(), { method: "POST" }));
    };
    return ServiceProviderApi;
}());
exports.ServiceProviderApi = ServiceProviderApi;
