"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_1 = require("redux");
var react_redux_1 = require("react-redux");
var lists_1 = require("actions/lists");
var spinner_1 = require("../../spinner");
var component_1 = require("./component");
var lists_2 = require("constants/lists");
var SessionssListContainer = /** @class */ (function (_super) {
    __extends(SessionssListContainer, _super);
    function SessionssListContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SessionssListContainer.prototype.render = function () {
        var _a = this.props, selectedDate = _a.selectedDate, sessionsForSelectedDay = _a.sessionsForSelectedDay, onListItemClick = _a.onListItemClick;
        return selectedDate && sessionsForSelectedDay ? (React.createElement(component_1.default, { selectedDate: selectedDate, sessions: sessionsForSelectedDay, onListItemClick: onListItemClick })) : (React.createElement(spinner_1.default, null));
    };
    SessionssListContainer.prototype.update = function () {
        this.getSessionsByDate(this.props.selectedDate);
    };
    SessionssListContainer.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.selectedDate !== nextProps.selectedDate) {
            this.getSessionsByDate(nextProps.selectedDate);
        }
        if (this.props.lastUpdateTime !== nextProps.lastUpdateTime) {
            this.update();
        }
    };
    SessionssListContainer.prototype.getSessionsByDate = function (date) {
        var query = {
            date: date ? date.format() : null,
            service_provider: this.props.serviceProviderId,
            client: this.props.clientId
        };
        this.props.getSessionsAction(query, lists_2.LIST_ENTITIES.DAY_SESSIONS);
    };
    SessionssListContainer = __decorate([
        react_redux_1.connect(function (state) {
            return {
                selectedDate: state.sessions.selectedDate,
                sessionsForSelectedDay: state.lists[lists_2.LIST_ENTITIES.DAY_SESSIONS].items
            };
        }, function (dispatch) {
            return {
                getSessionsAction: redux_1.bindActionCreators(lists_1.getList, dispatch)
            };
        })
    ], SessionssListContainer);
    return SessionssListContainer;
}(React.Component));
exports.default = SessionssListContainer;
