"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_provider_1 = require("constants/service_provider");
function workspace(state, action) {
    if (state === void 0) { state = null; }
    switch (action.type) {
        case service_provider_1.SERVICE_PROVIDER_CONST.SERVICE_PROVIDER_GET_WORKSPACE_SUCCESS:
            return Object.assign({}, action.payload);
        default:
            return state;
    }
}
exports.default = workspace;
