"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getPreferedLocation(_a, t) {
    var city = _a.city, preferred_location = _a.preferred_location;
    if (city && preferred_location) {
        return city + ", " + preferred_location;
    }
    if (city) {
        return city;
    }
    if (preferred_location) {
        return preferred_location;
    }
    return t("provider_view.any");
}
exports.getPreferedLocation = getPreferedLocation;
