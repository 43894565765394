"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("./style.less");
var spinner_1 = require("../spinner");
var OverflowLoader = /** @class */ (function (_super) {
    __extends(OverflowLoader, _super);
    function OverflowLoader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @method render
     */
    OverflowLoader.prototype.render = function () {
        var _a = this.props, isLoaderVisible = _a.isLoaderVisible, text = _a.text;
        return isLoaderVisible ? (React.createElement("div", { className: "overflow-loader-wrapper" },
            React.createElement(spinner_1.default, null),
            text && React.createElement("div", { className: "overflow-loader-wrapper__text" },
                " ",
                text,
                " "))) : null;
    };
    return OverflowLoader;
}(React.Component));
exports.default = OverflowLoader;
