"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var redux_form_1 = require("redux-form");
var radio_1 = require("components/form/fields/radio");
exports.ClientType = function (_a) {
    var t = _a.t;
    return (React.createElement(React.Fragment, null,
        React.createElement(redux_form_1.Field, { name: "client_type", component: radio_1.default, label: t("client_add_form.fields.client_type.radio.adult.label"), radioValue: "adult", type: "radio", value: "adult", id: "adult-radio" }),
        React.createElement(redux_form_1.Field, { name: "client_type", component: radio_1.default, label: t("client_add_form.fields.client_type.radio.child.label"), radioValue: "child", type: "radio", value: "child", id: "child-radio" })));
};
