"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var billing_1 = require("constants/billing");
var initialState = {
    is_connected: null,
    conenct_error: null,
    login_link: null,
    login_link_message: null,
    login_link_loading: false
};
function stripe(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case billing_1.BILLING_CONST.STRIPE_CONNECT_SUCCESS:
            var newState = __assign({}, state, { is_connected: true, conenct_error: null });
            return newState;
        case billing_1.BILLING_CONST.STRIPE_CONNECT_FAIL:
            newState = __assign({}, state, { is_connected: false, conenct_error: action.payload.error.message, login_link: null, login_link_message: null });
            return newState;
        case billing_1.BILLING_CONST.IS_CONNECTED_SUCCESS:
            newState = __assign({}, state, { is_connected: action.payload.is_connected, conenct_error: null });
            return newState;
        case billing_1.BILLING_CONST.IS_CONNECTED_FAIL:
            newState = __assign({}, state, { is_connected: false, conenct_error: null });
            return newState;
        case billing_1.BILLING_CONST.STRIPE_DISCONNECT_SUCCESS:
            newState = __assign({}, state, { is_connected: false, conenct_error: null, login_link: null, login_link_message: null });
            return newState;
        case billing_1.BILLING_CONST.STRIPE_DISCONNECT_FAIL:
            newState = __assign({}, state, { is_connected: true, conenct_error: action.payload.error.message });
            return newState;
        case billing_1.BILLING_CONST.STRIPE_GET_LOGIN_LINK_SUCCESS:
            newState = __assign({}, state, { login_link_loading: false, login_link: action.payload.login_link, login_link_message: action.payload.message });
            return newState;
        case billing_1.BILLING_CONST.STRIPE_GET_LOGIN_LINK_FAIL:
            newState = __assign({}, state, { login_link_loading: false, login_link: null, login_link_message: action.payload.message });
            return newState;
        case billing_1.BILLING_CONST.STRIPE_GET_LOGIN_LINK_START:
            newState = __assign({}, state, { login_link_loading: true, login_link: null, login_link_message: null });
            return newState;
        default:
            return state;
    }
}
exports.default = stripe;
