"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var react_toastify_1 = require("react-toastify");
var moment = require("moment");
var lodash_1 = require("lodash");
var react_router_1 = require("react-router");
var classnames_1 = require("classnames");
var redux_form_1 = require("redux-form");
require("./style.less");
var datepicker_1 = require("../../form/fields/datepicker");
var select_1 = require("../../form/fields/select");
var validators_1 = require("../../form/wrapper/validators");
var clients_helper_1 = require("helpers/clients_helper");
var session_1 = require("constants/session");
var reload_schedule_first_session_1 = require("../../decorators/reload_schedule_first_session");
var cost_1 = require("./cost");
var duration_1 = require("./duration");
var repeat_on_days_1 = require("./repeat_on_days");
var _ = require("lodash");
var fields_1 = require("./fields");
var prefered_time_1 = require("../../../helpers/prefered_time");
var fields_2 = require("./fields");
var invoice_1 = require("constants/invoice");
var auth_1 = require("components/helpers/auth");
var defaultTime = moment().startOf('minute');
var defaultTimeAfterMonth = defaultTime.add(1, "month");
var SessionForm = /** @class */ (function (_super) {
    __extends(SessionForm, _super);
    function SessionForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isClientPage = false;
        _this.state = {
            isOverlapsWarningVisible: false,
            isDeleteWarningVisible: false,
            sessionType: "one-time-session",
            rateType: "offline"
        };
        // SUBMIT FORM
        _this.handleSubmit = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var _a, serviceProvider, checkNewSessionOverlaps, session, newValues, isRecurring, checkOverlapsResult;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, serviceProvider = _a.serviceProvider, checkNewSessionOverlaps = _a.checkNewSessionOverlaps, session = _a.session;
                        newValues = this.prepareValues(values);
                        isRecurring = this.state.sessionType === "recurring-session";
                        if (!this.state.isOverlapsWarningVisible) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.submit(newValues)];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 2: return [4 /*yield*/, checkNewSessionOverlaps(__assign({}, newValues, { session_id: session ? session.id : undefined, service_provider_id: serviceProvider.id }), isRecurring)];
                    case 3:
                        checkOverlapsResult = _b.sent();
                        if (!(checkOverlapsResult.type ===
                            session_1.SESSION_CONST.SESSION_CHECK_OVERLAPS_SUCCESS)) return [3 /*break*/, 6];
                        if (!checkOverlapsResult.payload.overlaps) return [3 /*break*/, 4];
                        this.showOverlapWarning();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.submit(newValues)];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.submit = function (values) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.props.asEditForm) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.submitEditForm(values)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.submitCreateForm(values)];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.submitEditForm = function (newValues) { return __awaiter(_this, void 0, void 0, function () {
            var _a, t, editSession, onSuccessSubmit, session, reloadFirstSession, client, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, t = _a.t, editSession = _a.editSession, onSuccessSubmit = _a.onSuccessSubmit, session = _a.session, reloadFirstSession = _a.reloadFirstSession, client = _a.client;
                        return [4 /*yield*/, editSession(newValues, session.id)];
                    case 1:
                        result = _b.sent();
                        if (result.type === session_1.SESSION_CONST.SESSION_EDIT_SUCCESS) {
                            react_toastify_1.toast.success(t("add_session_form.notifications.edit_success"), {
                                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                hideProgressBar: true
                            });
                            onSuccessSubmit();
                            if (this.isOnClientpage()) {
                                reloadFirstSession(client.id);
                            }
                        }
                        else {
                            react_toastify_1.toast.error(t("add_session_form.notifications.edit_error"), {
                                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                hideProgressBar: true
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.submitCreateForm = function (newValues) { return __awaiter(_this, void 0, void 0, function () {
            var _a, t, addSession, onSuccessSubmit, reloadFirstSession, client, isRecurring, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, t = _a.t, addSession = _a.addSession, onSuccessSubmit = _a.onSuccessSubmit, reloadFirstSession = _a.reloadFirstSession, client = _a.client;
                        isRecurring = this.state.sessionType === "recurring-session";
                        return [4 /*yield*/, addSession(newValues, isRecurring)];
                    case 1:
                        result = _b.sent();
                        if (result.type === session_1.SESSION_CONST.SESSION_CREATE_SUCCESS) {
                            react_toastify_1.toast.success(t("add_session_form.notifications.create_success"), {
                                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                hideProgressBar: true
                            });
                            onSuccessSubmit();
                            if (this.isOnClientpage()) {
                                reloadFirstSession(client.id);
                            }
                        }
                        else {
                            react_toastify_1.toast.error(t("add_session_form.notifications.create_error"), {
                                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                hideProgressBar: true
                            });
                            if (result.payload.errors) {
                                throw new redux_form_1.SubmissionError(this.prepareErrors(result.payload.errors));
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.prepareErrors = function (errors) {
            var result = {};
            _.each(errors, function (value, key) {
                result["recurring." + key] = value;
            });
            return result;
        };
        // DELETE SESSION
        _this.deleteSessionHandler = function () { return __awaiter(_this, void 0, void 0, function () {
            var isDeleteWarningVisible;
            return __generator(this, function (_a) {
                isDeleteWarningVisible = this.state.isDeleteWarningVisible;
                if (isDeleteWarningVisible) {
                    this.deleteSession();
                }
                else {
                    this.showDeleteWarning();
                }
                return [2 /*return*/];
            });
        }); };
        _this.deleteSession = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, t, deleteSession, onSuccessSubmit, session, reloadFirstSession, client, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, t = _a.t, deleteSession = _a.deleteSession, onSuccessSubmit = _a.onSuccessSubmit, session = _a.session, reloadFirstSession = _a.reloadFirstSession, client = _a.client;
                        return [4 /*yield*/, deleteSession(session.id)];
                    case 1:
                        result = _b.sent();
                        if (result.type === session_1.SESSION_CONST.SESSION_DELETE_SUCCESS) {
                            react_toastify_1.toast.success(t("add_session_form.notifications.delete_success"), {
                                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                hideProgressBar: true
                            });
                            onSuccessSubmit();
                            if (this.isOnClientpage()) {
                                reloadFirstSession(client.id);
                            }
                        }
                        else {
                            react_toastify_1.toast.error(t("add_session_form.notifications.delete_error"), {
                                position: react_toastify_1.toast.POSITION.TOP_RIGHT,
                                hideProgressBar: true
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        // UTILS
        _this.showOverlapWarning = function () {
            _this.setState({ isOverlapsWarningVisible: true });
        };
        _this.hideWarnings = function () {
            _this.setState({
                isOverlapsWarningVisible: false,
                isDeleteWarningVisible: false
            });
        };
        _this.showDeleteWarning = function () {
            _this.setState({ isDeleteWarningVisible: true });
        };
        _this.getSubmitText = function () {
            var _a = _this.props, t = _a.t, asEditForm = _a.asEditForm;
            var isOverlapsWarningVisible = _this.state.isOverlapsWarningVisible;
            if (asEditForm) {
                return !isOverlapsWarningVisible
                    ? t("add_session_form.edit_submit")
                    : t("add_session_form.edit_submit_anyway");
            }
            else {
                return !isOverlapsWarningVisible
                    ? t("add_session_form.submit")
                    : t("add_session_form.submit_anyway");
            }
        };
        _this.isOnClientpage = function () {
            return !!_this.props.client;
        };
        _this.handleChangeSession = function () {
            _this.setState({
                sessionType: _this.state.sessionType === "one-time-session"
                    ? "recurring-session"
                    : "one-time-session"
            });
        };
        _this.handleChangeClient = function () {
            if (_this.props.change) {
                _this.props.change("rateType", "offline");
            }
            _this.setState({
                rateType: "offline"
            });
        };
        _this.handleChangeRate = function (value) {
            _this.setState({
                rateType: value.id ? value.id : value
            });
        };
        _this.renderBaseRateField = function (feeData) {
            var _a = _this.props, t = _a.t, validators = _a.validators;
            if (!(t && validators)) {
                throw new Error("Incorrect render base rate usage");
            }
            return feeData && feeData.onlineRate ? (React.createElement(redux_form_1.Field, { label: t("add_session_form.fields.rate.label"), component: select_1.default, 
                // clearable={true}
                disabled: _this.state.isOverlapsWarningVisible, options: clients_helper_1.default.getRates(feeData), name: "rateType", require: "true", validate: validators.required, type: "text", placeholder: t("add_session_form.fields.rate.placeholder"), onChange: _this.handleChangeRate })) : (React.createElement("div", { className: "field" },
                React.createElement("div", { className: "label label-replacer" }, t("add_session_form.fields.rate.label")),
                React.createElement("div", { className: "value-replacer" }, feeData ? "$" + feeData.offlineRate + "/" + feeData.sessionDuration + " min" : "-")));
        };
        _this.renderSessionCost = function (feeData) {
            var t = _this.props.t;
            var sessionType = _this.state.sessionType;
            // TODO: for edit session form rateType = offline and
            //  feeData.offlineRate = session.rate which is actually wrong
            //  but works correctly
            return t && (React.createElement("div", null,
                React.createElement("div", { className: "label label-replacer" }, t("add_session_form.cost")),
                feeData ? (React.createElement(cost_1.default, { rateType: _this.state.rateType, rates: { offline: feeData.offlineRate, online: feeData.onlineRate }, sessionDuration: feeData.sessionDuration, recurring: sessionType === "recurring-session" })) : React.createElement("div", { className: "value-replacer" }, "-")));
        };
        return _this;
    }
    SessionForm.prototype.componentWillMount = function () {
        this.isClientPage = !!this.props.client;
    };
    SessionForm.prototype.render = function () {
        var _a = this.props, t = _a.t, handleSubmit = _a.handleSubmit, submitting = _a.submitting, validators = _a.validators, clients = _a.clients, serviceProvider = _a.serviceProvider, client = _a.client, asEditForm = _a.asEditForm, session = _a.session, error = _a.error, selectedClientId = _a.selectedClientId, change = _a.change, touch = _a.touch;
        var _b = this.state, isOverlapsWarningVisible = _b.isOverlapsWarningVisible, isDeleteWarningVisible = _b.isDeleteWarningVisible, sessionType = _b.sessionType;
        var userIsSupervisor = !auth_1.default.isAuthorizedAsServiceProvider();
        var clientId = (client && client.id) || selectedClientId;
        var selectedClient = !asEditForm && clients.find(function (item) { return item.id === clientId; });
        var feeData = null;
        if (session) {
            feeData = {
                offlineRate: session.rate,
                managerName: session.manager_name,
                managerFee: session.manager_fee,
                serviceProviderFee: session.service_provider_fee,
                sessionDuration: client ? client.session_duration : session.client_session_duration
            };
        }
        if (selectedClient) {
            feeData = {
                offlineRate: selectedClient.rate,
                onlineRate: selectedClient.online_rate,
                managerName: selectedClient.manager,
                managerFee: selectedClient.manager_fee,
                serviceProviderFee: selectedClient.service_provider_fee,
                sessionDuration: selectedClient.session_duration
            };
        }
        return t && handleSubmit && validators && clients ? (React.createElement(redux_form_1.Form, { className: "add-new-session", onSubmit: handleSubmit(this.handleSubmit) },
            !asEditForm && React.createElement("h2", null,
                t("add_session_form.title"),
                " "),
            asEditForm && React.createElement("h2", null, t("add_session_form.edit_title")),
            !this.isClientPage && !asEditForm && (React.createElement("div", { className: "field-two-columns" },
                React.createElement(redux_form_1.Field, { label: t("add_session_form.fields.client.label"), component: select_1.default, 
                    // clearable={true}
                    options: clients_helper_1.default.getOptions(clients), name: "client_id", disabled: this.state.isOverlapsWarningVisible, onChange: this.handleChangeClient, require: "true", validate: validators.required, type: "text", placeholder: t("add_session_form.fields.client.placeholder") }),
                this.renderBaseRateField(feeData))),
            (this.isClientPage || asEditForm) && (React.createElement("div", { className: "field-two-columns" },
                React.createElement("div", { className: "field field-replacer" },
                    React.createElement("div", { className: "label label-replacer" }, t("add_session_form.fields.client.label")),
                    React.createElement("div", { className: "value-replacer" },
                        !asEditForm && client.first_name + " " + client.last_name,
                        asEditForm && session.client_name)),
                this.renderBaseRateField(feeData))),
            React.createElement("div", { className: "field-two-columns" },
                React.createElement("div", { className: "field field-replacer " },
                    React.createElement("div", { className: "label label-replacer" }, t("add_session_form.fields.service_provider.label")),
                    React.createElement("div", { className: "value-replacer" }, serviceProvider.first_name + " " + serviceProvider.last_name)),
                React.createElement("div", null,
                    React.createElement("div", { className: "label label-replacer" }, t("add_session_form.tutor_fee")),
                    React.createElement("div", { className: "value-replacer" }, feeData ? feeData.serviceProviderFee + "%" : "-"))),
            React.createElement("div", { className: "field-two-columns" },
                React.createElement("div", { className: "field field-replacer " },
                    React.createElement("div", { className: "label label-replacer" }, t("add_session_form.supervisor")),
                    React.createElement("div", { className: "value-replacer" }, feeData ? feeData.managerName : "-")),
                userIsSupervisor ? (React.createElement("div", null,
                    React.createElement("div", { className: "label label-replacer" }, t("add_session_form.supervisor_fee")),
                    React.createElement("div", { className: "value-replacer" }, feeData ? feeData.managerFee + "%" : "-"))) : this.renderSessionCost(feeData)),
            userIsSupervisor && this.renderSessionCost(feeData),
            !asEditForm && (React.createElement("div", { className: "sessions-wrapper" },
                React.createElement("input", { className: "session-type", id: "one-time-session", type: "radio", name: "session-type", value: "one-time-session", onChange: this.handleChangeSession, checked: sessionType === "one-time-session" }),
                React.createElement("label", { className: "label-for-radio", htmlFor: "one-time-session" }, t("add_session_form.one-time-session")),
                React.createElement("input", { className: "session-type", id: "recurring-session", type: "radio", name: "session-type", value: "recurring-session", onChange: this.handleChangeSession, checked: sessionType === "recurring-session" }),
                React.createElement("label", { className: "label-for-radio", htmlFor: "recurring-session" }, t("add_session_form.recurring-session")))),
            sessionType === "one-time-session" && (React.createElement("div", { className: "session-form" },
                React.createElement(fields_1.OneTimeStartAtField, { t: t, validators: validators, isOverlapsWarningVisible: isOverlapsWarningVisible, formChange: change, clientSessionDuration: lodash_1.get(client, 'session_duration', invoice_1.STANDARD_SESSION_DURATION) }),
                React.createElement("div", { className: "no-margin" },
                    React.createElement(fields_2.OneTimeEndAtField, { t: t, isOverlapsWarningVisible: isOverlapsWarningVisible, validators: validators })),
                React.createElement(duration_1.default, null))),
            sessionType === "recurring-session" && (React.createElement("div", { className: "session-form session-form_recursive" },
                React.createElement("h3", { className: "section-title" }, t("add_session_form.fields.repeat_on")),
                React.createElement(repeat_on_days_1.default, { validators: validators }),
                React.createElement("div", { className: "two-line no-margin" },
                    React.createElement(fields_2.RecurringStartTimeField, { t: t, validators: validators, isOverlapsWarningVisible: isOverlapsWarningVisible, formChange: change, formTouch: touch, clientSessionDuration: lodash_1.get(client, 'session_duration', invoice_1.STANDARD_SESSION_DURATION) }),
                    React.createElement(fields_2.RecurringEndTimeField, { t: t, validators: validators, isOverlapsWarningVisible: isOverlapsWarningVisible })),
                React.createElement(duration_1.default, { recurring: true }),
                React.createElement("div", { className: "two-line" },
                    React.createElement(redux_form_1.Field, { label: t("add_session_form.fields.session_start_date.label"), selected: defaultTime, component: datepicker_1.default, selectsStart: true, disableTyping: true, require: "true", validate: [validators.required, validators.moment], name: "recurring.begin_at", type: "text", placeholder: t("add_session_form.fields.session_start_date.placeholder") }),
                    React.createElement(redux_form_1.Field, { label: t("add_session_form.fields.session_end_date.label"), component: datepicker_1.default, selected: defaultTimeAfterMonth, selectsEnd: true, 
                        // minDate={moment(formValues.recurring.begin_at)}
                        // maxDate={moment(formValues.recurring.begin_at).add("year", 1 )}
                        disableTyping: true, require: "true", validate: [
                            validators.required,
                            validators.moment,
                            validators.recurringAfterBeginAt,
                            validators.diffWithBeginAtLessThenYear
                        ], name: "recurring.end_at", type: "text", placeholder: t("add_session_form.fields.session_end_date.placeholder") })))),
            (isOverlapsWarningVisible || isDeleteWarningVisible) && (React.createElement("button", { className: classnames_1.default({
                    "button button__cancel": true,
                    "half-width": true
                }), onClick: this.hideWarnings, disabled: submitting, type: "button" }, t("add_session_form.cancel"))),
            asEditForm && !isOverlapsWarningVisible && (React.createElement("button", { className: classnames_1.default({
                    "button button__cancel": true,
                    "half-width": true
                }), onClick: this.deleteSessionHandler, disabled: submitting, type: "button" }, t("add_session_form.delete"))),
            !isDeleteWarningVisible && (React.createElement("button", { className: classnames_1.default({
                    "button button__red": true,
                    "full-width": !isOverlapsWarningVisible && !asEditForm,
                    "half-width": isOverlapsWarningVisible || asEditForm
                }), disabled: submitting, type: "submit" }, this.getSubmitText())),
            error && (React.createElement("span", { className: "error" }, t("provider_assign_client_form.required"))),
            isOverlapsWarningVisible && (React.createElement("div", { className: "warning" }, t("add_session_form.overlaps_warning"))),
            isDeleteWarningVisible && (React.createElement("div", { className: "warning" }, t("add_session_form.delete_warning"))))) : null;
    };
    SessionForm.prototype.prepareValues = function (values) {
        var _a = this.props, serviceProvider = _a.serviceProvider, client = _a.client, asEditForm = _a.asEditForm;
        var oneTime = values.oneTime, recurring = values.recurring;
        var newValues;
        if (this.state.sessionType === "one-time-session") {
            newValues = {
                begin_at: oneTime.begin_at.format(),
                end_at: oneTime.end_at.format()
            };
        }
        else {
            newValues = {
                begin_at: recurring.begin_at.format(),
                end_at: recurring.end_at.format(),
                begin_time: recurring.begin_time.format("HH:mm"),
                end_time: recurring.end_time.format("HH:mm"),
                days: recurring.days
                    .map(function (isChecked, index) {
                    return isChecked && prefered_time_1.daysOfWeek[index];
                })
                    .filter(function (dayOrFalse) { return dayOrFalse; })
            };
        }
        if (!asEditForm) {
            var clientId = void 0;
            if (this.isClientPage) {
                clientId = client.id;
            }
            else {
                clientId = values.client_id ? values.client_id.id : null;
            }
            newValues.client_id = clientId;
            newValues.service_provider_id = serviceProvider.id;
            newValues.rate_type = this.state.rateType;
        }
        return newValues;
    };
    SessionForm = __decorate([
        validators_1.default,
        react_router_1.withRouter,
        redux_form_1.reduxForm({
            form: "add_new_session",
            // TODO possible we should change something there to avoid extra re-registering fields
            enableReinitialize: true,
            keepDirtyOnReinitialize: true
        }),
        react_i18next_1.translate(),
        reload_schedule_first_session_1.default()
    ], SessionForm);
    return SessionForm;
}(React.Component));
exports.default = SessionForm;
