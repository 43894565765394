"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var classnames_1 = require("classnames");
require("./style.less");
var auth_1 = require("../../helpers/auth");
var TableBody = function (props) {
    return props.sessions.map(function (item) {
        // TODO check flag
        return (React.createElement("tr", { key: item.id, className: classnames_1.default({
                "not-selectable": item.is_anonymous || !auth_1.default.isAuthorizedAsServiceProvider(),
            }), onClick: function () {
                if (!item.is_anonymous || auth_1.default.isAuthorizedAsServiceProvider()) {
                    props.onListItemClick(item);
                }
            } },
            React.createElement("td", { className: "time-td" },
                item.time,
                item.day && (React.createElement(React.Fragment, null,
                    React.createElement("br", null),
                    item.day))),
            React.createElement("td", null,
                item.client_name,
                " (",
                item.manager_name,
                ")")));
    });
};
var SessionsList = /** @class */ (function (_super) {
    __extends(SessionsList, _super);
    function SessionsList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    SessionsList.prototype.render = function () {
        var _a = this.props, sessions = _a.sessions, onListItemClick = _a.onListItemClick, t = _a.t;
        return (React.createElement("div", { className: "sessions-table content-table" }, sessions.length ? (React.createElement("table", { className: "fixed-layout" },
            React.createElement("tbody", { className: "small-table" },
                React.createElement(TableBody, { sessions: sessions, onListItemClick: onListItemClick })))) : (React.createElement("p", { className: "sessions-table__no-sessions" }, t("add_session_form.no_sessions")))));
    };
    SessionsList = __decorate([
        react_i18next_1.translate()
    ], SessionsList);
    return SessionsList;
}(React.Component));
exports.default = SessionsList;
