"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
require("./style.less");
var container_1 = require("./session_form/container");
var popup_1 = require("../popup");
var container_2 = require("./calendar/container");
var container_3 = require("./sessions_list/container");
var moment = require("moment");
var Schedule = /** @class */ (function (_super) {
    __extends(Schedule, _super);
    function Schedule() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isAddNewSessionPopupOpened: false,
            selectedSessionInList: null,
            updateTime: new Date().getTime()
        };
        // ADD NEW SESSIONS
        _this.closeAddNewSessionPopup = function () {
            _this.setState({
                isAddNewSessionPopupOpened: false,
                selectedSessionInList: null
            });
        };
        _this.showAddNewSessionPopup = function () {
            _this.setState({ isAddNewSessionPopupOpened: true });
        };
        _this.onSuccessAddNewSession = function () {
            _this.setState({
                updateTime: new Date().getTime()
            });
            _this.closeAddNewSessionPopup();
        };
        // SESSION LIST
        _this.onSessionListItemClick = function (session) {
            _this.setState({ selectedSessionInList: session }, _this.showAddNewSessionPopup);
        };
        _this.isPossibleAddSession = function () {
            var _a = _this.props, providerData = _a.providerData, clientData = _a.clientData;
            return (providerData &&
                providerData.status === "active" &&
                (!clientData || clientData.status === "active"));
        };
        return _this;
    }
    Schedule.prototype.render = function () {
        var _a = this.props, t = _a.t, schedule_preference_text = _a.schedule_preference_text, providerData = _a.providerData, clientData = _a.clientData, selectedDate = _a.selectedDate, firstSession = _a.firstSession;
        var _b = this.state, isAddNewSessionPopupOpened = _b.isAddNewSessionPopupOpened, updateTime = _b.updateTime, selectedSessionInList = _b.selectedSessionInList;
        return t ? (React.createElement(React.Fragment, null,
            schedule_preference_text && (React.createElement("section", { className: "schedule__section" },
                React.createElement("p", null, schedule_preference_text))),
            firstSession && firstSession.session && clientData && (React.createElement("div", { className: "first_session_position" }, t("schedule.first_session_label", {
                date: moment(firstSession.session.begin_at).format("MMMM D, YYYY")
            }))),
            React.createElement(container_2.default, { providerData: providerData, clientData: clientData, lastUpdateTime: updateTime }),
            React.createElement("div", { className: "schedule-panel" },
                React.createElement("div", { className: "half-width inline long-month-text" }, selectedDate && selectedDate.format("MMMM DD, YYYY")),
                this.isPossibleAddSession() && (React.createElement("div", { className: "new-session-wrapper half-width inline text-right" },
                    React.createElement("a", { onClick: this.showAddNewSessionPopup, className: "add-new-session-button link-red" }, t("add_session_form.show_button"))))),
            React.createElement(container_3.default, { lastUpdateTime: updateTime, clientId: clientData ? clientData.id : undefined, serviceProviderId: clientData || !providerData ? undefined : providerData.id, onListItemClick: this.onSessionListItemClick }),
            React.createElement(popup_1.default, { openned: isAddNewSessionPopupOpened, onClose: this.closeAddNewSessionPopup, popupExtendedClass: "new-session-modal", blockClickOutside: true },
                React.createElement(container_1.default, { session: selectedSessionInList, serviceProvider: providerData, client: clientData, onSuccessSubmit: this.onSuccessAddNewSession.bind(this) })))) : null;
    };
    Schedule = __decorate([
        react_i18next_1.translate()
    ], Schedule);
    return Schedule;
}(React.Component));
exports.default = Schedule;
