"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var INVOICE_CONST;
(function (INVOICE_CONST) {
    INVOICE_CONST["INVOICE_GET_SUCCESS"] = "INVOICE_GET_SUCCESS";
    INVOICE_CONST["INVOICE_GET_FAIL"] = "INVOICE_GET_FAIL";
    INVOICE_CONST["INVOICE_UPDATE_SUCCESS"] = "INVOICE_UPDATE_SUCCESS";
    INVOICE_CONST["INVOICE_UPDATE_FAIL"] = "INVOICE_UPDATE_FAIL";
    INVOICE_CONST["INVOICE_STATUS_GET_SUCCESS"] = "INVOICE_STATUS_GET_SUCCESS";
    INVOICE_CONST["INVOICE_STATUS_GET_FAIL"] = "INVOICE_STATUS_GET_FAIL";
    INVOICE_CONST["GET_INVOICE_BY_UUID_FAIL"] = "GET_INVOICE_BY_UUID_FAIL";
    INVOICE_CONST["GET_INVOICE_BY_UUID_SUCCESS"] = "GET_INVOICE_BY_UUID_SUCCESS";
    INVOICE_CONST["CHARGE_INVOICE_FAIL"] = "CHARGE_INVOICE_FAIL";
    INVOICE_CONST["CHARGE_INVOICE_SUCCESS"] = "CHARGE_INVOICE_SUCCESS";
    INVOICE_CONST["CREATE_SOURCE_ACH_SUCCESS"] = "CREATE_SOURCE_ACH_SUCCESS";
    INVOICE_CONST["CREATE_SOURCE_ACH_FAIL"] = "CREATE_SOURCE_ACH_FAIL";
    INVOICE_CONST["VERIFY_ACH_START_SUCCESS"] = "VERIFY_ACH_START_SUCCESS";
    INVOICE_CONST["VERIFY_ACH_START_FAIL"] = "VERIFY_ACH_START_FAIL";
    INVOICE_CONST["VERIFY_ACH_END_SUCCESS"] = "VERIFY_ACH_END_SUCCESS";
    INVOICE_CONST["VERIFY_ACH_END_FAIL"] = "VERIFY_ACH_END_FAIL";
    INVOICE_CONST["CHARGE_ACH_SUCCESS"] = "CHARGE_ACH_END_SUCCESS";
    INVOICE_CONST["CHARGE_ACH_FAIL"] = "CHARGE_ACH_END_FAIL";
    INVOICE_CONST["INVOICE_MARK_AS_PAID_SUCCESS"] = "INVOICE_MARK_AS_PAID_SUCCESS";
    INVOICE_CONST["INVOICE_MARK_AS_PAID_FAIL"] = "INVOICE_MARK_AS_PAID_FAIL";
    INVOICE_CONST["INVOICE_CANCEL_SUCCESS"] = "INVOICE_CANCEL_SUCCESS";
    INVOICE_CONST["INVOICE_CANCEL_FAIL"] = "INVOICE_CANCEL_FAIL";
    INVOICE_CONST["INVOICE_SEND_SUCCESS"] = "INVOICE_SEND_SUCCESS";
    INVOICE_CONST["INVOICE_SEND_FAIL"] = "INVOICE_SEND_FAIL";
    INVOICE_CONST["CHARGE_CARD_FAIL"] = "CHARGE_CARD_FAIL";
    INVOICE_CONST["CHARGE_CARD_SUCCESS"] = "CHARGE_CARD_SUCCESS";
})(INVOICE_CONST = exports.INVOICE_CONST || (exports.INVOICE_CONST = {}));
exports.STANDARD_SESSION_DURATION = 75;
