"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        title: "footer_menu.terms_of_use"
    },
    {
        title: "footer_menu.privacy_policy"
    }
];
