"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_i18next_1 = require("react-i18next");
var fields_1 = require("components/form/fields");
var redux_form_1 = require("redux-form");
var react_router_1 = require("react-router");
var validators_1 = require("../../../../components/form/wrapper/validators");
var password_reset_success_1 = require("../passwordResetSuccess/password_reset.success");
var password_reset_fail_1 = require("../passwordResetFail/password_reset.fail");
var PasswordResetForm = /** @class */ (function (_super) {
    __extends(PasswordResetForm, _super);
    function PasswordResetForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            success: false,
            fail: false
        };
        _this.onSubmitPassword = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onSubmitPassword, history, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onSubmitPassword = _a.onSubmitPassword, history = _a.history;
                        if (!(onSubmitPassword && history)) return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, onSubmitPassword(values)];
                    case 2:
                        _b.sent();
                        this.setState({ success: true });
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        this.setState({ fail: true });
                        throw new redux_form_1.SubmissionError({
                            _error: error_1.email
                        });
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    PasswordResetForm.prototype.render = function () {
        var _a = this.props, t = _a.t, handleSubmit = _a.handleSubmit, submitting = _a.submitting, error = _a.error, validators = _a.validators;
        var _b = this.state, success = _b.success, fail = _b.fail;
        if (success) {
            return React.createElement(password_reset_success_1.default, null);
        }
        if (fail) {
            return React.createElement(password_reset_fail_1.default, null);
        }
        if (!t || !handleSubmit)
            return null;
        return (React.createElement("div", null,
            React.createElement("h1", null, t("reset_password.password_reset")),
            React.createElement("form", { onSubmit: handleSubmit(this.onSubmitPassword) },
                React.createElement(redux_form_1.Field, { label: t("reset_password.new_password"), component: fields_1.default, require: "true", name: "password", validate: [validators.required, validators.passwordMaxMin], type: "password", placeholder: t("user_details_form.fields.password.placeholder") }),
                React.createElement(redux_form_1.Field, { label: t("reset_password.repeat_password"), component: fields_1.default, require: "true", validate: [
                        validators.required,
                        validators.password,
                        validators.passwordMaxMin
                    ], name: "password_confirmation", type: "password", placeholder: t("user_details_form.fields.confirm_password.placeholder") }),
                React.createElement("button", { className: "$ button button__red", disabled: submitting, type: "submit" }, t("reset_password.update_password")),
                error && (React.createElement("span", { className: "error" }, t("reset_password.login_error"))))));
    };
    PasswordResetForm = __decorate([
        react_router_1.withRouter,
        validators_1.default,
        redux_form_1.reduxForm({
            form: "reset_password"
        }),
        react_i18next_1.translate()
    ], PasswordResetForm);
    return PasswordResetForm;
}(React.Component));
exports.default = PasswordResetForm;
